import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Textarea from "../form/Textarea";
import Radio from "../form/Radio";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { useTranslation } from "react-i18next";

const MakePaymentPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  type,
}) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("");
  const [purpose, setPurpose] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("stripe");
  const [paymentMode, setPaymentMode] = useState("payment");
  const [paymentVia, setPaymentVia] = useState("own");
  const [transactionId, setTransactionId] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [isPay, setIsPay] = useState(false);
  const [isVisabled, setIsVisabled] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [isCopy, setIsCopy] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onMakePayment = async () => {
    setIsPay(true);
    if (amount === "") {
      toastr.error(t("price_field_is_required"));
      setIsPay(false);
    } else if (purpose === "") {
      toastr.error(t("purpose_field_is_required"));
      setIsPay(false);
    } else if (subscriptionType === "manual" && transactionId === "") {
      toastr.error(t("transaction_#_field_is_required"));
      setIsPay(false);
    } else if (subscriptionType === "manual" && checkNumber === "") {
      toastr.error(t("cheque_ACH_number_field_is_required"));
      setIsPay(false);
    } else {
      try {
        const results = await postData("additional-payment", {
          amount: amount,
          purpose: purpose,
          subscriptionType: subscriptionType,
          paymentMode: paymentMode,
          company_id: data?._id,
          transaction_id: transactionId,
          check_number: checkNumber,
          payment_via: paymentVia,
        });
        if (results.status && results.status === 200) {
          setIsPay(false);
          if (
            subscriptionType &&
            subscriptionType === "stripe" &&
            paymentVia === "own"
          ) {
            window.location.href = results?.data?.redirect_url;
            setIsOpen(false);
          } else if (
            subscriptionType &&
            subscriptionType === "stripe" &&
            paymentVia === "mail"
          ) {
            setPaymentUrl(results?.data?.redirect_url);
            setIsVisabled(true);
          } else {
            setIsOpen(false);
            return navigate("/subscriptions");
          }
        } else {
          console.log(results.message);
          setIsPay(false);
        }
      } catch (error) {
        console.log(error.message);
        setIsPay(false);
      }
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("make_payment")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4">
                    <div className="relative mb-3">
                      <Input
                        label={t("price")}
                        labelClasses={"!text-xs"}
                        inputType={"number"}
                        inputPlaceholder={t("enter_price")}
                        inputValue={amount}
                        setInput={setAmount}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Textarea
                        label={t("purpose")}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t("enter_purpose")}
                        inputValue={purpose}
                        setTextarea={setPurpose}
                      />
                    </div>
                    <div className="relative mb-5">
                      <p className="text-sm text-gray-500 text-start mb-3">
                        {t("payment_mode")}
                      </p>
                      <div className="sm:flex gap-2 space-y-2 sm:space-y-0">
                        <Radio
                          radioName={"type"}
                          radioLabel={t("stripe")}
                          radioInputClass={""}
                          isChecked={
                            subscriptionType === "stripe" ? true : false
                          }
                          radioLableClass={""}
                          radioValue={"stripe"}
                          getRadioValue={(event) => setSubscriptionType(event)}
                        />
                        <Radio
                          radioName={"type"}
                          radioLabel={t("manual")}
                          radioInputClass={""}
                          isChecked={
                            subscriptionType === "manual" ? true : false
                          }
                          radioLableClass={""}
                          radioValue={"manual"}
                          getRadioValue={(event) => setSubscriptionType(event)}
                        />
                      </div>
                    </div>
                    {subscriptionType && subscriptionType === "stripe" ? (
                      <>
                        <div className="relative mb-3">
                          <p className="text-sm text-gray-500 text-start mb-3">
                            {t("payment_for")}
                          </p>
                          <div className="sm:flex gap-2 space-y-2 sm:space-y-0">
                            <Radio
                              radioName={"payment_mode"}
                              radioLabel={t("one_time_payment")}
                              radioInputClass={""}
                              isChecked={
                                paymentMode === "payment" ? true : false
                              }
                              radioLableClass={""}
                              radioValue={"payment"}
                              getRadioValue={(event) => setPaymentMode(event)}
                            />
                            <Radio
                              radioName={"payment_mode"}
                              radioLabel={t("subscription_payment")}
                              radioInputClass={""}
                              isChecked={
                                paymentMode === "subscription" ? true : false
                              }
                              radioLableClass={""}
                              radioValue={"subscription"}
                              getRadioValue={(event) => setPaymentMode(event)}
                            />
                          </div>
                        </div>
                        <div className="relative mb-3">
                          <p className="text-sm text-gray-500 text-start mb-3">
                            {t("payment_via")}
                          </p>
                          <div className="sm:flex gap-2 space-y-2 sm:space-y-0">
                            <Radio
                              radioName={"payment_via"}
                              radioLabel={t("send_mail_for_client_payment")}
                              radioInputClass={""}
                              isChecked={paymentVia === "mail" ? true : false}
                              radioLableClass={""}
                              radioValue={"mail"}
                              getRadioValue={(event) => setPaymentVia(event)}
                            />
                            <Radio
                              radioName={"payment_via"}
                              radioLabel={t("pay_own")}
                              radioInputClass={""}
                              isChecked={paymentVia === "own" ? true : false}
                              radioLableClass={""}
                              radioValue={"own"}
                              getRadioValue={(event) => setPaymentVia(event)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <hr />
                        <p className="text-sm text-gray-500 text-start mb-3">
                          {t(
                            "if_company_paid_via_cheque_ach_for_activated_his_account"
                          )}
                        </p>
                        <div className="relative mb-3">
                          <Input
                            label={t("Transaction_#")}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={"Enter Transaction #"}
                            inputValue={transactionId}
                            setInput={setTransactionId}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Input
                            label={t("cheque_ACH_Number")}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t("enter_check_ach_number")}
                            inputValue={checkNumber}
                            setInput={setCheckNumber}
                          />
                        </div>
                      </>
                    )}
                    {isVisabled && (
                      <div className="flex justify-between mb-3 gap-2">
                        <div className="relative w-full pointer-events-none opacity-40">
                          <Textarea
                            label={""}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t("link_details")}
                            inputValue={paymentUrl}
                            setTextarea={setPaymentUrl}
                          />
                        </div>
                        <Tooltip id="button-tooltip" place="left"></Tooltip>
                        <Button
                          buttonIcon={
                            isCopy
                              ? "fa-light fa-spinner fa-spin"
                              : "fa-light fa-copy"
                          }
                          buttonIconPosition={"left"}
                          buttonClasses={
                            "!h-6 !w-6 !min-w-[24px] !text-sm !p-0"
                          }
                          buttonType={"button"}
                          functions={(e) =>
                            navigator.clipboard.writeText(paymentUrl)
                          }
                          buttonHasLink={false}
                          buttonDisabled={isCopy}
                          tooltipContent={t("copy")}
                        />
                      </div>
                    )}
                    <div className="relative mt-4 py-4 border-t border-slate-200">
                      <Button
                        buttonClasses="!bg-teal-500 mx-auto"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          isPay
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-arrow-right-to-arc"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("process")}
                        functions={onMakePayment}
                        buttonHasLink={false}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default MakePaymentPopup;
