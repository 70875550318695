import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";

const ReportTab = ({ xPlacement,type,subTypes,heading,getChangeValue =() => {}, ...props }) => { 
  const[sub_type,setSubType] = useState('');
  useEffect(()=>{
    setSubType(subTypes)
    getChangeValue({'sub_type':subTypes})
  },[subTypes])

  let dropdownData = [
    {
      _id: 1,
      label: "Total "+heading,
      icon: "fa-users-crown",
      link: "/reports?type="+type+"&sub_type=total",
      type: "link",
      current: sub_type && sub_type === "total" ? true : false,
      data : 'total'
    },
    {
      _id: 2,
      label: "Current year "+heading,
      icon: "fa-level-up",
      link: "/reports?type="+type+"&sub_type=current-year",
      type: "link",
      current: sub_type && sub_type === "current-year" ? true : false,
      data : 'current-year'
    },
    {
      _id: 3,
      label: "Previous year "+heading,
      icon: "fa-school",
      link: "/reports?type="+type+"&sub_type=previous-year",
      type: "link",
      current: sub_type && sub_type === "previous-year" ? true : false,
      data : 'previous-year'
    },
    {
      _id: 4,
      label: "Current Month "+heading,
      icon: "fa-arrow-up",
      link: "/reports?type="+type+"&sub_type=current-month",
      type: "link",
      current: sub_type && sub_type === "current-month" ? true : false,
      data : 'current-month'
    },
    {
      _id: 5,
      label: "Previous Month "+heading,
      icon: "fa-trophy",
      link: "/reports?type="+type+"&sub_type=previous-month",
      type: "link",
      current: sub_type && sub_type === "previous-month" ? true : false,
      data : 'previous-month'
    }
  ];

  return (
    <>
      <ul
        className="w-full flex flex-row list-none border-b pl-0 mb-4 border-slate-200 space-x-1 overflow-x-auto overflow-y-hidden scroll-smooth scrollbar"
        id="tabs-tab"
        role="tablist"
      >
        {dropdownData.map((item, index) => (
          <li className="" role="presentation" key={index}>
            <Link
              to={item.link}
              className={
                "inline-block px-4 py-2 rounded-t-lg text-sm font-medium text-center whitespace-nowrap " +
                (item.current ? "!bg-teal-100 !text-teal-600" : "text-gray-500")
              }
              id="tabs-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#tabs-home"
              role="tab"
              aria-controls="tabs-home"
              aria-selected="true"
              onClick={() => {
                setSubType(item.data)
                getChangeValue({'sub_type':item.data})
              }}             
            >
              {item?.label}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
export default ReportTab;
