import React from "react";

const DashboardPlanTrackingSkeleton = () => {
  return (
    <div className="space-y-5 p-1 bg-gray-50 rounded-md">
      <div className="h-6 rounded-md shimmers w-1/3"></div>
      <div className="space-y-3">
        <div className="grid grid-cols-7 gap-4">
          {[...Array(7)].map((_, i) => (
            <div
              key={i}
              className="h-5 rounded-md shimmers w-full"
            ></div>
          ))}
        </div>
        {[...Array(7)].map((_, i) => (
          <div key={i} className="grid grid-cols-7 gap-4">
            <div className="h-4 rounded-md shimmers col-span-1"></div>
            <div className="h-4 rounded-md shimmers col-span-2"></div>
            <div className="h-4 rounded-md shimmers col-span-1"></div>
            <div className="h-4 rounded-md shimmers col-span-1"></div>
            <div className="h-4 rounded-md shimmers col-span-1"></div>
            <div className="h-4 rounded-md shimmers col-span-1"></div>
            <div className="h-4 rounded-md shimmers col-span-1"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPlanTrackingSkeleton;
