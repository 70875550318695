import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Button from "../../form/Button";
import { postData } from "../../../services/api";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import PerspectiveOverallReport from "../../../pages/pdf/PerspectiveOverallReport";
import { API_URL } from "../../../config/host";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EmergencyReplacementPlanReport from "../../../pages/pdf/EmergencyReplacementPlanReport";

const DashBoardReportCenter = () => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [perspectives, setPerspectives] = useState([]);
  const [details, setDetails] = useState({});
  const [entryDetails, setEntryDetails] = useState([]);

  useEffect(() => {
    async function proposedBoardMemberReport() {
      try {
        const result = await postData("proposed-board-member/report", {
          sortQuery: { created_at: 1 },
          type: "boardmember",
        });
        if (result.data) {
          setPerspectives(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    proposedBoardMemberReport();
  }, []);

  useEffect(() => {
    const detailsFun = async () => {
      try {
        const result = await postData("emergency-replacement/details", {});
        if (result.data) {
          setDetails(result.data);
        } else {
          setDetails({});
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    detailsFun();

    async function loadDetails() {
      try {
        const result = await postData("emergency-replace/details", {});
        if (result.data) {
          setEntryDetails(result.data);
        } else {
          console.log("Details message", result.message);
        }
      } catch (error) {
        console.log("Error details catch", error.message);
      }
    }
    loadDetails();
  }, []);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <div className="relative bg-white rounded-lg shadow-md divide-y divide-slate-200 w-full  p-4">
      <div className="text-base text-slate-800 font-bold leading-none px-3 py-3">
        REPORT CENTER
      </div>
      <div className="px-3 py-3 border-y border-slate-200 space-y-2">
        <div
          className="sm:ml-auto flex justify-center items-center"
          data-tooltip-id={`planhistory${1344}`}
          data-tooltip-place="top"
        >
          <Button
            buttonType={"button"}
            buttonLabel={"Completed Company Succession Plans"}
            buttonClasses={" !w-full"}
            buttonHasLink={true}
            buttonLink={"/planhistory"}
          />
          <Tooltip
            id={`planhistory${1344}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to View Completed Company Succession Plans
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div
          className="sm:ml-auto flex justify-center items-center"
          data-tooltip-id={`boardstrength${133344}`}
          data-tooltip-place="top"
        >
          <Button
            buttonType={"button"}
            buttonLabel={"Board Strength Report"}
            buttonClasses={" !w-full"}
            buttonHasLink={true}
            buttonLink={"/boardstrength"}
          />
          <Tooltip
            id={`boardstrength${133344}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to View Board Strength Report
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div
          className="sm:ml-auto flex justify-center items-center"
          data-tooltip-id={`proposedBoardMembers${19644}`}
          data-tooltip-place="top"
        >
          <Button
            buttonType={"button"}
            isCustomLabel={true}
            customData={
              <>
                <i className={"fa-fw fa-solid fa-file-pdf"}></i>
                <div
                  className={"text-xs sm:text-sm font-medium whitespace-nowrap"}
                >
                  <PDFDownloadLink
                    document={
                      <Document>
                        <PerspectiveOverallReport
                          title={t("prospective_board_member_report")}
                          headingTitle={t("prospective_board_members")}
                          user={user}
                          companyLogo={API_URL + user?.image}
                          type={"boardmember"}
                          perspectiveReport={perspectives}
                        />
                      </Document>
                    }
                    fileName="profile_report.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? t("Loading Report") : "Proposed Board Members"
                    }
                  </PDFDownloadLink>
                </div>
              </>
            }
            buttonLabel={"Proposed Board Members"}
            buttonClasses={" !w-full"}
            buttonHasLink={false}
            functions={(e) => getDownload(e)}
          />
          <Tooltip
            id={`proposedBoardMembers${19644}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to View Proposed Board Members Report
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div
          className="sm:ml-auto flex justify-center items-center"
          data-tooltip-id={`emergency-replacement-plan${136644}`}
          data-tooltip-place="top"
        >
          <Button
            buttonType={"button"}
            buttonLabel={"Emergency Replacement Plan"}
            buttonClasses={" !w-full"}
            isCustomLabel={true}
            customData={
              <>
                <i className={"fa-fw fa-solid fa-file-pdf"}></i>
                <div
                  className={"text-xs sm:text-sm font-medium whitespace-nowrap"}
                >
                  <PDFDownloadLink
                    document={
                      <Document>
                        <EmergencyReplacementPlanReport
                          data={details}
                          details={entryDetails}
                          user={user}
                          companyLogo={API_URL + user?.image}
                        />
                      </Document>
                    }
                    fileName="emergency_replacement_plan.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading
                        ? t("Loading Report")
                        : "Emergency Replacement Plan"
                    }
                  </PDFDownloadLink>
                </div>
              </>
            }
            buttonHasLink={false}
            functions={(e) => getDownload(e)}
          />
          <Tooltip
            id={`emergency-replacement-plan${136644}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to View Emergency Replacement Plan
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div
          className="sm:ml-auto flex justify-center items-center"
          data-tooltip-id={`executivestrength${186644}`}
          data-tooltip-place="top"
        >
          <Button
            buttonType={"button"}
            buttonLabel={"Executive Strength Report"}
            buttonClasses={" !w-full"}
            buttonHasLink={true}
            buttonLink={"/executivestrength"}
          />
          <Tooltip
            id={`executivestrength${186644}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to View Executive Strength Report
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div
          className="sm:ml-auto flex justify-center items-center"
          data-tooltip-id={`retirement-reports${1986644}`}
          data-tooltip-place="top"
        >
          <Button
            buttonType={"button"}
            buttonLabel={"Company Retirement Profile"}
            buttonClasses={" !w-full"}
            buttonHasLink={true}
            buttonLink={"/retirement-reports"}
          />
          <Tooltip
            id={`retirement-reports${1986644}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">
                    Click to View Company Retirement Profile
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DashBoardReportCenter;
