import React from "react";
import TableFilter from "./TableFilter";
import TableSort from "./TableSort";
import Checkbox from "../../form/Checkbox";

const Th = ({
  name,
  width,
  value,
  nameAlign,
  isSort,
  isFilter,
  dropdownData = [],
  isFilterSearch,
  onFilter = () => {},
  filterSearch = () => {},
  functions = () => {},
  getSortValue = () => {},
  totalDataCount,
  type,
  handleCheckboxChange,
  onCheckboxChange,
  checkboxChecked,
  ...props
}) => {
  const align = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center",
    "": "justify-start",
  };

  return (
    <>
      <th
        className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 first:rounded-tl-md last:rounded-tr-md"
        style={{ minWidth: width + "%" }}
      >
        <div className={"flex items-center w-full " + align[nameAlign]}>
          <div className="text-sm font-semibold text-white capitalize">
            {name}
          </div>
          {type === "checkbox" && <Checkbox isChecked={checkboxChecked} getCheckboxValue={onCheckboxChange} />}
          {(isSort || isFilter) && (
            <div className="ml-auto flex justify-end items-center">
              {isSort && (
                <div
                  className="relative flex items-center"
                  onClick={() => {
                    functions(value);
                  }}
                >
                  <TableSort
                    sortState={"nostate"}
                    getSortValue={getSortValue}
                  />
                </div>
              )}
              {isFilter && dropdownData?.length > 0 && (
                <div className="flex mr-3">
                  <TableFilter
                    xPlacement={"bottomRight"}
                    filterData={dropdownData}
                    // filterData={filterData}
                    filterSearch={filterSearch}
                    onFilter={onFilter}
                    isFilterSearch={isFilterSearch}
                    totalDataCount={totalDataCount}
                  />
       
                </div>
              )}
            </div>
          )}
        </div>
      </th>
    </>
  );
};

export default Th;
