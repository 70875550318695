import React from "react";
import Animation from "./Animation/Animation";

const PolicySkeletonLoader = () => {
  return (
    <div>
      {" "}
      <div className="flex-grow">
        <div className="space-y-1">
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <Animation classes={"via-[#a3a3a3]/60"} />
          </div>
          <div className="rounded overflow-hidden h-2 w-full bg-[#d4d4d4]">
            <Animation classes={"via-[#a3a3a3]/60"} />
          </div>
          <div className="rounded overflow-hidden h-2 w-1/2 bg-[#d4d4d4]">
            <Animation classes={"via-[#a3a3a3]/60"} />
          </div>
        </div>
        <div className="rounded overflow-hidden h-2 w-40 mt-3 bg-[#d4d4d4]">
          <Animation classes={"via-[#a3a3a3]/60"} />
        </div>
      </div>
    </div>
  );
};

export default PolicySkeletonLoader;
