import React, { useState, useRef, useEffect } from "react";
import toastr from "toastr";
import moment from "moment";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { postData } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Textarea from "../../components/form/Textarea";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { useTranslation } from "react-i18next";

const ManageSalesTracking = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Sales Tracking");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | New Sales Tracking");
  }
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  const [sizes, setSizes] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAdministrator, setCompanyAdministrator] = useState("");
  const [companyAdministratorEmail, setCompanyAdministratorEmail] = useState("");
  const [verifyCompanyAdministratorEmail, setVerifyCompanyAdministratorEmail] = useState("");
  const [companyAdministratorPhone, setCompanyAdministratorPhone] = useState("");
  const [administratorPosition, setAdministratorPosition] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [price, setPrice] = useState("");
  const [priceNote, setPriceNote] = useState("");
  const [salesperson, setSalesperson] = useState("");
  const [contractLength, setContractLength] = useState('')
  const [paymentTerms, setPaymentTerms] = useState('')
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [details, setDetails] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    const loadCompanySize = async () => {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { ordering: 1 },
          type: "company-size"
        });
        if (result.data) {
          setSizes(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCompanySize();
    // Lead details
    const loadLeadTrackingDetailsPlans = async () => {
      try {
        const result = await postData("salesteam/details", {
          sortQuery: { ordering: 1 },
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadLeadTrackingDetailsPlans();
  }, []);

  useEffect(() => {
    setCompanyName(details?.company_name);
    setCompanyAdministrator(details?.comapny_administrator);
    setCompanyAdministratorEmail(details?.company_administrator_email);
    setCompanyAdministratorPhone(details?.company_administrator_phone);
    setCompanySize(details?.company_size);
    setPrice(details?.price);
    setPriceNote(details?.price_note);
    setSalesperson(details?.salesperson);
    setContractLength(details?.contract_length);
    setPaymentTerms(details?.payment_terms);
    setAdditionalNotes(details?.additional_note);
    setAdministratorPosition(details?.administrator_position);
  }, [details]);

  const onSubmit = async () => {
    setIsUpdate(true);
    try {
      const result = await postData("salesteam/update", {
        id: id,
        company_name: companyName,
        comapny_administrator: companyAdministrator,
        company_administrator_email: companyAdministratorEmail,
        verify_company_adminstrator_email: verifyCompanyAdministratorEmail,
        company_administrator_phone: companyAdministratorPhone,
        company_size: companySize,
        price: price,
        price_note: priceNote,
        salesperson: salesperson,
        additional_note: additionalNotes,
        administrator_position: administratorPosition,
        contract_length: contractLength,
        payment_terms: paymentTerms
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("edit_lead_tracking")}</div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col">
          <div className="space-y-4">
            <div className="grid sm:grid-cols-3 gap-4">
              <div className="relative w-full">
                <Input label={t("company_name")} labelClasses={"!text-xs"} requiredDisplay={true} inputType={"input"} inputPlaceholder={t("company_name")} inputValue={companyName} setInput={setCompanyName} />
              </div>
              <div className="relative w-full">
                <Input label={t("company_administrator")} labelClasses={"!text-xs"} requiredDisplay={true} inputType={"input"} inputPlaceholder={t("enter_company_administrator")} inputValue={companyAdministrator} setInput={setCompanyAdministrator} />
              </div>
              <div className="relative w-full">
                <Input label={t("administrator_position")} labelClasses={"!text-xs"} requiredDisplay={true} inputType={"input"} inputPlaceholder={t("enter_administrator_position")} inputValue={administratorPosition} setInput={setAdministratorPosition} />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("company_administrator_email")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"email"}
                  inputPlaceholder={t("enter_company_administrator_email")}
                  inputValue={companyAdministratorEmail}
                  setInput={setCompanyAdministratorEmail}
                  isDisabled={true}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("company_administrator_phone")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  inputType={"number"}
                  inputPlaceholder={t("enter_company_administrator_phone")}
                  inputValue={companyAdministratorPhone}
                  setInput={setCompanyAdministratorPhone}
                //isDisabled={true}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={t("company_size")}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  xPlacement={"left"}
                  selectedValue={companySize}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    { _id: " ", label: t("select_size"), value: "" },
                    ...sizes.map((boardyear_item) => ({
                      _id: boardyear_item?._id,
                      label: boardyear_item?.name,
                      value: boardyear_item?._id,
                    })),
                  ]}
                  getSelectedValue={(e) => {
                    setCompanySize(e._id);
                  }}
                />
              </div>
              <div className="relative w-full">
                <Input label={t("price")} labelClasses={"!text-xs"} requiredDisplay={true} inputType={"input"} inputPlaceholder={t("enter_price")} inputValue={price} setInput={setPrice} />
              </div>
              
              <div className="relative w-full">
                <Input label={t("salesperson")} labelClasses={"!text-xs"} requiredDisplay={true} inputType={"input"} inputPlaceholder={t("enter_salesperson")} inputValue={salesperson} setInput={setSalesperson} />
              </div>
              <div className="relative w-full">
                <Select
                  label={'Proposed Contract Length'}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  xPlacement={"left"}
                  selectedValue={contractLength}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    { _id: " ", label: t("select_size"), value: "" },
                    ...Array(3).fill().map((_, index) => (
                      { _id: Number(index + 1), label: (Number(index + 1) > 1) ? Number(index + 1) + ' Years' : Number(index + 1) + ' Year', value: Number(index + 1) }
                    )),
                  ]}
                  getSelectedValue={(e) => {
                    setContractLength(e._id);
                  }}
                />
              </div>
              <div className="relative w-full">
                <Select
                  label={'Payment Terms'}
                  labelClasses={"!text-xs"}
                  requiredDisplay={true}
                  xPlacement={"left"}
                  selectedValue={paymentTerms}
                  dropdownClass={"!w-full"}
                  dropdownData={[
                    { _id: "", label: 'Select Payemnt Terms', value: "" },
                    { _id: "monthly", label: "Monthly", value: "monthly" },
                    { _id: "full-payment", label: "Full Payment", value: "full-payment" },
                  ]}
                  getSelectedValue={(e) => {
                    setPaymentTerms(e._id);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="relative w-full">
                <Textarea label={t("price_notes")} inputClasses={"!h-32"} labelClasses={"!text-xs"} inputValue={priceNote} setTextarea={setPriceNote} />
              </div>
              <div className="relative w-full">
                <Textarea label={t("additional_notes")} inputClasses={"!h-32"} labelClasses={"!text-xs"} inputValue={additionalNotes} setTextarea={setAdditionalNotes} />
              </div>
            </div>
            
          </div>
          <div className="flex justify-end gap-4 mt-5">
              <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-regular fa-paper-plane"} buttonIconPosition={"left"} buttonLabel={t("send")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isUpdate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSalesTracking;
