import React from "react";

const ReportTableSkeleton = () => {
  return (
    <table className="w-full shimmers">
      <tbody className="bg-white">
        <tr className="bg-gray-50">
          <td className="py-3 px-2 bg-gray-100" colSpan={11}></td>
        </tr>

        <tr className="border-b border-gray-200 bg-gray-100">
          <td
            className="py-3 px-2 font-semibold text-left bg-gray-100"
            colSpan={3}
          >
            <div className="h-4 bg-gray-400 rounded"></div>
          </td>
          <td
            className="py-3 px-2 font-semibold text-left bg-gray-100"
            colSpan={8}
          >
            <div className="h-4 bg-gray-400 rounded"></div>
          </td>
        </tr>

        <tr className="text-gray-700 font-medium text-xs border-b border-gray-200">
          <td className="py-4 px-2 bg-gray-100" colSpan={8}>
            <div className="h-6 bg-gray-400 rounded"></div>
          </td>
        </tr>

        {[...Array(2)].map((_, index) => (
          <tr key={index} className="border-b border-gray-200">
            {Array.from({ length: 7 }).map((_, idx) => (
              <td key={idx} className="py-3 px-2 bg-gray-100">
                <div className="h-4 bg-gray-400 rounded"></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ReportTableSkeleton;
