import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Textarea from "../components/form/Textarea";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "../components/form/Button";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const ManagePolicy = () => {
  setTitle("Succession Now | Policy Statement");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [pageData, setPageData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [id, setId] = useState("");
  const [description, setDescription] = useState(""); //EditorState.createEmpty()

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  useEffect(() => {
    async function loadPageData() {
      try {
        const result = await postData("page/details", {
          alias: "policy-statement",
        });
        if (result.status === 403) {
          return navigate("/policy");
        }else{
          if (result.data) {
            setPageData(result.data);
          } else {
            console.log("Page Details message", result.message);
          }
        }
      } catch (error) {
        console.log("Error page details catch", error.message);
      }
    }
    loadPageData();
  }, []);

  useEffect(() => {
    if (pageData.description) {
      // setDescription(EditorState.createWithContent(
      //     ContentState.createFromBlockArray(
      //         convertFromHTML(pageData?.description)
      //     )
      // ));
      setDescription(pageData.description);
    }
    if (role && (role === "customer-admin" || role === "customer-subadmin") && pageData.company_id) {
      setId(pageData?._id);
    } else if (role && role === "master-admin") {
      setId(pageData?._id);
    }
  }, [pageData]);

  const onUpdate = async (e) => {
    setIsUpdate(true);
    //const rawContentState = convertToRaw(description.getCurrentContent());
    //const html = draftToHtml(rawContentState);
    try {
      let path = "";
      let payload = {};
      if (id) {
        path = "page/update";
        payload = {
          id: id,
          description: description, //html
        };
      } else {
        path = "page/create";
        payload = {
          alias: "policy-statement",
          description: description, //html
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsUpdate(false);
        return navigate("/policy");
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("policy_statement")}</div>
      </div>
      <div className="relative">
          <Textarea label={""} labelClasses={"!text-xs"} inputClasses={"!h-40 lg:!h-96"} inputValue={description} inputPlaceholder={t("enter_policy")} setTextarea={setDescription} />
          {/* <Editor
                editorState={description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}                
                /> */}
        </div>
        <div className="flex items-center justify-end gap-4">
            <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onUpdate} buttonHasLink={false} buttonDisabled={isUpdate} />
          </div>
    </div>
  );
};
export default ManagePolicy;
