import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../components/form/Button";

import { postData } from "../services/api";
import Image from "../components/elements/Image";
import { useTranslation } from "react-i18next";
import Select from "../components/form/Select";
import { languages } from "../helpers";
import { API_URL } from "../config/host";

const FrontFooter = ({settings}) => {
  //const [settings, setSettings] = useState({});
  const [showLogo, setShowLogo] = useState("");
  const isLogin = localStorage.getItem('accessToken');

  const { i18n,t } = useTranslation();
  const [currentLang,setCurrentLang] = useState('')

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // useEffect(() => {
  //   async function settingsData() {
  //     try {
  //       const result = await postData("setting/details", {});
  //       if (result.data) {
  //         setSettings(result.data);
  //       } else {
  //         console.log("settings list message", result.message);
  //       }
  //     } catch (error) {
  //       console.log("Error settings list catch", error.message);
  //     }
  //   }
  //   settingsData();
  // }, []);

  useEffect(() => {    
    setShowLogo(API_URL+settings?.logo);
  }, [settings]);

  const handleLanguageChange = (e) => {
    const newLang = e.value;
    i18n.changeLanguage(newLang);
    sessionStorage.setItem('sessionLang', newLang);
  };

  useEffect(() => {
    const langs = languages();
    const filterValue = langs.filter((item) => item?.value === i18n?.language);
    if(filterValue && filterValue.length>0){
      setCurrentLang(filterValue[0]?._id);
    }
  },[i18n?.language]) 
  
  return (
    <>
      <footer className="relative bg-slate-200 flex items-center py-16 pb-6">
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="flex flex-wrap justify-between -mx-5 space-y-10 lg:space-y-0">
            <div className="lg:w-2/6 xl:w-2/6 w-full px-5">
              <div className="relative mb-5 lg:mb-8">
                <div className="h-10 relative">
                  <Link
                    to="/"
                    className="block w-full h-full cursor-pointer transition-all duration-200"
                  >
                    <Image 
                      src={'logo-succession-now.svg'} 
                      alt={''} 
                      width={'100%'}
                      height={'100%'} 
                      effect={'blur'} 
                      classes={'object-contain object-left'}
                      id={""}
                    />
                  </Link>
                </div>
                {/* {settings && settings.about && (
                  <div className="relative mt-5">
                    <div className="text-base text-slate-500 font-light leading-relaxed">
                      {settings?.about}
                    </div>
                  </div>
                )} */}
              </div>
              <div className="flex mb-5 lg:mb-8">
                <a href="https://www.linkedin.com/company/successionnow/" target="_blank" className="text-xl h-10 flex items-center space-x-1 text-slate-500 hover:text-dark-teal-400 transition-all duration-200 leading-none">
                  <i className="fa-brands fa-fw fa-linkedin fa-2xl"></i>
                  {/* <span className="font-semibold text-base leading-none">Linkedin</span> */}
                </a>
              </div>
              <div className="flex">
                <Select
                  xPlacement={'left'}
                  transitionClass={'!w-full lg:!w-auto'}
                  dropdownClass={'!w-full lg:!w-24'}
                  dropdownButtonClass={'!bg-dark-teal-400 hover:!bg-dark-teal-600 text-white rounded-full border-none'}
                  dropdownButtonLabelClass={'text-white'}
                  selectedValue={currentLang} 
                  dropdownData={languages()}
                  getSelectedValue={handleLanguageChange}
                />
              </div>
            </div>
            <div className="lg:w-2/6 xl:w-2/6 w-full px-5">
              <div className="relative mb-4">
                <div className="text-xl font-semibold text-dark-teal-400 mb-2 lg:mb-6">
                  {t('quick_links')}
                </div>
                <ul className="p-0 list-none space-y-2">
                  <li>
                    <Link
                      to={"/"}
                      className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                    >
                      {t('home')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/#aboutUsDiv"}
                      className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                    >
                      {t('about_us')}
                    </Link>
                  </li>
                  {/* {
                    isLogin && 
                    <li>
                      <Link
                        to={"/"}
                        className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                      >
                        Succession Planning Tutorial
                      </Link>
                    </li>
                  } */}
                  {(settings && settings.enabled_resource) &&
                  <li>
                    <Link
                      to={"/resources"}
                      className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                    >
                    {t('articles')}
                    </Link>
                  </li>
                  }
                  <li>
                    <Link
                      to={"/contact-us"}
                      className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                    >
                      {t('contact_us')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/frequently-asked-questions"}
                      className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                    >
                      {t('faq')}
                    </Link>
                  </li>
                  <li>
                  <Link
                    to={"/terms-and-conditions"}
                    className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                  >
                    {t('terms_and_conditions')}
                  </Link>
                  </li>
                  <li>
                  <Link
                    to={"/privacy"}
                    className="flex text-base text-slate-500 hover:text-dark-teal-400 hover:translate-x-2 transition-all duration-200"
                  >
                    {t('privacy')}
                  </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="lg:w-2/6 xl:w-2/6 w-full px-5">
              <div className="relative mb-4">
                <div className="text-xl font-semibold text-dark-teal-400 mb-6">
                  Start a conversation
                </div>
                <div className="relative mb-4">
                  <div className="text-base font-semibold text-slate-700">
                    Call us
                  </div>
                  {settings && settings.phone && (
                    <div className="text-sm text-slate-500">
                      US:
                      <a
                        href={
                          settings && settings.phone
                            ? "tel:1" + settings.phone
                            : ""
                        }
                        className="text-sm text-slate-500 hover:text-dark-teal-400"
                      >
                        {" "}
                        {settings?.phone}
                      </a>
                    </div>
                  )}
                  {settings && settings.alt_phone && (
                    <div className="text-sm text-slate-500">
                      IN:
                      <a
                        href={
                          settings && settings.alt_phone
                            ? "tel:1" + settings.alt_phone
                            : ""
                        }
                        className="text-sm text-slate-500 hover:text-dark-teal-400"
                      >
                        {" "}
                        {settings?.alt_phone}
                      </a>
                    </div>
                  )}
                </div>
                {settings && settings.email && (
                  <div className="relative mb-4">
                    <div className="text-base font-semibold text-slate-700">
                      General Enquiries
                    </div>
                    <div className="text-sm text-slate-500">
                      <a
                        href={
                          settings && settings.email
                            ? "mailto:" + settings.email
                            : ""
                        }
                        className="text-sm text-slate-500 hover:text-dark-teal-400"
                      >
                        {settings?.email}
                      </a>
                    </div>
                  </div>
                )}
                {settings && settings.address && (
                  <div className="relative mb-4">
                    <div className="text-base font-semibold text-slate-700">
                      Find us
                    </div>
                    <div className="text-sm text-slate-500">
                      {settings?.address}
                    </div>
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <div className="flex justify-between items-center mt-10 pt-5 border-t border-slate-300">
            <div className="text-xs text-slate-600 mr-auto">
              {settings?.copyright}
            </div>            
            <Button
              buttonClasses={
                "!bg-white !w-10 !p-0 !text-slate-600 !text-xl hover:!bg-dark-teal-400 hover:!text-white"
              }
              buttonType={"button"}
              buttonIcon={"fa-regular fa-arrow-up"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              functions={handleScroll}
            />
          </div>
        </div>
      </footer>
    </>
  );
};

export default FrontFooter;
