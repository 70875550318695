import React, { useState, useEffect } from 'react';
import { setTitle } from '../helpers/MetaTag';
import Button from '../components/form/Button';
import Select from '../components/form/Select';
import Search from '../components/form/Search';
import Searching from '../components/Searching';
import NoDataFound from '../components/NoDataFound';
import Image from '../components/elements/Image';
import moment from "moment";
import toastr from "toastr";
import { Tooltip } from 'react-tooltip';

import { postData } from '../services/api';
import { useTranslation } from "react-i18next";

const Invoices = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Pending Invoices");
  const { i18n,t } = useTranslation();  
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [invoiceList, setinvoiceList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0); 
  
  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadInvoiceList = async () => {
      setLoading(true);
      try {
        const result = await postData("invoice/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
        });
        if (result.data) {
          setinvoiceList(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadInvoiceList();
  }, [limit,offset,keyword]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('invoices')}</div>      
    </div>
    {/* <div className="relative flex justify-between items-center mb-4">
        <div className="relative">
            <Select 
            xPlacement={'left'} 
            dropdownButtonClass={'!bg-white'} 
            selectedValue={limit} 
            dropdownData={[
                { _id: 1000000, label: t('all_items'),value: 1000000 }, 
                ...[{ _id: 10, label: '10 '+t('items'), value: 10 },
                    { _id: 20, label: '20 '+t('items'), value: 20 },
                    { _id: 30, label: '30 '+t('items'), value: 30 },
                    { _id: 50, label: '50 '+t('items'), value: 50 },
                    { _id: 100, label: '100 '+t('items'), value: 100 }]
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
            />
        </div>
        <div className="relative ml-auto flex justify-end items-center gap-3">
            <div className="relative">
            <Search 
            placeholder={t('search')} 
            setInput={ setKeyword } 
            />
            </div>
        </div>
    </div> */}
    <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">              
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('date')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('price')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('purpose')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('payment_status')}
              </th>
              <th className="py-4 px-2 text-right max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                {t('action')}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              invoiceList.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>                  
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                        {(item && item?.created_at)?moment(new Date(item?.created_at)).format('Do MMM YYYY'):'N/A'}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                        {(item && item?.price)?'$'+item?.price.toFixed(2):'$0.00'}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                        {(item && item?.purpose)?item?.purpose:'N/A'}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="flex items-center">
                    <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 " + ((item && item.payment_status === 1)?'bg-green-100 text-green-600':(item && item.payment_status === 3)?'bg-amber-100 text-amber-600':'bg-rose-100 text-rose-600')}>{((item && item.payment_status === 1)?t('paid'):t('unpaid'))}</div>
                    </div>
                  </td>                  
                  <td className="py-3 px-2 text-right max-w-[240px]">
                    <div className="flex items-center justify-end gap-2">
                      <Tooltip id="button-tooltip" place="left"></Tooltip>
                      <Button
                        buttonClasses={"!h-7 !px-2 !text-xs !bg-fuchsia-400"}
                        buttonLabel={''}
                        buttonLabelClasses={"text-xs"}
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-arrow-right-to-arc"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={()=> { window.location.href=item?.payment_link }}
                        tooltipContent={t('make_payment')}
                      />
                    </div>                    
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={7}>
                  {
                    loading?<Searching label={t('searching')} />:<NoDataFound label={t('no_data_found')} />
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Invoices;