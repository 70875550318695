import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Table from "../../components/elements/table/Table";
import moment from "moment";

import { postData } from "../../services/api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { useNavigate, useSearchParams } from "react-router-dom";
import { classNames } from "../../helper/classNames";
import Image from "../../components/elements/Image";
import Tabs from "../../components/elements/Tab";
import AllCompanyTransction from "../../components/section/companyDetails.js/AllCompanyTransction";
import { capitalizeString } from "../../helpers";

const BillingDetails = ({ details }) => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Subscriptions"
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const companyIds = searchParams.get("company");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [subscription, setSubscriptions] = useState({});
  const [settings, setSettings] = useState({});
  const [tabType, setTabType] = useState("active");
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    const loadSubscriptionDetails = async () => {
      try {
        const result = await postData("subscription/list", { type: tabType });
        if (result.data) {
          if (tabType === "active") {
            const value =
              Array?.isArray(result.data) &&
              result.data?.length > 0 &&
              result.data[0];

            let salesPersion = "";
            let salesPersonEmail = "";
            let consultantPersion = "";
            let appointmentSetterPersion = "";
            let sales =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "sales-staff"
              );
            if (sales && sales.length > 0) {
              let salesId = sales[0].staff_id;
              let salesPersionArray = value?.staff_details.filter(
                (itm) => itm._id === salesId
              );
              if (salesPersionArray && salesPersionArray.length > 0) {
                salesPersion =
                  salesPersionArray[0].first_name +
                  " " +
                  salesPersionArray[0].last_name;
                salesPersonEmail = salesPersionArray[0]?.email;
              }
            }
            let consultant =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "consultant"
              );
            if (consultant && consultant.length > 0) {
              let consultantId = consultant[0].staff_id;
              let consultantPersionArray = value?.staff_details.filter(
                (itm) => itm._id === consultantId
              );
              if (consultantPersionArray && consultantPersionArray.length > 0) {
                consultantPersion =
                  consultantPersionArray[0].first_name +
                  " " +
                  consultantPersionArray[0].last_name;
              }
            }
            let appointmentSetter =
              value?.user_details.length > 0 &&
              value?.user_details[0].staffs &&
              value?.user_details[0].staffs.filter(
                (item) => item.code === "appointment-setter"
              );
            if (appointmentSetter && appointmentSetter.length > 0) {
              let appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = value?.staff_details.filter(
                (itm) => itm._id === appointmentSetterId
              );
              if (
                appointmentSetterPersionArray &&
                appointmentSetterPersionArray.length > 0
              ) {
                appointmentSetterPersion =
                  appointmentSetterPersionArray[0].first_name +
                  " " +
                  appointmentSetterPersionArray[0].last_name;
              }
            }
            let noOfSuccessionPlan = value?.individual_details.filter(
              (it) => it.conclusion_details.length > 0
            );
            let _statusLabel = "";
            let _statusType = "";
            if (value?.payment_status && value?.payment_status === 1) {
              _statusLabel = t("paid");
              _statusType = "success";
            } else if (value?.payment_status && value?.payment_status === 2) {
              _statusLabel = t("unpaid");
              _statusType = "danger";
            } else if (value?.payment_status && value?.payment_status === 3) {
              _statusLabel = t("unpaid") + " (" + t("trial") + ")";
              _statusType = "danger";
            }

            let successionPlanCount =
              value?.plan_info?.features &&
              value?.plan_info?.features?.length > 0 &&
              value?.plan_info?.features?.find(
                (it) => it?.code === "succession-plans-per-year"
              )?.inputValue;

            setSubscriptions({
              salesPersion,
              noOfSuccessionPlan,
              successionPlanCount,
              _statusLabel,
              _statusType,
              appointmentSetter,
              salesPersion,
              salesPersonEmail,
              consultantPersion,
              appointmentSetterPersion,
              sales,
              consultant,
              ...value,
            });
          }

          setSubscriptionList(
            Array?.isArray(result.data) &&
              result?.data?.length > 0 &&
              result?.data?.map((value, key) => {
                //let renewalType  = (role && role === 'master-admin')?'date':'text';
                // file?.path
                let salesPersion = "";
                let salesPersonEmail = "";
                let consultantPersion = "";
                let appointmentSetterPersion = "";
                let sales =
                  value?.user_details.length > 0 &&
                  value?.user_details[0].staffs &&
                  value?.user_details[0].staffs.filter(
                    (item) => item.code === "sales-staff"
                  );
                if (sales && sales.length > 0) {
                  let salesId = sales[0].staff_id;
                  let salesPersionArray = value?.staff_details.filter(
                    (itm) => itm._id === salesId
                  );
                  if (salesPersionArray && salesPersionArray.length > 0) {
                    salesPersion =
                      salesPersionArray[0].first_name +
                      " " +
                      salesPersionArray[0].last_name;
                    salesPersonEmail = salesPersionArray[0]?.email;
                  }
                }
                let consultant =
                  value?.user_details.length > 0 &&
                  value?.user_details[0].staffs &&
                  value?.user_details[0].staffs.filter(
                    (item) => item.code === "consultant"
                  );
                if (consultant && consultant.length > 0) {
                  let consultantId = consultant[0].staff_id;
                  let consultantPersionArray = value?.staff_details.filter(
                    (itm) => itm._id === consultantId
                  );
                  if (
                    consultantPersionArray &&
                    consultantPersionArray.length > 0
                  ) {
                    consultantPersion =
                      consultantPersionArray[0].first_name +
                      " " +
                      consultantPersionArray[0].last_name;
                  }
                }
                let appointmentSetter =
                  value?.user_details.length > 0 &&
                  value?.user_details[0].staffs &&
                  value?.user_details[0].staffs.filter(
                    (item) => item.code === "appointment-setter"
                  );
                if (appointmentSetter && appointmentSetter.length > 0) {
                  let appointmentSetterId = appointmentSetter[0].staff_id;
                  let appointmentSetterPersionArray =
                    value?.staff_details.filter(
                      (itm) => itm._id === appointmentSetterId
                    );
                  if (
                    appointmentSetterPersionArray &&
                    appointmentSetterPersionArray.length > 0
                  ) {
                    appointmentSetterPersion =
                      appointmentSetterPersionArray[0].first_name +
                      " " +
                      appointmentSetterPersionArray[0].last_name;
                  }
                }
                let noOfSuccessionPlan = value?.individual_details.filter(
                  (it) => it.conclusion_details.length > 0
                );
                let _statusLabel = "";
                let _statusType = "";
                if (value?.payment_status && value?.payment_status === 1) {
                  _statusLabel = t("paid");
                  _statusType = "success";
                } else if (
                  value?.payment_status &&
                  value?.payment_status === 2
                ) {
                  _statusLabel = t("unpaid");
                  _statusType = "danger";
                } else if (
                  value?.payment_status &&
                  value?.payment_status === 3
                ) {
                  _statusLabel = t("unpaid") + " (" + t("trial") + ")";
                  _statusType = "danger";
                }

                let successionPlanCount =
                  value?.plan_info?.features &&
                  value?.plan_info?.features?.length > 0 &&
                  value?.plan_info?.features?.find(
                    (it) => it?.code === "succession-plans-per-year"
                  )?.inputValue;
                return {
                  _id: value?._id,
                  userIds: value?.user_details[0]?._id,
                  rowData: [
                    {
                      _id: 1,
                      width: 15,
                      type: "user",
                      shortName: value?.company_name,
                      isTooltip: true,
                      toolTipData: (
                        <div className="flex flex-col items-start justify-center text-white gap-1">
                          {value?.name && (
                            <h4 className="font-semibold">
                              <span className="text-slate-200">
                                Administrator Name:{" "}
                              </span>
                              {value?.name}
                            </h4>
                          )}

                          {value?.email && (
                            <h4 className="font-medium">
                              <span className="text-slate-200">
                                Administrator Email:{" "}
                              </span>
                              {value?.email}
                            </h4>
                          )}

                          {value?.phone && (
                            <h4 className="font-medium">
                              <span className="text-slate-200">
                                Administrator Phone:{" "}
                              </span>
                              {value?.phone}
                            </h4>
                          )}
                          {value?.account_type && (
                            <h4 className="flex font-medium space-x-2">
                              <span className="text-slate-200">
                                Account Type:{" "}
                              </span>
                              <div className="flex items-center">
                                <div
                                  className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                                    value?.account_type === "business"
                                      ? "bg-violet-100 text-violet-600"
                                      : "bg-orange-100 text-orange-600"
                                  }`}
                                >
                                  {capitalizeString(value?.account_type)}
                                </div>
                              </div>
                            </h4>
                          )}
                          {salesPersion && (
                            <h4 className="font-medium">
                              <span className="text-slate-200">
                                Sales Person:{" "}
                              </span>
                              {salesPersion ? salesPersion : t("none")}
                            </h4>
                          )}
                          {value?.number_of_employees && (
                            <h4 className="font-medium">
                              <span className="text-slate-200">
                                Number of employees:{" "}
                              </span>

                              {value?.number_of_employees}
                            </h4>
                          )}
                        </div>
                      ),
                      data: {
                        _id: value?._id,
                        name: value?.company_name,
                        link: `/company/${value?.company_id}`,
                      },
                      image: value?.image,
                      isColor: "",
                    },
                    {
                      _id: 3,
                      width: 40,
                      type: "text",
                      // data: value?.name,
                      isColor: "",
                      data: (
                        <div className="relative flex flex-col items-center text-center">
                          {value?.name || "N/A"}
                          <small className="text-sky-700">
                            {value?.email || "N/A"}
                          </small>
                        </div>
                      ),
                    },
                    //{ _id:4, width:40, type:'text', data:value?.email,isColor:'' },
                    //{ _id:5, width:15, type:'status', statusLabel:(value?.email_verified && value?.email_verified === 1)?t('verified'):t('not_verified'), statusType:(value?.email_verified && value?.email_verified === 1)?'success':'warning'},
                    //{ _id:6, width:40, type:'text', data:value?.phone,isColor:'' },
                    {
                      _id: 19,
                      width: 10,
                      type: "text",
                      // data: value?.name,
                      data: (
                        <div className="relative flex flex-col text-orange-500  text-[12px]">
                          {value?.plan_info?.name || "N/A"}
                        </div>
                      ),
                    },
                    {
                      _id: 2,
                      width: 40,
                      type: "text",
                      isColor: "",
                      data: (
                        <div className="relative flex flex-col items-center text-center">
                          {value?.number_of_employees || "N/A"}
                          {/* <small className="text-orange-600">
                          {value?.plan_info?.name || "N/A"}
                        </small> */}
                        </div>
                      ),
                    },
                    {
                      _id: 7,
                      width: 40,
                      type: "text",
                      isColor: "",
                      data: (
                        <div
                          className="relative flex flex-col items-center text-center"
                          data-tooltip-id={`currentPlan${value?._id}`}
                          data-tooltip-place="right"
                        >
                          {value?.number_of_succession_plan
                            ? `${noOfSuccessionPlan.length}/${value?.number_of_succession_plan}`
                            : "0/1"}
                          <small className="font-bold text-dark-teal-600">
                            {`${
                              successionPlanCount &&
                              successionPlanCount >= 100000
                                ? "Unlimited"
                                : successionPlanCount
                            }
                           Succession Plan`}
                          </small>

                          <Tooltip
                            id={`currentPlan${value?._id}`}
                            className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
                            render={() => (
                              <>
                                <div className="flex flex-col items-start justify-center text-white gap-1">
                                  <h4 className="font-medium">
                                    <span className="text-slate-200">
                                      Succession Plan:{" "}
                                    </span>
                                    {successionPlanCount &&
                                    successionPlanCount >= 100000
                                      ? "Unlimited"
                                      : successionPlanCount}
                                  </h4>
                                  <h4 className="font-semibold">
                                    <span className="text-slate-200">
                                      Completed Succession Plan:{" "}
                                    </span>
                                    {noOfSuccessionPlan.length || 0}
                                  </h4>

                                  <h4 className="font-medium">
                                    <span className="text-slate-200">
                                      Number Of Succession Plan:{" "}
                                    </span>
                                    {value?.number_of_succession_plan || 1}
                                  </h4>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      ),
                    },
                    {
                      _id: 9,
                      width: 20,
                      type: "text",
                      data:
                        value && value?.created_at
                          ? moment(new Date(value?.created_at))
                              .tz("America/Denver")
                              .format("ll")
                          : "N/A",
                      isColor: "",
                    },
                    {
                      _id: 8,
                      width: 10,
                      type: "text",
                      isColor: "",
                      data: (
                        <div className="flex flex-col">
                          ${value?.original_price.toFixed(2)}
                          {value?.subscription_type === "stripe" && (
                            <small className="font-bold text-dark-teal-600">
                              Utah rate {settings?.tax}% on $
                              {value?.original_price.toFixed(2)} $
                              {Number(
                                (value?.original_price.toFixed(2) *
                                  settings?.tax) /
                                  100
                              ).toFixed(2)}
                            </small>
                          )}
                        </div>
                      ),
                    },
                    {
                      _id: 10,
                      width: 20,
                      type: "text",
                      data:
                        value && value?.end_date
                          ? moment(new Date(value?.end_date))
                              .tz("America/Denver")
                              .format("ll")
                          : value && value?.renewal_date
                          ? moment(new Date(value?.renewal_date))
                              .tz("America/Denver")
                              .format("ll")
                          : "N/A",
                      isColor:
                        value?.is_cancel && value?.is_cancel === 1
                          ? "text-red-600"
                          : "",
                    },
                    {
                      _id: 21,
                      width: 15,
                      type: "text",
                      data:
                        value && value.renewal_price !== undefined
                          ? "$" + Number(value.renewal_price).toFixed(2)
                          : "N/A",
                      isColor: "",
                    },
                    {
                      _id: 11,
                      width: 20,
                      type: "text",
                      data:
                        value && value?.cancel_date
                          ? moment(new Date(value?.cancel_date))
                              .tz("America/Denver")
                              .format("MMM Do YYYY")
                          : "N/A",
                      isColor: "",
                    },
                    {
                      _id: 17,
                      width: 15,
                      type: "text",
                      data: (
                        <div className="flex items-center">
                          <div
                            className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                              value?.account_type === "business"
                                ? "bg-violet-100 text-violet-600"
                                : "bg-orange-100 text-orange-600"
                            }`}
                          >
                            {capitalizeString(value?.account_type)}
                          </div>
                        </div>
                      ),
                      isColor: "",
                    },
                    {
                      _id: 15,
                      width: 15,
                      type: "status",
                      statusLabel:
                        value?.status && value?.status === 1
                          ? "Active"
                          : value?.status && value?.status === 2
                          ? t("cancelled")
                          : t("unpaid"),
                      statusType:
                        value?.status && value?.status === 1
                          ? "success"
                          : value?.status && value?.status === 2
                          ? "danger"
                          : "danger",
                      isColor: "",
                    },
                    {
                      _id: 12,
                      width: 20,
                      type: "text",
                      data: salesPersion ? salesPersion : "N/A",
                      isColor: "",
                      data: (
                        <div className="relative flex flex-col items-center text-center">
                          {salesPersion || "N/A"}
                          <small className="font-bold text-sky-700">
                            {salesPersonEmail || "N/A"}
                          </small>
                        </div>
                      ),
                    },
                    //{ _id:13, width:20, type:'text', data:(consultantPersion)?consultantPersion:t('none'),isColor:''},
                    //{ _id:14, width:20, type:'text', data:(appointmentSetterPersion)?appointmentSetterPersion:t('none'),isColor:''},
                    //{ _id:18, width:40, type:'text', data: value?.activation_code,isColor:'' },
                    {
                      _id: 29,
                      width: 15,
                      type: "status",
                      statusLabel: _statusLabel,
                      // (value?.payment_status && value?.payment_status === 1)?t('paid'):
                      // (value?.payment_status && value?.payment_status === 2)?t('unpaid'):
                      // (value?.payment_status && value?.payment_status === 3)?t('trial'):
                      // (value?.payment_status && value?.payment_status === 4)?t('inactive'):
                      // (value?.payment_status && value?.payment_status === 5)?t('sales_demo'):'',
                      statusType: _statusType,
                      // (value?.payment_status && value?.payment_status === 1)?'success':
                      // (value?.payment_status && value?.payment_status === 2)?'danger':
                      // (value?.payment_status && value?.payment_status === 3)?'info':
                      // (value?.payment_status && value?.payment_status === 4)?'same-success':
                      // (value?.payment_status && value?.payment_status === 5)?'warning':'',
                      isColor: "",
                    },
                  ],
                };
              })
          );
          setTotalDataCount(result.count);
          setTotalPrice(result?.totalPrice);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSubscriptionDetails();
  }, [tabType]);

  const patternAfter =
    "after:content-[''] after:absolute after:z-[-1] after:w-1/2 after:h-40 after:bg-amber-500/15 after:-bottom-1/2 after:-right-10 after:rotate-[-15deg]";
  const patternBefore =
    "before:content-[''] before:absolute before:z-[-1] before:w-60 before:h-80 before:bg-cyan-500/20 before:-top-20 before:-right-40 before:rotate-[-15deg]";

  const viewToolTip = (data) => {
    return (
      <>
        <Tooltip
          id={`toolTip${data?.id}`}
          className="!text-xs !bg-dark-teal-600 z-50 rounded px-6"
          render={() => (
            <div className="gap-x-1">
              <div className="text-[13px] font-normal leading-none  flex items-center">
                <div className="font-semibold">{data?.name}</div>
              </div>
            </div>
          )}
        />
      </>
    );
  };

  const tabs = [
    {
      _id: "tab7",
      name: "Transactions",
      component: <AllCompanyTransction />,
    },
  ];

  useEffect(() => {
    async function settingsData() {
      try {
        const result = await postData("setting/details", {});
        if (result.data) {
          setSettings(result.data);
        } else {
          console.log("settings list message", result.message);
        }
      } catch (error) {
        console.log("Error settings list catch", error.message);
      }
    }
    settingsData();
  }, []);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      name: t("company"),
      value: "lower_company_name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("company_administrator"),
      value: "lower_name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    //{_id:4, width:15, name:t('administrator_email'), value:'email', align:'left', isSort:false, isFilter:false},
    //{_id:5, width:15, name:t('email_verified'), value:'email_verified', align:'left', isSort:false, isFilter:false},
    //{_id:6, width:15, name:t('administrator_phone'), value:'phone', align:'left', isSort:false, isFilter:false},
    {
      _id: 19,
      width: 10,
      name: t("plan"),
      value: "planName",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 40,
      name: t("#_Of_employees"),
      value: "sorting_number_of_employees",
      align: "left",
      isSort: false,
    },
    {
      _id: 7,
      width: 40,
      name: t("of_completed_plans"),
      value: "number_of_succession_plan",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 9,
      width: 20,
      name: t("start_date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 8,
      width: 15,
      name: t("starting_price"),
      value: "original_price",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 10,
      width: 20,
      name: t("renewal_date"),
      value: "end_date",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 21,
      width: 15,
      name: t("renewal_price"),
      value: "renewal_price",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 11,
      width: 20,
      name: t("cancellation_date"),
      value: "cancel_date",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 17,
      width: 15,
      name: t("account"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 15,
      width: 15,
      name: t("current_status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 12,
      width: 20,
      name: t("sales_person"),
      value: "lower_staff_details",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    //{_id:13, width:20, name:t('appointment'),value:'cancel_date', align:'left', isSort:false, isFilter:false},
    //{_id:14, width:20, name:t('consultant'),value:'cancel_date', align:'left', isSort:false, isFilter:false},
    //{_id:18, width:20, name:t('activation_code'),value:'activation_code', align:'left', isSort:false, isFilter:false},
    {
      _id: 29,
      width: 20,
      name: t("paid") + "/" + t("unpaid"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    //{_id:20, width:20, name:t('subscription_for'),value:'subscription_for', align:'left', isSort:false, isFilter:false},
  ];

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">Subscriptions Details</div>
      </div>
      <div className="flex items-center space-x-5 border-b border-gray-200 mb-5">
        <button
          type="button"
          className={
            "relative inline-block px-6 py-3 rounded-t-lg text-sm font-semibold text-center transition-colors duration-300 ease-in-out " +
            (tabType && tabType === "active"
              ? "border-b-4 border-green-300 bg-green-100 text-green-700 shadow-sm"
              : "text-gray-500 hover:border-b-2 hover:border-green-300 hover:bg-green-50")
          }
          onClick={() => {
            setTabType("active");
            setSubscriptions({});
            setSubscriptionList([]);
          }}
        >
          {t("active")}
        </button>

        <button
          type="button"
          className={
            "relative inline-block px-6 py-3 rounded-t-lg text-sm font-semibold text-center transition-colors duration-300 ease-in-out " +
            (tabType && tabType === "expire"
              ? "border-b-4 border-yellow-300 bg-yellow-100 !text-yellow-700 shadow-sm"
              : "text-gray-500 hover:border-b-2 hover:border-yellow-300 hover:bg-yellow-50")
          }
          onClick={() => {
            setTabType("expire");
            setSubscriptions({});
            setSubscriptionList([]);
          }}
        >
          {t("expired")}
        </button>

        <button
          type="button"
          className={
            "relative inline-block px-6 py-3 rounded-t-lg text-sm font-semibold text-center transition-colors duration-300 ease-in-out " +
            (tabType && tabType === "cancelled"
              ? "border-b-4 border-rose-300 bg-rose-100 text-rose-700 shadow-sm"
              : "text-gray-500 hover:border-b-2 hover:border-rose-300 hover:bg-rose-50")
          }
          onClick={() => {
            setTabType("cancelled");
            setSubscriptions({});
            setSubscriptionList([]);
          }}
        >
          {t("cancelled")}
        </button>
        {/* 
        <button
          type="button"
          className={
            "relative inline-block px-6 py-3 rounded-t-lg text-sm font-semibold text-center transition-colors duration-300 ease-in-out " +
            (tabType && tabType === "all"
              ? "border-b-4 border-blue-300 bg-blue-100 text-blue-700 shadow-sm"
              : "text-gray-500 hover:border-b-2 hover:border-blue-300 hover:bg-blue-50")
          }
          onClick={() => {
            setTabType("all");
          }}
        >
          {t("all")}
        </button> */}
      </div>
      {tabType && tabType === "active" && (
        <div className="w-full mx-auto">
          <div
            className={classNames(
              "relative w-full md:flex block lg:space-x-10 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow border border-slate-300 items-start z-0 overflow-hidden",
              patternBefore,
              patternAfter
            )}
          >
            <div className="flex justify-center">
              <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-lg flex-shrink-0 flex border border-slate-200">
                <Image
                  src={subscription?.image}
                  effect={"blur"}
                  className="object-cover"
                />
              </div>
            </div>
            <div className="flex-shrink flex-grow space-y-4">
              <div className="md:flex block justify-between">
                <div className="flex gap-4 items-center lg:justify-start justify-center">
                  <div className="text-dark-teal-500 font-bold md:text-3xl text-2xl">
                    {subscription?.company_name || "N/A"}
                  </div>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="relative  w-1/2">
                  <div
                    className="flex items-start gap-2 w-full"
                    data-tooltip-id={`toolTip${1}`}
                    data-tooltip-place="left"
                  >
                    <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                      <i className="fa-regular fa-user"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500">
                        {subscription?.name || "N/A"}
                      </div>
                    </div>
                    {viewToolTip({ id: 1, name: "Administrator Name" })}
                  </div>
                  <div
                    className="flex gap-2 items-start w-full"
                    data-tooltip-id={`toolTip${2}`}
                    data-tooltip-place="left"
                  >
                    <div className="text-base text-dark-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-fw fa-envelope"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm mailto:text-slate-500">
                        {subscription?.email || "N/A"}
                      </div>
                    </div>
                    {viewToolTip({ id: 2, name: "Administrator Email" })}
                  </div>
                  <div
                    className="flex gap-2 items-start w-full"
                    data-tooltip-id={`toolTip${3}`}
                    data-tooltip-place="left"
                  >
                    <div className="text-base text-dark-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-fw fa-phone"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500">
                        {" "}
                        {subscription?.phone || "N/A"}
                      </div>
                    </div>
                    {viewToolTip({ id: 3, name: "Administrator Phone No" })}
                  </div>
                </div>
                <div className="relative  w-1/2">
                  <div
                    className="w-full flex gap-2 items-center "
                    data-tooltip-id={`toolTip${4}`}
                    data-tooltip-place="left"
                  >
                    <div className="text-base text-dark-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-apartment"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500">
                        {" "}
                        {subscription?.position_name || "N/A"}
                      </div>
                    </div>
                    {viewToolTip({ id: 4, name: "Administrator Position" })}
                  </div>
                  <div
                    className="w-full flex gap-2 items-center"
                    data-tooltip-id={`toolTip${5}`}
                    data-tooltip-place="left"
                  >
                    <div className="text-base text-dark-teal-500 flex-shrink-0">
                      <i className="fa-solid fa-ticket"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500">
                        {" "}
                        {subscription?.activation_code || "N/A"}
                      </div>
                    </div>
                    {viewToolTip({ id: 5, name: "Promo Code" })}
                  </div>
                  <div
                    className="w-full flex gap-2 items-center"
                    data-tooltip-id={`toolTip${6}`}
                    data-tooltip-place="left"
                  >
                    <div className="text-base text-dark-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-building"></i>
                    </div>
                    <div
                      className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${
                        subscription?.account_type === "business"
                          ? "bg-violet-100 text-violet-600"
                          : "bg-orange-100 text-orange-600"
                      }`}
                    >
                      {subscription?.account_type &&
                        capitalizeString(subscription?.account_type)}
                    </div>
                    {viewToolTip({ id: 6, name: "Account Type" })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-shrink flex-grow  h-60 w-full">
            <div className="flex gap-3 mt-3">
              <div className="relative  w-1/2  bg-slate-50 border border-dark-teal-600 p-4 shadow-md rounded-md">
                <div className="text-slate-700 font-semibold text-lg flex justify-start items-start">
                  Details
                </div>
                <div className="flex gap-3">
                  <div className="relative  w-1/2">
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Plan Name:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-orange-500">
                          {subscription?.plan_info?.name || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Number Of Employees:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {subscription?.number_of_employees || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Start Date:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {subscription && subscription?.created_at
                            ? moment(new Date(subscription?.created_at))
                                .tz("America/Denver")
                                .format("ll")
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Cancellation Date :
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {subscription && subscription?.cancel_date
                            ? moment(new Date(subscription?.cancel_date))
                                .tz("America/Denver")
                                .format("MMM Do YYYY")
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Current Status :
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div
                          className={`flex text-center justify-center text-[10px] w-16 font-medium leading-tight rounded py-1 px-2  ${
                            subscription?.status === 1
                              ? "bg-green-100 text-green-600"
                              : "bg-rose-100 text-rose-600"
                          }`}
                        >
                          {subscription?.status && subscription?.status === 1
                            ? "Active"
                            : subscription?.status && subscription?.status === 2
                            ? t("cancelled")
                            : t("unpaid")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative  w-1/2">
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Starting Price:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          <div className="flex flex-col">
                            ${subscription?.original_price?.toFixed(2)}
                            {subscription?.subscription_type === "stripe" && (
                              <small className="font-bold text-dark-teal-600">
                                Utah rate {settings?.tax}% on $
                                {subscription?.original_price?.toFixed(2)} $
                                {Number(
                                  (subscription?.original_price?.toFixed(2) *
                                    settings?.tax) /
                                    100
                                ).toFixed(2)}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Renewal Date:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {subscription && subscription?.end_date
                            ? moment(new Date(subscription?.end_date))
                                .tz("America/Denver")
                                .format("ll")
                            : subscription && subscription?.renewal_date
                            ? moment(new Date(subscription?.renewal_date))
                                .tz("America/Denver")
                                .format("ll")
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Renewal Price:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {subscription &&
                          subscription?.renewal_price !== undefined
                            ? "$" +
                              Number(subscription?.renewal_price)?.toFixed(2)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Paid/Unpaid :
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div
                          className={`flex text-center justify-center rounded-md border text-sm text-white w-[70px]  ${
                            subscription?.status && subscription?.status === 1
                              ? "bg-green-500"
                              : subscription?.status &&
                                subscription?.status === 2
                              ? "bg-rose-500"
                              : "bg-yellow-600"
                          }`}
                        >
                          {subscription?.status && subscription?.status === 1
                            ? "Active"
                            : subscription?.status && subscription?.status === 2
                            ? t("cancelled")
                            : t("unpaid")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative  w-1/2  bg-slate-50 border border-dark-teal-600 p-4 shadow-md rounded-md">
                <div className="flex gap-3">
                  <div className="relative  w-1/2">
                    <div className="text-slate-700 font-semibold text-lg flex justify-start items-start">
                      Succession Plan
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Total Succession Plan:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {subscription?.successionPlanCount &&
                          subscription?.successionPlanCount >= 100000
                            ? "Unlimited"
                            : subscription?.successionPlanCount}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Completed Succession Plan:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {(Array.isArray(subscription?.noOfSuccessionPlan) &&
                            subscription?.noOfSuccessionPlan?.length > 0 &&
                            subscription?.noOfSuccessionPlan?.length) ||
                            0}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Number Of Succession Plan:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {(subscription &&
                            subscription?.number_of_succession_plan) ||
                            1}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative  w-1/2">
                    <div className="text-slate-700 font-semibold text-lg flex justify-start items-start">
                      Sales Person
                    </div>
                    <div
                      className="flex items-start gap-2 w-full"
                      data-tooltip-id={`toolTip${1}`}
                      data-tooltip-place="left"
                    >
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Name:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {(subscription && subscription?.salesPersion) ||
                            "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start gap-2 w-full">
                      <div className="text-base text-dark-teal-500 flex-shrink-0 ">
                        Email:
                      </div>
                      <div className="flex-shrink flex-grow">
                        <div className="text-sm text-slate-500">
                          {(subscription && subscription?.salesPersonEmail) ||
                            "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {tabType && tabType !== "active" && (
        <div className="w-full bg-white rounded-md shadow ">
          <Table
            tableData={subscriptionList}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            isDraggable={false}
          />
        </div>
      )}

      <div className="grid grid-cols-5 gap-6 mt-1 ">
        <div className="col-span-5 space-y-4">
          <div className="relative space-y-3">
            <Tabs
              tabs={tabs}
              navTabClass="w-60 m-auto "
              divClass="w-full bg-transparent shadow-none gap-5"
              buttonClass="w-full rounded-md !text-dark-teal-500 border border-dark-teal-500"
              tabContentClass={"xs:!px-0 lg:!px-0 -mt-9"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
