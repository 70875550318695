export const getStatusFilter = (t) => [
  { value: 1, name: t("active"), type: "checkbox" },
  { value: 2, name: t("deactivated"), type: "checkbox" },
];
export const featured_filter = [
  { value: 1, name: "Featured", type: "checkbox" },
  { value: 2, name: "Non-featured", type: "checkbox" },
];
export const getEmailFilter = (t) => [
  { value: 1, name: t("verified"), type: "checkbox" },
  { value: 2, name: t("not_verified"), type: "checkbox" },
];
