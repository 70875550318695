import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { classNames } from "../../helper/classNames";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import { postData } from "../../services/api";
import Tabs from "../../components/elements/Tab";
import user from "../../assets/image/user.jpg";
import SubscriptionsList from "../../components/section/companyDetails.js/SubscriptionsList";
import AllEmployees from "../../components/section/companyDetails.js/AllEmployees";
import AllCompanyTransction from "../../components/section/companyDetails.js/AllCompanyTransction";
import { Tooltip } from "react-tooltip";
import AllUsersList from "../../components/section/companyDetails.js/AllUsersList";
import AllExecutivesList from "../../components/section/companyDetails.js/AllExecutivesList";
import CompanyPositionList from "../../components/section/companyDetails.js/CompanyPositionList";
import CompanyBoardMember from "../../components/section/companyDetails.js/CompanyBoardMember";
import { capitalizeString } from "../../helpers";

const ViewCompanyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const [details, setDetails] = useState({});

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  const text = `
      Contrary to popular belief, Lorem Ipsum is not simply random text. 
      It has roots in a piece of classical Latin literature from 45 BC, 
      making it over 2000 years old. Richard McClintock, a Latin professor 
      at Hampden-Sydney College in Virginia, looked up one of the more 
      obscure Latin words, consectetur, from a Lorem Ipsum passage, and going 
      through the cites of the word in classical literature, discovered the 
      undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 
      of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by 
      Cicero, written in 45 BC. This book is a treatise on the theory of ethics, 
      very popular.
    `;

  const tabs = [
    {
      _id: "tab1",
      name: "Users",
      component: <AllUsersList data={details} />,
    },
    {
      _id: "tab2",
      name: "Employees",
      component: <AllEmployees />,
    },
    {
      _id: "tab3",
      name: "Executives",
      component: <AllExecutivesList data={details} />,
    },
    {
      _id: "tab4",
      name: "Board Members",
      component: <CompanyBoardMember data={details} />,
    },
    {
      _id: "tab5",
      name: "Positions",
      component: <CompanyPositionList data={details} />,
    },
    {
      _id: "tab6",
      name: "Subscriptions",
      component: <SubscriptionsList />,
    },
    {
      _id: "tab7",
      name: "Transactions",
      component: <AllCompanyTransction />,
    },
  ];

  const patternAfter = "after:content-[''] after:absolute after:z-[-1] after:w-1/2 after:h-40 after:bg-slate-900/5 after:-bottom-1/2 after:-right-10 after:rotate-[-15deg]";
  const patternBefore = "before:content-[''] before:absolute before:z-[-1] before:w-60 before:h-80 before:bg-teal-500/20 before:-top-10 before:-right-40 before:rotate-[-15deg]";

  const viewToolTip = (data) => {
    return (
      <>
        <Tooltip
          id={`toolTip${data?.id}`}
          className="!text-xs !bg-teal-500 z-50 rounded px-2"
          render={() => (
            <div className="gap-x-1">
              <div className="text-[13px] font-normal leading-none  flex items-center">
                <div className="font-semibold">{data?.name}</div>
              </div>
            </div>
          )}
        />
      </>
    );
  };

  return (
    <div className="flex flex-row space-x-4 w-full pb-6">
      <div className="w-full relative z-0 space-y-10">
        <div className="w-full mx-auto">
          <div
            className={classNames(
              "relative w-full md:flex block lg:space-x-10 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow items-start z-0 overflow-hidden",
              patternBefore,
              patternAfter
            )}
          >
            <div className="flex justify-center">
              <div className="4xl:w-40 3xl:w-36 lg:w-44 md:w-36 w-32 !aspect-square overflow-hidden rounded-lg flex-shrink-0 flex border border-slate-200">
                <Image src={details?.image} effect={"blur"} className="object-cover" />
              </div>
            </div>
            <div className="flex-shrink flex-grow space-y-4">
              <div className="md:flex block justify-between">
                <div className="flex gap-4 items-center lg:justify-start justify-center">
                  <div className="text-teal-500 font-bold md:text-3xl text-2xl">{details?.company_name || "N/A"}</div>
                  <div
                    className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${details?.account_type === "business" ? "bg-violet-100 text-violet-600" : "bg-orange-100 text-orange-600"}`}
                  >
                    {details?.account_type && capitalizeString(details?.account_type)}
                  </div>
                </div>
                <div className="flex items-center mt-2 cursor-pointer">
                  <Button
                    buttonLabel={"Administrator Login"}
                    buttonClasses={`!text-base`}
                    buttonType={"button"}
                    buttonIcon={"fa-solid fa-arrow-right-to-arc"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    buttonLabelClasses={"!text-base !font-semibold"}
                    functions={() => navigate(`/login-as?user=${details?._id}`)}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="space-y-1 relative">
                  <div className="flex items-start gap-2 w-full" data-tooltip-id={`toolTip${1}`} data-tooltip-place="right">
                    <div className="text-base text-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-fw fa-user"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500">{details?.name || "N/A"}</div>
                    </div>
                    {viewToolTip({ id: 1, name: "Administrator Name" })}
                  </div>
                  <div className="flex gap-2 items-start w-full" data-tooltip-id={`toolTip${2}`} data-tooltip-place="right">
                    <div className="text-base text-teal-500 flex-shrink-0">
                      <i class="fa-regular fa-fw fa-envelope"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500">{details?.email || "N/A"}</div>
                    </div>
                    {viewToolTip({ id: 2, name: "Administrator Email" })}
                  </div>
                  <div className="flex gap-2 items-start w-full" data-tooltip-id={`toolTip${3}`} data-tooltip-place="right">
                    <div className="text-base text-teal-500 flex-shrink-0">
                      <i class="fa-regular fa-fw fa-phone"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500"> {details?.phone || "N/A"}</div>
                    </div>
                    {viewToolTip({ id: 3, name: "Administrator Phone No" })}
                  </div>
                  <div className="w-full flex gap-2 items-center " data-tooltip-id={`toolTip${4}`} data-tooltip-place="right">
                    <div className="text-base text-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-fw fa-apartment"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500"> {details?.position_name || "N/A"}</div>
                    </div>
                    {viewToolTip({ id: 4, name: "Administrator Position" })}
                  </div>
                  <div className="w-full flex gap-2 items-center" data-tooltip-id={`toolTip${5}`} data-tooltip-place="right">
                    <div className="text-base text-teal-500 flex-shrink-0">
                      <i className="fa-regular fa-fw fa-ticket"></i>
                    </div>
                    <div className="flex-shrink flex-grow">
                      <div className="text-sm text-slate-500"> {details?.promo_code || "N/A"}</div>
                    </div>
                    {viewToolTip({ id: 5, name: "Promo Code" })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-6 mt-4">
          <div className="col-span-5 space-y-4">
            <div className="relative space-y-3">
              <Tabs
                tabs={tabs}
                navTabClass="w-full"
                divClass="!w-full bg-transparent shadow-none !p-0 border-b-2 border-slate-300 rounded-none"
                buttonClass="!text-slate-800 rounded-none -mb-[2px] border-b-2 border-transparent"
                activeButtonClass="!bg-transparent !text-teal-500  !border-teal-500"
                tabContentClass={"!px-0 !py-0"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCompanyDetails;
