import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Textarea from "../../components/form/Textarea";
import Image from "../../components/elements/Image";
import ButtonFile from "../../components/form/ButtonFile";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { allowedImageExtensions, languages } from "../../helpers";
import { API_URL } from "../../config/host";
import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const ManageTestimonial = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Testimonial");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Testimonial");
  }
  const { i18n, t } = useTranslation();
  const allLang = languages();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [details, setDetails] = useState({});
  const [name, setName] = useState({});
  const [image, setImage] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [description, setDescription] = useState({});
  const [designation, setDesignation] = useState({});
  const [qualification, setQualification] = useState("");
  const [company, setCompany] = useState({});
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("testimonial/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setDescription(details?.comments);
    setDesignation(details?.designation);
    setQualification(details?.qualification);
    setCompany(details?.company);
    setShowLogo(API_URL + details?.image);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setDescription(details?.comments);
      setDesignation(details?.designation);
      setQualification(details?.qualification);
      setCompany(details?.company);
    } else {
      setName("");
      setDescription("");
      setDesignation("");
      setQualification("");
      setCompany("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    const formData = new FormData();
    for (const key in name) {
      if (Object.hasOwnProperty.call(name, key)) {
        formData.append("name[" + key + "]", name[key]);
      }
    }
    for (const key2 in designation) {
      if (Object.hasOwnProperty.call(designation, key2)) {
        formData.append("designation[" + key2 + "]", designation[key2]);
      }
    }
    for (const key3 in description) {
      if (Object.hasOwnProperty.call(description, key3)) {
        formData.append("comments[" + key3 + "]", description[key3]);
      }
    }
    for (const key4 in company) {
      if (Object.hasOwnProperty.call(company, key4)) {
        formData.append("company[" + key4 + "]", company[key4]);
      }
    }
    formData.append("image", image);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "testimonial/update";
        formData.append("id", id);
        payload = formData;
      } else {
        path = "testimonial/create";
        payload = formData;
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/testimonials");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{id ? t("edit_client_review") : t("add_client_review")}</div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col">
          <div className="space-y-4">
              <ul className="flex flex-wrap text-sm font-medium text-center border-b border-slate-200 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                {allLang &&
                  allLang.length > 0 &&
                  allLang.map((item, index) => (
                    <li className="" role="presentation" key={index}>
                      <button className={"inline-block px-4 py-2 rounded-t-lg text-sm font-medium text-center" + (index === activeTab ? " bg-teal-100 text-teal-600" : "text-gray-500")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                        {item?.label}
                      </button>
                    </li>
                  ))}
              </ul>
              <div id="default-tab-content">
                {allLang &&
                  allLang.length > 0 &&
                  allLang.map((item, index) => (
                    <div className={index === activeTab ? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>

                      <div className="grid grid-cols-12 gap-4">
                          <div className="relative col-span-12 sm:col-span-4">
                              <Input
                                label={t("name")}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={t("enter_name")}
                                inputValue={name?.[item?.value]}
                                setInput={(val) =>
                                  setName({
                                    ...name,
                                    [item?.value]: val,
                                  })
                                }
                              />
                          </div>
                          <div className="relative col-span-12 sm:col-span-4">
                              <Input
                                label={t("title")}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={t("enter_title")}
                                inputValue={designation?.[item?.value]}
                                setInput={(val) =>
                                  setDesignation({
                                    ...designation,
                                    [item?.value]: val,
                                  })
                                }
                              />
                          </div>
                          <div className="relative col-span-12 sm:col-span-4">
                              <Input
                                label={t("company")}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={t("enter_company")}
                                inputValue={company?.[item?.value]}
                                setInput={(val) =>
                                  setCompany({
                                    ...company,
                                    [item?.value]: val,
                                  })
                                }
                              />
                          </div>
                          <div className="relative col-span-12">
                              <Textarea
                                label={t("comment")}
                                labelClasses={"!text-xs"}
                                inputValue={description?.[item?.value]}
                                inputPlaceholder={t("enter_comment")}
                                setTextarea={(val) =>
                                  setDescription({
                                    ...description,
                                    [item?.value]: val,
                                  })
                                }
                              />
                          </div>
                      </div>        
                    </div>
                  ))}
              </div>
              <div className="flex justify-end gap-4 mt-5">
                  <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-floppy-disk"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
          </div>  
        </div>

       
      </div>
    </div>
  );
};

export default ManageTestimonial;
