import React, { useState, useEffect, useCallback } from "react";
import { postData } from "../services/api";
import { Tooltip } from "react-tooltip";
import { setTitle } from "../helpers/MetaTag";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NoDataFound from "../components/NoDataFound";
import { useSelector } from "react-redux";

const ManagePositionGroup = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Question group"
  );
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [positionData, SetPositionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [tabType, setTabType] = useState("concern");
  const [positionFor, setPositionFor] = useState(
    user &&
      Array.isArray(user?.company_info) &&
      user?.company_info.length > 0 &&
      user?.company_info[0]?._id
  );

  //Question group List Api Call
  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("company-position/list", {
        status: 1,
        companyId:
          positionFor ||
          user?._id ||
          (user &&
            Array.isArray(user?.company_info) &&
            user?.company_info.length > 0 &&
            user?.company_info[0]?._id),
      });

      if (result.data) {
        SetPositionData(result?.data);
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [tabType]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const handleDragEndDrop = async (data) => {
    setLoading(true);
    try {
      const result = await postData("heading/manage-global-tire", {
        type: tabType,
        data: data,
      });

      if (result.data) {
        SetPositionData(data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside any droppable area
    if (!destination) return;

    // If dropped in the same group and same position
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    // Get source and destination groups
    const sourceGroup = positionData.find(
      (group) => group._id.toString() === source.droppableId
    );
    const destinationGroup = positionData.find(
      (group) => group._id.toString() === destination.droppableId
    );

    // Copy the source question
    const [movedQuestion] = sourceGroup.question.splice(source.index, 1);

    // Add the question to the destination group
    destinationGroup.question.splice(destination.index, 0, movedQuestion);

    // Update state with the new data

    handleDragEndDrop([...positionData]);
  };

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        <div className="relative flex justify-between items-center mb-3">
          <div className="text-2xl font-bold">{t("manage_position_group")}</div>
        </div>
        <div className="relative mb-3">
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex flex-col">
              <div className="space-y-8">
                {positionData &&
                  Array?.isArray(positionData) &&
                  positionData?.length > 0 &&
                  positionData?.map((category) => (
                    <Droppable
                      key={category?._id}
                      droppableId={category?._id?.toString()}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`rounded-lg bg-slate-50 border border-slate-200`}
                        >
                          <div className="cursor-pointer py-2 px-4 bg-teal-50 flex justify-between items-center border-t rounded-t-lg border-b border-slate-200">
                            <h2 className="flex gap-2 text-lg font-bold text-slate-800">
                              {category.name}
                              <div className="text-lg font-bold text-slate-800">{`(${category?.question?.length})`}</div>
                            </h2>

                            <div
                              className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer ${
                                category?.setDefault === 1
                                  ? "bg-blue-100 text-blue-600"
                                  : "bg-amber-100 text-amber-600"
                              }`}
                              // onClick={() => defaultStatusChange(value?._id)}
                            >
                              {category?.setDefault === 1
                                ? "Default"
                                : "Non-default"}
                            </div>
                          </div>
                          {category?.question?.length > 0 ? (
                            <div className="list-disc py-3 px-4 space-y-2">
                              {category?.question?.map((q, index) => (
                                <Draggable
                                  key={q._id}
                                  draggableId={q._id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="flex text-gray-800 bg-white py-3 px-4 rounded-md border border-slate-300 shadow-sm font-semibold gap-4"
                                    >
                                      <div className="aspect-[1/1] flex-shrink-0 text-slate-400">
                                        <i className="fa-regular fa-arrow-up-arrow-down"></i>
                                      </div>
                                      <div className="text-sm font-bold !leading-none flex items-center flex-shrink flex-grow">
                                        {q.name}
                                      </div>
                                      <div
                                        className="flex-shrink-0 ml-auto"
                                        data-tooltip-id={
                                          "info-tooltip-" + q?._id
                                        }
                                        data-tooltip-place="left"
                                      >
                                        {q?.description && (
                                          <>
                                            <Tooltip
                                              id={"info-tooltip-" + q?._id}
                                              className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                                              render={() => (
                                                <div className="w-60 p-1 rounded-md">
                                                  <div className="text-[13px] font-normal">
                                                    <p className="font-normal text-white">
                                                      {q?.description}
                                                    </p>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                            <i
                                              className="fa-light fa-circle-info"
                                              style={{ color: "#14b8a6" }}
                                            ></i>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          ) : (
                            <div className="list-disc pl-6 p-4 space-y-1">
                              <div className="flex justify-center items-center">
                                <i className="fas fa-exclamation-circle mr-2 text-slate-500 text-[25px]"></i>
                              </div>
                              <div className="flex justify-center items-center text-xl font-normal text-slate-700">
                                No positions are available in this group at the
                                moment.
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Droppable>
                  ))}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};

export default ManagePositionGroup;
