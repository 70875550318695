import React from "react";

const SymbolKey = ({ sign, message }) => {
  return (
    <div className="px-4 py-4 bg-teal-100 rounded-lg shadow w-full mx-auto">
      <ul className="space-y-3 m-0">
        {sign === "star" && (
          <li className="flex items-start font-semibold text-sm">
            <div className="text-lg text-red-500 mr-2 leading-none">*</div>
            <div className="text-teal-700">{message}</div>
          </li>
        )}
        {sign === "hash" && (
          <li className="flex items-start font-semibold text-sm">
            <div className="text-lg text-red-500 mr-2">#</div>
            <div className="text-teal-700">{message}</div>
          </li>
        )}
        {sign === "plus" && (
          <li className="flex items-start font-semibold text-sm">
            <div className="text-lg text-red-500 mr-2">+</div>
            <div className="text-teal-700">{message}</div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SymbolKey;
