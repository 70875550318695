import React, { useCallback, useState } from "react";
import Button from "../../components/form/Button";

import Table from "../../components/elements/table/Table";
import { useEffect } from "react";
import { postData } from "../../services/api";
import { reorder } from "../../helpers";
import moment from "moment";
import toastr from "toastr";
import Search from "../../components/form/Search";
import Select from "../../components/form/Select";
import ReactPaginate from "react-paginate";
import { DragDropContext } from "react-beautiful-dnd";

import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";
import MultipleButtonAction from "../../components/elements/multipleButtonAction/MultipleButtonAction";

export default function Blogs() {
  const { i18n, t } = useTranslation();
  const [id, setId] = useState("");
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState("");

  const [BlogData, setBlogData] = useState([]);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [sortValue, setSortValue] = useState(-1);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState([]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      name: t("image"),
      value: "image",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 40,
      name: t("title"),
      value: "title",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("short_description"),
      value: "Short Description",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 20,
      name: t("created_date"),
      value: "created_at",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 5,
      width: 20,
      name: t("status"),
      value: "status",
      align: "left",
      isShort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 20,
      name: t("action"),
      value: "action",
      align: "right",
      isShort: false,
      isFilter: false,
    },
  ];

  useEffect(() => {
    //Status

    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("blog/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setBlogData(
              BlogData.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
            toastr.success(statusData.message);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue?.row_id && actionValue?.button_type === "delete") {
      // delete
      const deleteBlog = async () => {
        const statusData = await postData("blog/delete", {
          id: actionValue?.row_id,
        });

        if (statusData.status === 200) {
          setBlogData((blogData) =>
            blogData.filter((item) => item._id !== actionValue.row_id)
          );
          toastr.success(statusData.message);
        } else {
          toastr.error(statusData.message);
        }
      };
      deleteBlog();
    }
  }, [actionValue]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("blog/list", {
        keyword: keyword,
        offset: keyword ? 0 : offset,
        limit: limit,
        sortQuery: sortQuery,
      });
      if (result.data) {
        setBlogData(result.data);
        setCount(result.count);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [isUpdate, keyword, limit, offset, sortQuery]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const tableData =
    BlogData &&
    Array?.isArray(BlogData) &&
    BlogData.length > 0 &&
    BlogData?.map((value, key) => {
      return {
        _id: value?._id,
        rowData: [
          {
            _id: 1,
            width: 40,
            type: "image",
            data: "",
            image: value?.image,
          },
          { _id: 2, width: 15, type: "text", data: value?.name },

          {
            _id: 3,
            width: 15,
            type: "text",
            data: value?.short_description,
          },
          {
            _id: 4,
            width: 20,
            type: "text",
            data:
              value && value?.created_at
                ? moment(new Date(value?.created_at)).format("Do MMM YYYY")
                : "N/A",
          },
          {
            _id: 5,
            width: 15,
            type: "status",
            statusLabel:
              value?.status && value?.status === 1
                ? t("active")
                : t("deactivated"),
            statusType:
              value?.status && value?.status === 1 ? "success" : "warning",
          },
          ,
          {
            _id: 6,
            width: 10,
            type: "action",
            statusLabel:
              value?.status && value?.status === 1
                ? t("active")
                : t("deactivated"),
            statusType:
              value?.status && value?.status === 1 ? "success" : "warning",
          },
        ],
      };
    });

  useEffect(() => {
    setNoOfPage(Math.ceil(count / limit));
    const startItem = Number(count > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, count);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, count, keyword]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % count;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, count);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      BlogData,
      result.source.index,
      result.destination.index
    );
    setBlogData(items);
    const res = await postData("blogs/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 ">
        <div className="text-2xl font-bold">
          {id ? t("add_articles") : t("articles")}
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={t("add")}
            buttonHasLink={true}
            buttonLink={"/add-blog"}
            buttonClasses={"text-sm"}
          />
        </div>
      </div>
      <div className="relative flex items-center gap-3 mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => {
              setLimit(e.value);
              setOffset(0);
              setSelectedRow([]);
            }}
          />
        </div>
        <div className="relative w-full sm:w-auto">
          <Search
            placeholder={t("search")}
            setInput={setKeyword}
            valueReset={() => {
              setOffset(0);
              setSelectedRow([]);
            }}
          />
        </div>
        <div className="relative">
          <MultipleButtonAction
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            list={BlogData}
            setList={setBlogData}
            loadList={loadList}
            API_URL_STATUS={"blog/status-change"}
            API_URL_DELETE={"blog/delete"}
          />
        </div>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={tableData}
            tableHeadData={tableHeadData}
            loading={loading}
            totalRecord={count}
            getSortValue={setSortValue}
            editUrl={"/edit-blog"}
            isDraggable={false}
            isDeleteEnabled
            isStatusUpdate={true}
            getActionValue={(obj) => setActionValue(obj)}
            getSortKey={setSortKey}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectable={true}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={count}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {count > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of {count}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
