import React, { useEffect, useState } from "react";

const InternetSpeedMonitor = () => {
  const [speed, setSpeed] = useState(null);
  const [networkType, setNetworkType] = useState(null);
  const [warning, setWarning] = useState("");

  const speedThreshold = 2; // Minimum Mbps to consider as acceptable speed

  const testInternetSpeed = async () => {
    const imageUrl = "../logo-succession-now.png"; // Replace with a reliable file
    const fileSizeInBytes = 1000; // Approximate file size in bytes
    const startTime = new Date().getTime();

    try {
      await fetch(imageUrl, { method: "GET", cache: "no-cache" });
      const endTime = new Date().getTime();
      const durationInSeconds = (endTime - startTime) / 1000;

      const speedInMbps = (fileSizeInBytes * 8) / (1024 * 1024 * durationInSeconds);
      setSpeed(speedInMbps.toFixed(2));

      if (speedInMbps < speedThreshold) {
        setWarning("Your internet speed is very slow!");
      } else {
        setWarning("");
      }
    } catch (error) {
      setSpeed("Error");
      setWarning("Unable to measure internet speed.");
    }
  };

  const checkNetworkType = () => {
    if (navigator.connection) {
      const { type, effectiveType } = navigator.connection;
      setNetworkType(effectiveType || type);
    } else {
      setNetworkType("Unknown");
    }
  };

  useEffect(() => {
    // Test internet speed on component mount
    testInternetSpeed();

    // Check network type on component mount
    checkNetworkType();

    // Monitor network changes
    const handleNetworkChange = () => {
      checkNetworkType();
      testInternetSpeed(); // Recheck speed on network change
    };

    if (navigator.connection) {
      navigator.connection.addEventListener("change", handleNetworkChange);
    }

    // Cleanup event listener on unmount
    return () => {
      if (navigator.connection) {
        navigator.connection.removeEventListener("change", handleNetworkChange);
      }
    };
  }, []);

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Internet Speed Monitor</h1>
      <p>
        <strong>Current Speed:</strong> {speed ? `${speed} Mbps` : "Measuring..."}
      </p>
      <p>
        <strong>Network Type:</strong> {networkType || "Unknown"}
      </p>
      {warning && <p style={{ color: "red" }}>{warning}</p>}
    </div>
  );
};

export default InternetSpeedMonitor;
