import React from "react";

const SyncListSkeleton = ({ count = 6, type = "" }) => {
  console.log(type, "type");
  return (
    <div className="shimmer">
      <div className="relative">
        <div className="flex justify-start items-center gap-4 p-3">
          
        </div>
        <div className="flex items-center gap-2 py-4">
          <div className="h-10 w-20 bg-slate-200 rounded-md"></div>
          <div className="h-10 w-32 bg-slate-200 rounded-md"></div>
          <div className="h-4 w-40 bg-slate-200 rounded-md"></div>
          <div className="h-10 w-48 bg-slate-200 rounded-md ml-auto"></div>
        </div>
      </div>
      {
        (type === "employee" && (
          <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-375px)] -mx-4 px-4">
            {Array.from({ length: count }).map((_, index) => (
              <div
                key={index}
                className="bg-white rounded-md border border-slate-200 py-4 px-4 flex items-center gap-2 w-full"
              >
                <div className="flex justify-start">
                  <div className="flex items-center justify-between py-2">
                    <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
                  </div>
                </div>
                <div className="overflow-hidden rounded-full flex items-center justify-center flex-shrink-0 w-8 h-8 bg-slate-200"></div>
                <div className="flex-shrink flex-grow">
                  <div className="h-3 bg-slate-200 rounded-md w-2/3 mb-2"></div>
                  <div className="h-2 bg-slate-200 rounded-md w-1/3"></div>
                </div>
              </div>
            ))}
          </div>
        ))
      }
      {
        (type === "position" && (
          <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-375px)] -mx-4 px-4">
            {Array.from({ length: count }).map((_, index) => (
              <div
                key={index}
                className="bg-white rounded-md border border-slate-200 py-4 px-4 flex items-center gap-2 w-full"
              >
                <div className="flex justify-start">
                  <div className="flex items-center justify-between py-2">
                    <div className="w-5 h-5 bg-slate-200 rounded-full"></div>
                  </div>
                </div>
                {/* <div className="overflow-hidden rounded-full flex items-center justify-center flex-shrink-0 w-8 h-8 bg-slate-200"></div> */}
                <div className="flex-shrink flex-grow">
                  <div className="h-3 bg-slate-200 rounded-md w-2/3 mb-2"></div>
                  {/* <div className="h-2 bg-slate-200 rounded-md w-1/3"></div> */}
                </div>
              </div>
            ))}
          </div>
        ))
      }
    </div>
  );
};

export default SyncListSkeleton;
