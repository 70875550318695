import React from "react";
import { classNames } from "../../../helper/classNames";

const Animation = ({ classes }) => {
  return (
    <div
      className={classNames(
        "w-full h-full relative before bg-gradient-to-r from-transparent via-gray-100/90 to-transparent -translate-x-full animate-[shimmer_2s_infinite]",
        classes
      )}
    ></div>
  );
};

export default Animation;
