import React, { useState, useRef, useEffect, Fragment, useCallback } from "react";
import toastr from "toastr";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { apiRequest, postData } from "../../services/api";
import Button from "../form/Button";
import NoDataFound from "../NoDataFound";
import { useDataSyncModal } from "../../Context/SyncDataModalContext";
import Indentification from "../elements/Indentification";
import { initialsValue } from "../../helpers";
import Loader from "../loader/Loader";
import syncFailed from "../../assets/image/syncFaild.png";
import syncSuccess from "../../assets/image/syncSucess.png";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { NINEBOX_API_URL, NINEBOX_BASE_URL, ORGCHART_API_URL, ORGCHART_BASE_URL } from "../../config/host";
import SyncingLoader from "../loader/SyncingLoader";
import Pagination from "../Common/Pagination";
import PositionList from "../section/syncSection/PositionList";
import SyncListSkeleton from "../loader/SyncListSkeleton";
import Select from "../form/Select";
import { classNames } from "../../helper/classNames";

const SyncDataPopup = ({ isOpen }) => {
  const { i18n, t } = useTranslation();
  const {
    syncDataModalOpen,
    setSyncDataModalOpen,
    userList,
    setUserList,
    loadUserList,
    loading,
    syncingLoader,
    syncingStep,
    keyword,
    setKeyword,
    limit,
    setLimit,
    offset,
    setOffset,
    totalDataCount,
    setTotalDataCount,
    startDataCount,
    setStartDataCount,
    endDataCount,
    setEndDataCount,
    noOfPage,
    setNoOfPage,
    currentPage,
    setCurrentPage,
    selectedRole,
    setSelectedRole,
  } = useDataSyncModal();

  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [arePositionAllSelected, setArePositionAllSelected] = useState(false);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [tabType, setTabType] = useState("employees");
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    if (
      syncDataModalOpen &&
      syncingStep?.step === "2"
      // &&
      // userList?.length === 0
    )
      loadUserList();
  }, [
    syncDataModalOpen,
    //  userList?.length,
    syncingStep,
  ]);

  //   if (!syncDataModalOpen) return null;

  const handleSelectedMember = (item) => {
    setSelectedMember((prevSelected) => {
      const isAlreadySelected = prevSelected.some((member) => member?._id === item?._id);

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers = userList?.filter((member) => member._id);

    if (selectedMember.length === filteredMembers.length) {
      setSelectedMember([]);
    } else {
      setSelectedMember(filteredMembers);
    }
  };

  useEffect(() => {
    if (syncDataModalOpen) {
      const filteredMembers = userList?.filter((member) => member?._id);
      const isAllSelected = selectedMember.length === filteredMembers.length && filteredMembers.length !== 0;

      if (isAllSelected) {
        setAreAllSelected(true);
      } else {
        setAreAllSelected(false);
      }
    }
  }, [selectedMember, userList, areAllSelected, syncDataModalOpen]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const syncEmployee = async (url, headersToken) => {
    const updatedUserList = userList.map((user) => {
      const isSelected = selectedMember.some((member) => member._id === user._id);
      if (isSelected) {
        return { ...user, syncingStatus: "processing" };
      }
      return user;
    });
    setUserList(updatedUserList);
    try {
      while (selectedMember.length > 0) {
        const currentEmployee = selectedMember[0];

        // Validate currentEmployee fields
        if (!currentEmployee || !currentEmployee.company_info?.email || !currentEmployee.name || !currentEmployee.email) {
          console.error("Invalid employee data:", currentEmployee);
          toastr.error("Invalid employee data encountered.");
          selectedMember.shift();
          continue;
        }

        const path = url;
        const payload = {
          companyEmail: currentEmployee.company_info?.email,
          name: currentEmployee.name,
          email: currentEmployee.email,
          userId: currentEmployee?._id,
          position_details: Array.isArray(currentEmployee.position_details) && currentEmployee.position_details.length > 0 ? currentEmployee.position_details[0] : {},
          role_details: Array.isArray(currentEmployee.role_details) && currentEmployee.role_details.length > 0 ? currentEmployee.role_details[0] : {},
          code: "successionNow",
        };

        try {
          const res = await apiRequest({
            method: "POST",
            url:path,
            data :payload,
            headers: {
              Authorization: `Bearer ${headersToken}`,
              "Content-Type": "application/json",
            }
          })
          // const res = await axios.post(path, payload, {
          //   headers: {
          //     Authorization: `Bearer ${headersToken}`,
          //     "Content-Type": "application/json",
          //   },
          // });

          if (res?.data?.status === 200) {
            // toastr.success(`Successfully synced: ${currentEmployee.name}`);
            setUserList((prevList) => prevList.map((user) => (user._id === currentEmployee._id ? { ...user, syncingStatus: "success" } : user)));
            selectedMember.shift(); // Remove the synced employee from the list
          } else {
            setUserList((prevList) => prevList.map((user) => (user._id === currentEmployee._id ? { ...user, syncingStatus: "failed" } : user)));
            selectedMember.shift();
            console.log(`Failed to sync: ${currentEmployee.name}`);
            // break; // Stop processing further employees
          }
        } catch (err) {
          console.error(`Error syncing employee: ${currentEmployee.name}`, err);
          setUserList((prevList) => prevList.map((user) => (user._id === currentEmployee._id ? { ...user, syncingStatus: "failed" } : user)));
          if (err.response) {
            console.error("Server error:", err.response.data);
            toastr.error(`Error: ${err.response.data.message || "Server error occurred."}`);
          } else if (err.request) {
            // Request was made but no response received
            console.error("No response received:", err.request);
            toastr.error("Network error. Please try again later.");
          } else {
            console.error("Unexpected error:", err.message);
            toastr.error(`Unexpected error: ${err.message}`);
          }

          selectedMember.shift();
          // break;
        }
      }

      if (selectedMember.length === 0) {
        console.log("All employees have been successfully synced!");
      } else {
        console.log("Sync process stopped. Remaining employees:", selectedMember);
      }
    } catch (err) {
      console.error("Critical error during the sync process:", err);
      toastr.error("A critical error occurred. Please try again later.");
    }
  };

  const getGenarateToken = async (DOMAIN, API_URL = "") => {
    try {
      const res = await postData("create/token", {
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "sync-employees";
        const headersToken = res?.data;
        syncEmployee(url, headersToken);
      } else {
        toastr.error(res?.data?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toastr.error("Somthing went wrong !!!");
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setCurrentPage(event.selected);
    setOffset(newOffset);

    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    setSelectedMember([]);
  };

  const loadRoleList = useCallback(async () => {
    try {
      const result = await postData("role/list", {
        codes: ["customer-user", "customer-subadmin", "customer-boardmember", "customer-employee"],
      });
      if (result.data) {
        setRoleList(result.data);
      }
    } catch (err) {
      console.error(err.message);
    }
  }, []);

  useEffect(() => {
    loadRoleList();
  }, [loadRoleList]);

  return (
    <div className="relative z-50 overflow-hidden w-full">
      <div className={classNames("fixed inset-0 bg-black bg-opacity-25 transition-all duration-300", isOpen?"opacity-100 visible":"opacity-0 invisible")} />
      <div className={classNames("fixed inset-0 overflow-y-auto transition-all duration-300", isOpen ? "opacity-100 visible" : "opacity-0 invisible")}>
        <div className="flex h-screen justify-end w-full overflow-hidden">
          <div className={classNames("w-full flex flex-col max-w-3xl rounded-l-2xl p-4 bg-white text-left align-middle shadow-xl transition-all duration-300", isOpen ? "translate-x-0" :"translate-x-full")}>
            <div className="relative flex-shrink-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900 text-center mb-4">
                {syncingStep?.step === "1" ? "Company Syncing" : `Company Sync Dashboard (${syncingStep?.selectedWebsite?.label || "N/A"})`}
              </h3>
              <div className="absolute -top-4 -right-4 z-10">
                <Button
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-times"}
                  buttonIconPosition={"left"}
                  buttonLabel={""}
                  buttonClasses={"!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"}
                  functions={() => {
                    setSyncDataModalOpen(false);
                    setSelectedRole("");
                  }}
                />
              </div>
            </div>
            <div className="relative mt-2 flex-shrink flex-grow flex flex-col">
              {syncingStep?.step === "1" && (
                <>
                  <div className="flex justify-center items-center min-h-[260px] flex-col my-auto">
                    {syncingLoader && (
                      <>
                        <div className="">
                          <Loader />
                        </div>
                        <div className="flex text-center justify-center items-center text-xl font-bold text-teal-500">{"Syncing Company..."}</div>
                      </>
                    )}
                    {!syncingLoader && (
                      <>
                        {syncingStep?.status === 400 ? (
                          <>
                            <div className="w-16 h-16">
                              <img src={syncFailed} alt="syncFailed" />
                            </div>
                            <div className="flex text-center justify-center items-center text-xl font-bold text-rose-600">{syncingStep?.message || ""}</div>
                          </>
                        ) : syncingStep?.status === 200 ? (
                          <>
                            <div className="w-16 h-16">
                              <img src={syncSuccess} alt="syncSuccess" />
                            </div>
                            <div className="flex text-center justify-center items-center text-xl font-bold text-green-600">{"Company Syncing Successful"}</div>
                          </>
                        ) : syncingStep?.status === 417 ? (
                          <>
                            <div className="w-16 h-16">
                              <img src={syncSuccess} alt="syncSuccess" />
                            </div>
                            <div className="flex text-center justify-center items-center text-xl font-bold text-blue-600">{syncingStep?.message || "Comapany Already Exists"}</div>
                          </>
                        ) : (
                          <>
                            <div className="w-16 h-16">
                              <img src={syncFailed} alt="syncFailed" />
                            </div>
                            <div className="flex text-center justify-center items-center text-xl font-bold text-rose-600">{"Company Syncing Failed. Please Try Again."}</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              {syncingStep?.step === "2" && (
                <>
                  <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5 flex-shrink-0">
                    {["employees", "positions"].map((type) => (
                      <button
                        key={type}
                        type="button"
                        className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform ${
                          tabType === type ? "text-teal-500 border-teal-500 font-semibold" : "text-slate-500 border-transparent hover:text-teal-500 hover:border-teal-500"
                        }`}
                        onClick={() => setTabType(type)}
                      >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </button>
                    ))}
                  </div>

                  {tabType === "employees" && (
                    <>
                      {!loading && (
                        <>
                          <div className="flex-shrink flex-grow">
                            <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2">
                              <div className="relative">
                                <Select
                                  xPlacement={"left"}
                                  dropdownButtonClass={"!bg-white"}
                                  dropdownClass={"w-auto"}
                                  selectedValue={limit}
                                  dropdownData={[
                                    {
                                      _id: 1000000,
                                      label: t("all_items"),
                                      value: 1000000,
                                    },
                                    ...[
                                      {
                                        _id: 10,
                                        label: "10 " + t("items"),
                                        value: 10,
                                      },
                                      {
                                        _id: 20,
                                        label: "20 " + t("items"),
                                        value: 20,
                                      },
                                      {
                                        _id: 30,
                                        label: "30 " + t("items"),
                                        value: 30,
                                      },
                                      {
                                        _id: 50,
                                        label: "50 " + t("items"),
                                        value: 50,
                                      },
                                      {
                                        _id: 100,
                                        label: "100 " + t("items"),
                                        value: 100,
                                      },
                                    ],
                                  ]}
                                  getSelectedValue={(e) => {
                                    setLimit(e.value);
                                    setOffset(0);
                                    setCurrentPage(0);
                                    setSelectedMember([]);
                                  }}
                                />
                              </div>
                              <div className="relative w-48">
                                <Select
                                  xPlacement={"left"}
                                  dropdownButtonClass={"!bg-white"}
                                  dropdownClass={"w-auto"}
                                  selectedValue={selectedRole}
                                  dropdownData={[
                                    { _id: "", label: "Select Role" },
                                    ...roleList.map((item) => ({
                                      _id: item?.code,
                                      label: item?.name,
                                      value: item?.code,
                                    })),
                                  ]}
                                  getSelectedValue={(e) => {
                                    setSelectedRole(e.value);
                                    setOffset(0);
                                    setCurrentPage(0);
                                    setSelectedMember([]);
                                  }}
                                />
                              </div>
                              {/* {selectedMember?.length > 0 && (
                                <div className="relative">
                                  <h1 className="font-semibold">
                                    <span className="text-sm text-gray-500">
                                      {selectedMember.length} Employee
                                      {selectedMember.length > 1 ? "s" : ""} Selected
                                    </span>
                                  </h1>
                                </div>
                              )} */}
                              {userList?.filter((member) => member?._id).length > 0 && (
                                <div className="ml-auto" data-tooltip-id={`selectAllButton${1434}`} data-tooltip-place="bottom">
                                  <Button
                                    buttonType={"button"}
                                    buttonClasses={"bg-transparent !text-teal-500 !px-0"}
                                    buttonLabelClasses="text-teal-500"
                                    buttonIcon={areAllSelected ? "fa-solid fa-square-check" : "fa-light fa-square"}
                                    buttonIconPosition={"left"}
                                    buttonLabel={areAllSelected ? "Unselect All Employee" : "Select All Employee"}
                                    buttonHasLink={false}
                                    functions={() => handleSelectAll()}
                                  />
                                  <Tooltip
                                    id={`selectAllButton${1434}`}
                                    className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                                    render={() => (
                                      <div className="gap-x-1">
                                        <div className="text-[13px] font-normal leading-none flex items-center">
                                          <div className="font-semibold">{areAllSelected ? "Unselect All Employee" : "Select All Employee"}</div>
                                        </div>
                                      </div>
                                    )}
                                  />
                                </div>
                              )}

                              {selectedMember?.length > 0 && (
                                <div className="flex items-center">
                                  <Button
                                    buttonLabel={`Sync ${selectedMember.length} Employee${selectedMember.length > 1 ? "'s" : ""}`}
                                    buttonIcon={"fa-solid fa-rotate"}
                                    buttonIconPosition={"left"}
                                    buttonClasses={"!bg-teal-500 !text-white"}
                                    buttonLabelClasses={"!text-white"}
                                    functions={() => {
                                      if (syncingStep?.selectedWebsite?.code === "orgChart") {
                                        getGenarateToken(ORGCHART_BASE_URL, ORGCHART_API_URL);
                                      } else if (syncingStep?.selectedWebsite?.code === "9BoxNow") {
                                        getGenarateToken(NINEBOX_BASE_URL, NINEBOX_API_URL);
                                      } else {
                                        toastr.error("Please Select a Website For Syncing");
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-250px)] -mx-4 px-4">
                              {userList &&
                                Array.isArray(userList) &&
                                userList?.length > 0 &&
                                userList?.map((user, index) => {
                                  console.log("user:", user);
                                  return (
                                    <>
                                      <div className="bg-white rounded-md border border-slate-200 py-4 px-4 flex  gap-2 w-full">
                                        <div className="flex justify-start items-start py-2 flex-shrink-0">
                                          <div className="flex items-center justify-between" key={index}>
                                            <input
                                              type="checkbox"
                                              id={`${user?._id}`}
                                              checked={selectedMember.some((member) => member?._id === user?._id)}
                                              onChange={() => handleSelectedMember(user)}
                                              className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-teal-500 checked:hover:bg-teal-500 checked:bg-teal-500 "
                                            />
                                          </div>
                                        </div>
                                        <Indentification initial={initialsValue(user?.name)} image={null} alt={user?.name} fill={true} className={"flex-shrink-0"} size={"sm"} initialClass={" !text-sm"} />
                                        <div className="space-y-px flex-shrink flex-grow">
                                          <h3 className="text-sm font-semibold text-teal-500 capitalize !leading-none">
                                            <span>{user?.name || "N/A"}</span>
                                            <small className="text-slate-600 capitalize text-xs font-medium block">
                                              {(user?.role_details && Array?.isArray(user?.role_details) && user?.role_details?.length > 0 && user?.role_details[0]?.name) || "N/A"}
                                            </small>
                                          </h3>
                                          <div className="text-xs text-gray-500 flex items-center gap-2">
                                            <span>{user?.email || "N/A"}</span>
                                          </div>
                                          {/* <div className="text-sm text-gray-500 flex items-center gap-2">
                                            <span>
                                              {(user?.position_details &&
                                                Array?.isArray(
                                                  user?.position_details
                                                ) &&
                                                user?.position_details
                                                  ?.length > 0 &&
                                                user?.position_details[0]
                                                  ?.name) ||
                                                "N/A"}
                                            </span>
                                          </div> */}
                                        </div>
                                        {user?.syncingStatus === "processing" && (
                                          <div className="flex flex-col items-center ml-auto flex-shrink-0">
                                            <Loader className={"scale-50"} />
                                            <SyncingLoader className={"!text-xs"} />
                                          </div>
                                        )}
                                        {user?.syncingStatus === "success" && (
                                          <div className="flex flex-col items-center ml-auto flex-shrink-0">
                                            <div className="w-8 h-8">
                                              <img src={syncSuccess} alt="syncSuccess" />
                                            </div>
                                            <div className="flex text-center justify-center items-center text-xs font-semibold text-teal-600">{"Sync Finished"}</div>
                                          </div>
                                        )}
                                        {user?.syncingStatus === "failed" && (
                                          <div className="flex flex-col items-center ml-auto flex-shrink-0">
                                            <div className="w-8 h-8">
                                              <img src={syncFailed} alt="syncFailed" />
                                            </div>
                                            <div className="flex text-center justify-center items-center text-xs font-semibold text-rose-600">{"Sync Failed"}</div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="flex-shrink-0 mt-auto">
                            <Pagination
                              handlePageClick={handlePageClick}
                              noOfPage={noOfPage}
                              startDataCount={startDataCount}
                              endDataCount={endDataCount}
                              count={totalDataCount}
                              forcePage={currentPage}
                            />
                          </div>
                        </>
                      )}
                      {loading && <SyncListSkeleton type={"employee"} />}
                    </>
                  )}
                  {tabType === "positions" && (
                    <PositionList
                      syncingStep={syncingStep}
                      syncFailed={syncFailed}
                      syncSuccess={syncSuccess}
                      selectedPosition={selectedPosition}
                      setSelectedPosition={setSelectedPosition}
                      arePositionAllSelected={arePositionAllSelected}
                      setArePositionAllSelected={setArePositionAllSelected}
                      syncDataModalOpen={syncDataModalOpen}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyncDataPopup;
