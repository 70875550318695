import React from "react";
import { classNames } from "../../helper/classNames";

const SyncingLoader = ({className}) => {
  return (
    <>
      <style>
        {`
          .loaders {
            width: fit-content;
            font-weight: bold;
            font-family: monospace;
            font-size: 16px;
            color: #0000;
            background: linear-gradient(90deg, #326E68 calc(50% + 0.5ch), #000 0) right / calc(200% + 1ch) 100%;
            -webkit-background-clip: text;
            background-clip: text;
            animation: l7 2s infinite steps(11);
          }
          .loaders:before {
            content: "Syncing...";
          }
          @keyframes l7 {
            to {
              background-position: left;
            }
          }
        `}
      </style>
      <div className={classNames("loaders", className)}></div>
    </>
  );
};

export default SyncingLoader;
