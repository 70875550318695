import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import Button from "../components/form/Button";
import toastr from "toastr";

import { postData } from "../services/api";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import OtherPositionPopup from "../components/popup/OtherPositionPopup";
import OtherIndustryPopup from "../components/popup/OtherIndustryPopup";
import OtherStudyPopup from "../components/popup/OtherStudyPopup";
import OtherAreaExpertisePopup from "../components/popup/OtherAreaExpertisePopup";
import SurveyPopup from "../components/popup/SurveyPopup";

const ManageExecutive = () => {
  setTitle("Succession Now | Executive Audit 360");
  const { id } = useParams(); 
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState([]);
  const [education, setEducation] = useState([]);
  const [studies, setStudies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [areaOfExpertise, setAreaOfExpertise] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);
  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] =
    useState(false);
  const [enabledOtherStudyPopup, setEnabledOtherStudyPopup] = useState(false);
  const [enabledOtherIndustryPopup, setEnabledOtherIndustryPopup] =
    useState(false);
  const [enabledOtherAreaExpertisePopup, setEnabledOtherAreaExpertisePopup] =
    useState(false);
  const [actionValue, setActionValue] = useState({});
  const [isSave, setIsSave] = useState(false);
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [yearofexecutive, setYearofExecutive] = useState("");
  const [designation, setDesignation] = useState("");
  const [edu, setEdu] = useState("");
  const [study, setStudy] = useState("");
  const [industry, setIndustry] = useState("");
  const [industry1, setIndustry1] = useState("");
  const [industry2, setIndustry2] = useState("");
  const [primaryExpertise, setPrimaryExpertise] = useState("");
  const [primaryExp, setPrimaryExp] = useState("");
  const [secondaryExpertise, setSecondaryExpertise] = useState("");
  const [secondaryExp, setSecondaryExp] = useState("");
  const [expertise2, setExpertise2] = useState("");
  const [expertise3, setExpertise3] = useState("");
  const [expertiseYear2, setExpertiseYear2] = useState("");
  const [expertiseYear3, setExpertiseYear3] = useState("");
  const [mLevel, setMLevel] = useState("");
  const [managementExp, setManagementExp] = useState("");
  const [retirement, setRetirement] = useState("");
  const [nDevelopment, setNDevelopment] = useState("");
  const [desire, setDesire] = useState("");
  const [isPositionNotApplicable, setIsPositionNotApplicable] = useState(2);

  const [positionType, setPositionType] = useState(0);
  const [areaExpertiseType, setAreaExpertiseType] = useState(0);

  const [boardMambers, setBoardMambers] = useState([]);
  const [executiveDetails, setExecutiveDetails] = useState({});
  const [detailsId, setDetailsId] = useState("");
  const [reatingOptions, setReatingOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [enabledSurveyPopup, setEnabledSurveyPopup] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [otherIndustryType, setOtherIndustryType] = useState("");
  const [otherExpertiseType, setOtherExpertiseType] = useState("");

  useEffect(() => {
    const loadExecutiveDetails = async () => {
      setLoading(true);
      try {
        let payload;
        if (role === "customer-executive") {
          payload = {
            id: user?._id,
          };
        }else if (role === "customer-subadmin" || role === "customer-user") { 
          payload = {};
        } else {
          payload = {
            _id: id,
          };
        }
        const result = await postData("executive/details", payload);
        if (result.status === 403) {
          return navigate("/executives");
        } else {
          if (result.data) {
            setExecutiveDetails(result.data);
          }
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    if (role === "customer-admin" || 
      role === "customer-executive" || 
      role === "customer-subadmin" || 
      role === "customer-user" || id) {
      loadExecutiveDetails();
    }

    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "executive",
        });
        if (result.data) {
          setReatingOptions(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    employeeRatingOptionList();
  }, [role]);

  useEffect(() => {
    if (Object.keys(executiveDetails).length > 0) {      
      setDetailsId(executiveDetails?._id);
      setLastname(executiveDetails?.last_name);
      setFirstname(executiveDetails?.first_name);
      setYearofExecutive(executiveDetails?.board_year);
      setDesignation((executiveDetails?.position)?executiveDetails?.position:executiveDetails?.designation);     
      setEdu(executiveDetails?.education);
      setStudy(executiveDetails?.study);
      setIndustry(executiveDetails?.industry);
      setIndustry1(executiveDetails?.industry_one);
      setIndustry2(executiveDetails?.industry_two);
      setPrimaryExpertise(executiveDetails?.primary_expertise);
      setPrimaryExp(executiveDetails?.primary_experience);      
      setRetirement(executiveDetails?.retirement_window);
      setExpertise2(executiveDetails?.primary_expertise_two);
      setExpertise3(executiveDetails?.primary_expertise_three);
      setExpertiseYear2(executiveDetails?.primary_experience_two);
      setExpertiseYear3(executiveDetails?.primary_experience_three);
      if (
        executiveDetails?.executiveAudits &&
        executiveDetails?.executiveAudits.length > 0
      ) {
        setAnswers(
          reatingOptions.map((value) => {
            let _question = executiveDetails?.executiveAudits.filter(
              (it) => it?.questionId === value?.question?._id
            );
            if (_question.length > 0) {
              return {
                ..._question[0],
                ids: _question[0]?.ids,
                questionInfo : value
              };
            } else {
              return {
                questionId: value?.question?._id,
                answerId: null,
                answerLabel: null,
                rating: 0,
                comments: null,
                questionInfo : value
              };
            }
          })
        );
      } else {
        setAnswers(
          reatingOptions.map((value) => {
            return {
              questionId: value?.question?._id,
              answerId: null,
              answerLabel: null,
              rating: 0,
              comments: null,
              questionInfo : value
            };
          })
        );
      }
      setIsPositionNotApplicable(executiveDetails?.isPositionNotApplicable);
    }
  }, [executiveDetails, reatingOptions]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "study_add") {
      setStudies([...studies, actionValue?.result]);
      setStudy(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "industry_add") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry(actionValue.row_id);
    }else if (actionValue && actionValue.button_type === "industry_add1") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry1(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "industry_add2") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry2(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "area_add") {
      setAreaOfExpertise([...areaOfExpertise, actionValue?.result]);
      if (areaExpertiseType && areaExpertiseType === 1) {
        setPrimaryExpertise(actionValue?.row_id);
      } else if (areaExpertiseType && areaExpertiseType === 2) {
        setSecondaryExpertise(actionValue?.row_id);
      }
      setAreaExpertiseType(0);
    }else if (actionValue && actionValue.button_type === "area_add2") {
      setAreaOfExpertise([...areaOfExpertise, actionValue?.result]);
      setExpertise2(actionValue?.row_id);
    } else if (actionValue && actionValue.button_type === "area_add3") {
      setAreaOfExpertise([...areaOfExpertise, actionValue?.result]);
      setExpertise3(actionValue?.row_id);
    } else if (actionValue && actionValue.button_type === "area_add4") {
      setAreaOfExpertise([...areaOfExpertise, actionValue?.result]);
    } else if (actionValue && actionValue.button_type === "update-survey") {
      //setReatingOptions(actionValue.reatingOptions);
      setAnswers(actionValue.answers);
      setAutoSave(actionValue.autoSave);
    } else if (actionValue) {
      setPosition([...position, actionValue]);
      if (positionType && positionType === 1) {
        setDesignation(actionValue?._id);
      } 
      setPositionType(0);
    }
  }, [actionValue]);

  useEffect(() => {
    if (autoSave) {
      onSubmit();
    }
  }, [autoSave]);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          isBoardMember: 2,
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();    

    async function educationList() {
      try {
        const result = await postData("education/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          isBoardMember: 3,
        });
        if (result.data) {
          setEducation(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    educationList();

    async function studyList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type:"study"
        });
        if (result.data) {
          setStudies(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    studyList();

    async function industryList() {
      try {
        const result = await postData("industry/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setIndustries(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    industryList();

    async function areaExpertiseList() {
      try {
        const result = await postData("area-of-expertise/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setAreaOfExpertise(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    areaExpertiseList();   

    async function retimentList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "retirement-window",
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    retimentList();    
  }, []);

  const handelUpdate = async (question_id, answer_id, rating, label, index) => {
    setAnswers((answers) =>
      answers.map((item, i) => {
        if (item?.questionId === question_id) {
          if (answer_id === "comments") {
            return {
              ...item,
              comments: rating,
            };
          } else {
            return {
              ...item,
              answerId: answer_id,
              answerLabel: label,
              rating: rating,
            };
          }
        } else {
          return item;
        }
      })
    );
  };

  const onSubmit = async (e) => {
    setIsSave(true);
    try {
      let path;
      let payload;
      if (detailsId) {
        path = "executive/update-all";
        payload = {
          id: detailsId,
          first_name : firstname,
          last_name:lastname,
          executive_year : yearofexecutive,
          retirement_window: retirement,
          position: designation !== "others" ? designation : null,
          education:edu,
          study:(study !== 'others')?study:null,
          industry:(industry !== 'others')?industry:null,
          primary_expertise:(primaryExpertise !== 'others')?primaryExpertise:null,
          primary_experience:primaryExp,
          industry1: industry1 !== "others" ? industry1 : null,
          industry2: industry2 !== "others" ? industry2 : null,
          ratingOptions: answers,
          primary_expertise_two: expertise2 !== "others" ? expertise2 : null,
          primary_experience_two: expertiseYear2,
          primary_expertise_three: expertise3 !== "others" ? expertise3 : null,
          primary_experience_three: expertiseYear3,
        };
      } else {
        path = "executive/create";
        payload = {
          id: detailsId,
          first_name: firstname,
          last_name: lastname,
          executive_year: yearofexecutive,
          education: edu,
          study: study !== "others" ? study : null,
          industry: industry !== "others" ? industry : null,
          primary_expertise:
            primaryExpertise !== "others" ? primaryExpertise : null,
          primary_experience: primaryExp,
          secondary_expertise:
            secondaryExpertise !== "others" ? secondaryExpertise : null,
          secondary_experience: secondaryExp,
          retirement_window: retirement,
          needed_development: nDevelopment,
          desire: desire,
          isPositionNotApplicable: isPositionNotApplicable,
          ratingOptions: answers,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsSave(false);
        setAutoSave(false)
        if (!detailsId) {
          return navigate("/executives");
        }
      } else {
        toastr.error(result.message);
        setIsSave(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsSave(false);
    }
  };
  return (
    <>
      <div className="relative d-flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">
          {t("executive_audit_360")}
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Input
                      label={t("last_name")}
                      labelClasses={"!text-xs !font-semibold"}
                      inputType={"text"}
                      inputPlaceholder={t("enter_last_name")}
                      inputValue={lastname}
                      setInput={setLastname}
                      requiredDisplay={true}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Input
                      label={t("first_name")}
                      labelClasses={"!text-xs !font-semibold"}
                      inputType={"text"}
                      inputPlaceholder={t("enter_first_name")}
                      inputValue={firstname}
                      setInput={setFirstname}
                      requiredDisplay={true}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("years_with_the_company")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={yearofexecutive}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: " ",
                          label: t("select_years_with_the_company"),
                          value: "",
                        },                          
                        ...Array(50)
                          .fill()
                          .map((_, index) => ({
                            _id: Number(index + 1),
                            label:
                              Number(index + 1) > 1
                                ? Number(index + 1) + " Years"
                                : Number(index + 1) + " Year",
                            value: Number(index + 1),
                          })),
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setYearofExecutive(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("executive_retirement_window")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={retirement}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_retirement_window"),
                          value: "",
                        },
                      //   ...retirementWindow.map((item) => ({
                      //     _id: item?._id,
                      //     label: item?.name,
                      //     value: item?._id,
                      //   })),
                      // ].filter((itm) => itm)}
                      ...Array(50)
                        .fill()
                        .map((_, index) => ({
                          _id: Number(index + 1),
                          label:
                            Number(index + 1) > 1
                              ? Number(index + 1) + " Years"
                              : Number(index + 1) + " Year",
                          value: Number(index + 1),
                        })),
                    ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setRetirement(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                </div>
                <div className='flex items-start gap-4'>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select 
                      label={t('current_position_held')}
                      labelClasses={'!text-xs !font-semibold'}
                      xPlacement={'left'} 
                      selectedValue={designation} 
                      dropdownClass={'!w-60'} 
                      dropdownData={
                          [
                            { _id: ' ', label: t('select_position'),value:''} ,
                            ...position.map((item) => (
                                { _id: item?._id, label: item?.name, value: item?._id }
                            )),
                            { _id: "others", label: t('other'), value: "others" },
                          ].filter((itm) => itm)                             
                      }
                      getSelectedValue={(e) => {
                        if(e._id === 'others'){
                          setEnabledOtherPositionPopup(true);
                          setPositionType(1);
                        }
                        setDesignation(e._id)
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("education")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={edu}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_education"), value: "" },
                        ...education.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        setEdu(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                </div>                  
                <div className="flex items-start gap-4">                    
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("field_of_study")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={study}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_study"), value: "" },
                        ...studies.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherStudyPopup(true);
                        }
                        setStudy(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                  <div className="relative w-1/2 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("industry_experience")+' - 1'}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={industry}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_industry"), value: "" },
                        ...industries.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherIndustryPopup(true);
                        }
                        setIndustry(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                </div>                  
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("industry_experience_2")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={industry1}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_industry"), value: "" },
                        ...industries.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherIndustryPopup(true);
                          setOtherIndustryType("industry_add1");
                        }
                        setIndustry1(e._id);
                      }}
                      requiredDisplay={false}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("industry_experience_3")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={industry2}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_industry"), value: "" },
                        ...industries.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherIndustryPopup(true);
                          setOtherIndustryType("industry_add2");
                        }
                        setIndustry2(e._id);
                      }}
                      requiredDisplay={false}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("primary_area_of_expertise")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={primaryExpertise}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_primary_area_of_expertise"),
                          value: "",
                        },
                        ...areaOfExpertise.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherAreaExpertisePopup(true);
                          setAreaExpertiseType(1);
                        }
                        setPrimaryExpertise(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("years_of_primary_experience")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={primaryExp}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_years_of_primary_experience"),
                          value: "",
                        },
                        ...Array(30)
                          .fill()
                          .map((_, index) => ({
                            _id: Number(index + 1),
                            label:
                              Number(index + 1) > 1
                                ? Number(index + 1) + " Years"
                                : Number(index + 1) + " Year",
                            value: Number(index + 1),
                          })),
                      ]
                        .filter((itm) => itm)
                        .sort((a, b) => b.value - a.value)}
                      getSelectedValue={(e) => {
                        setPrimaryExp(e._id);
                      }}
                      requiredDisplay={true}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("primary_area_of_expertise_2")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={expertise2}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_primary_area_of_expertise"),
                          value: "",
                        },
                        ...areaOfExpertise.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherAreaExpertisePopup(true);
                          setAreaExpertiseType(1);
                          setOtherExpertiseType("area_add2");
                        }
                        setExpertise2(e._id);
                      }}
                      requiredDisplay={false}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("years_of_primary_expertise_2")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={expertiseYear2}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_years_of_primary_experience"),
                          value: "",
                        },
                        ...Array(30)
                          .fill()
                          .map((_, index) => ({
                            _id: Number(index + 1),
                            label:
                              Number(index + 1) > 1
                                ? Number(index + 1) + " Years"
                                : Number(index + 1) + " Year",
                            value: Number(index + 1),
                          })),
                      ]
                        .filter((itm) => itm)
                        .sort((a, b) => b.value - a.value)}
                      getSelectedValue={(e) => {
                        setExpertiseYear2(e._id);
                      }}
                      requiredDisplay={false}
                    />
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("primary_area_of_expertise_3")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={expertise3}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_primary_area_of_expertise"),
                          value: "",
                        },
                        ...areaOfExpertise.map((item) => ({
                          _id: item?._id,
                          label: item?.name,
                          value: item?._id,
                        })),
                        { _id: "others", label: t("other"), value: "others" },
                      ].filter((itm) => itm)}
                      getSelectedValue={(e) => {
                        if (e._id === "others") {
                          setEnabledOtherAreaExpertisePopup(true);
                          setAreaExpertiseType(1);
                          setOtherExpertiseType("area_add3");
                        }
                        setExpertise3(e._id);
                      }}
                      requiredDisplay={false}
                    />
                  </div>
                  <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                    <Select
                      label={t("years_of_primary_expertise_3")}
                      labelClasses={"!text-xs !font-semibold"}
                      xPlacement={"left"}
                      selectedValue={expertiseYear3}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        {
                          _id: "",
                          label: t("select_years_of_primary_experience"),
                          value: "",
                        },
                        ...Array(30)
                          .fill()
                          .map((_, index) => ({
                            _id: Number(index + 1),
                            label:
                              Number(index + 1) > 1
                                ? Number(index + 1) + " Years"
                                : Number(index + 1) + " Year",
                            value: Number(index + 1),
                          })),
                      ]
                        .filter((itm) => itm)
                        .sort((a, b) => b.value - a.value)}
                      getSelectedValue={(e) => {
                        setExpertiseYear3(e._id);
                      }}
                      requiredDisplay={false}
                    />
                  </div>
                </div>  
              </div>
            </div>
            <div className="relative flex justify-between items-center px-5">
              <h5 className="text-black rounded-none w-full font-semibold text-xl px-5 py-3">
                {t("executive_audit_360")}
              </h5>
              <div className="">
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-clock"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("start_survey")}
                  buttonHasLink={false}
                  functions={() => {
                    setEnabledSurveyPopup(true);
                    // setReatingOptions([]);
                    // setAnswers([]);
                  }}
                />
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonClasses=""
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={
                    isSave ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={t("submit")}
                  functions={onSubmit}
                  buttonHasLink={false}
                  buttonDisabled={isSave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          data={{ isBoardMember: 2 }}
        ></OtherPositionPopup>
      )}
      {enabledOtherStudyPopup && (
        <OtherStudyPopup
          isOpen={enabledOtherStudyPopup}
          setIsOpen={(val) => setEnabledOtherStudyPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherStudyPopup>
      )}
      {enabledOtherIndustryPopup && (
        <OtherIndustryPopup
          isOpen={enabledOtherIndustryPopup}
          setIsOpen={(val) => setEnabledOtherIndustryPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          otherIndustryType={otherIndustryType}
        ></OtherIndustryPopup>
      )}      
      {enabledOtherAreaExpertisePopup && (
        <OtherAreaExpertisePopup
          isOpen={enabledOtherAreaExpertisePopup}
          setIsOpen={(val) => setEnabledOtherAreaExpertisePopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          otherExpertiseType={otherExpertiseType}
          setOtherExpertiseType={setOtherExpertiseType}
        ></OtherAreaExpertisePopup>
      )}
      {enabledSurveyPopup && (
        <SurveyPopup
          isOpen={enabledSurveyPopup}
          setIsOpen={(val) => setEnabledSurveyPopup(val)}
          data={answers}
          surveyFor={"executive"}
          isConfirmation={detailsId ? true : false}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SurveyPopup>
      )}
    </>
  );
};

export default ManageExecutive;
