import React, { useEffect, useState } from 'react';
import { classNames } from "../../helper/classNames";

const Tabs = ({
  tabs, 
  navTabClass,
  tabContentClass,
  divClass,
  buttonClass,
  activeButtonClass,
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState({})
  
  const handleTabSelection = (tab = {}) => {
    setTimeout(() => {
      let activeTab = [];
      let filteredTab = tabs.filter((tab) => activeTab.includes(tab._id))[0];
      if(filteredTab){
        activeTab = [...activeTab.filter((actab) => actab !== filteredTab._id), tab?._id]
      } else {
        activeTab = [...activeTab, tab?._id]
      }
      setSelectedTab(tab);
    }, 100)
  }

  useEffect(() => {
    if (tabs.length) {
      setSelectedTab(tabs[0])
    }
  }, [tabs]);
  
  return (
    <>
      <div className="relative">
        <div className={classNames("flex overflow-auto", navTabClass)}>
          <nav className={classNames("flex bg-[#02231F] shadow-lg mb-4  rounded-full space-x-2 p-0 xs:p-2", divClass)}>
            {tabs.map((item, index) => (
              <button 
                type="button" 
                className={classNames(
                  "relative px-3 xs:px-5 py-2 rounded-full transition-all duration-200 text-white font-PlusJakartaSans font-bold", 
                  buttonClass,
                  (item._id === selectedTab?._id) ? activeButtonClass + " bg-teal-500":"",
                  
                )} 
                key={index} 
                onClick={() => handleTabSelection(item)}
              >
                <div className={classNames(
                  "whitespace-nowrap text-sm xs:text-base transition-all duration-200",
                )}>{item.name}</div>
              </button>
            ))}
          </nav>
        </div>
        <div className={classNames("relative pt-5 pb-0 xs:py-10 px-0 xs:px-5 lg:px-20", tabContentClass)}>{selectedTab?.component}</div>
      </div>
    </>
  );
};

export default Tabs;