import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./form/Button";

import toastr from "toastr";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postData } from "../services/api";
import { authLogout } from "../store/authSlice";

import { useTranslation } from "react-i18next";
import { classNames } from "../helper/classNames";
import { useSelector } from "react-redux";

const LogoutPopup = ({ isOpen, setIsOpen = () => {} }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogout, setIsLogout] = useState(false);

  const user = useSelector((state) => state.auth?.user);

  const closeModal = () => {
    setIsOpen(false);
  };

  const logout = async (e) => {
    e.preventDefault();
    setIsLogout(true);
    try {
      const verifyData = await postData("verify-token", {});
      if (verifyData.data) {
        setIsLogout(false);
        dispatch(authLogout());
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginAs");
        localStorage.removeItem("role");
        localStorage.removeItem("isTrial");

        localStorage.removeItem("reminderId");
        localStorage.removeItem("reminderDate");
        localStorage.removeItem("reminderFiled");
        return navigate("/");
      } else {
        toastr.error(verifyData.message);
        setIsLogout(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsLogout(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50  w-full" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto flex items-center justify-center">
            <div className="flex items-center justify-center text-center m-auto w-full md:p-0 p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full bg-white rounded-3xl h-auto text-left shadow-2xl transform transition-all sm:my-8 p-0 sm:max-w-sm">
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => closeModal()}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className={classNames("md:py-6 py-4 md:px-6 px-4")}>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <div className="flex justify-start w-full items-center">
                        <i className="fa-regular fa-exclamation-triangle text-rose-600 rounded-full mr-2 text-2xl"></i>
                        <div className="text-lg font-bold pl-1">
                          {t("time_to_go")}
                        </div>
                      </div>
                      <hr className="w-full border-1 border-slate-400"></hr>
                      <div className="flex pt-6 text-sm text-gray-700 font-semibold text-center">
                        {t(
                          "when_you_are_ready_to_end_your_current_session_click_logout"
                        )}
                      </div>
                      <div className="flex items-center gap-4 mt-3">
                        <Button
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          buttonClasses={
                            "!bg-dark-teal-500 !border-dark-teal-500 hover:!bg-dark-teal-500/80 !rounded-lg"
                          }
                          functions={closeModal}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses={
                            "!bg-rose-600 !text-base !rounded-lg !py-2 !px-6 !text-white  hover:!bg-rose-600/80"
                          }
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={
                            isLogout
                              ? "fa-regular fa-spinner fa-spin"
                              : "fa-solid fa-arrow-right-from-bracket"
                          }
                          buttonIconPosition={"left"}
                          buttonLabel={t("logout")}
                          functions={logout}
                          buttonHasLink={false}
                          buttonDisabled={isLogout}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default LogoutPopup;
