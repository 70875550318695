import React from "react";
import { Document, Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const CompanyReport = ({ successors, user, educations, creditUnion, experiences, retirementWindow, creditUnionAvgLabel, experienceAvgLabel, retirementAvgLabel, companyLogo }) => {
  const { i18n, t } = useTranslation();
  const profileHeading = [
    {
      name: t("education_summary"),
    },
    {
      name: t("year_of_service"),
    },
    {
      name: t("senior_management_experience"),
    },
    {
      name: t("retirement_window"),
    },
  ];

  return (
    <Document>
      <Page
        size="A4"
        orientation="landscape"
        style={{
          padding: 20,
          fontFamily: "RobotoRegular",
          lineHeight: 1.2,
          fontSize: 9,
          color: "#334155",
          backgroundColor: "#f1f5f9",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
          fixed
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              width: "100%",
              height: 160,
              zIndex: -1,
              opacity: 0.1,
            }}
          >
            <Image style={{ objectFit: "cover" }} src={Shape} />
          </View>
          <View
            style={{
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: -10,
              right: 0,
              height: 160,
              zIndex: -1,
              opacity: 0.075,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
        </View>
        <View style={{ position: "absolute", right: 30, top: 30 }}>
          <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
        </View>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
              alignItems: "center",
              position: "relative",
              width: "100%",
            }}
          >
            <Image
              style={{
                width: 200,
                height: 40,
                objectFit: "contain",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              src={companyLogo}
            />
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 20, textTransform: "capitalize" }]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text
              style={[
                {
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  textTransform: "uppercase",
                  color: "#14b8a6",
                  letterSpacing: 3,
                  textAlign: "center",
                },
              ]}
            >
              Succession Plan for {user?.title}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
              <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>
                {user?.name}({user?.title})
              </Text>
            </View>
            <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[{ fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        <View style={{ position: "relative", marginTop: 2 }} wrap={false}>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", columnGap: 10 }}>
            <View style={{ width: "33.33%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <View
                      style={[
                        {
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoBold",
                          fontSize: 11,
                          textAlign: "center",
                        }}
                      >
                        {t("average_years_of_service")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "0 auto",
                      width: "100%",
                      border: "1px solid #e2e8f0",
                      backgroundColor: "white",
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      overflow: "hidden",
                    }}
                  >
                    <View
                      style={[
                        {
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign: "center",
                            fontFamily: "RobotoRegular",
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {creditUnionAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "33.33%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <View
                      style={[
                        {
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoBold",
                          fontSize: 11,
                          textAlign: "center",
                        }}
                      >
                        {t("management_experience_average")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "0 auto",
                      width: "100%",
                      border: "1px solid #e2e8f0",
                      backgroundColor: "white",
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      overflow: "hidden",
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 10,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign: "center",
                            fontFamily: "RobotoRegular",
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {experienceAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "33.33%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 12,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoBold",
                          fontSize: 11,
                          textAlign: "center",
                        }}
                      >
                        {t("average_retirement_window")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "0 auto",
                      width: "100%",
                      border: "1px solid #e2e8f0",
                      backgroundColor: "white",
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      overflow: "hidden",
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 10,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign: "center",
                            fontFamily: "RobotoRegular",
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {retirementAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {successors &&
          successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <View style={{ width: "100%" }} wrap={false}>
                {row.successors_details && row.successors_details.length > 0 && (
                  <View style={[{ display: "flex", flexDirection: "row", columnGap: 20, marginTop: 5, marginBottom: 5 }]} key={key}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                      {row.name} <Text style={[{ fontFamily: "RobotoRegular", fontSize: 10 }]}>{" (" + row.position + ")"}</Text>
                    </Text>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                      Retirement Window : {row?.retirement ? row?.retirement?.replace(/(Eliminates this candidate)/gi, "").replace(/[{()}]/g, "") : ""}
                    </Text>
                  </View>
                )}
                {row.successors_details && row.successors_details.length > 0 && (
                  <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20 }} key={key}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#0f172a",
                        color: "#ffffff",
                        overflow: "hidden",
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontFamily: "RobotoBold",
                            fontSize: 12,
                            color: "#ffffff",
                            width: "20%",
                            maxWidth: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 6,
                          },
                        ]}
                      >
                        &nbsp;
                      </Text>
                      {row.successors_details.map((item, index) => (
                        <View
                          key={index}
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              fontFamily: "RobotoBold",
                              fontSize: 12,
                              color: "#ffffff",
                              width: "20%",
                              maxWidth: "100%",
                              paddingHorizontal: 6,
                              paddingVertical: 6,
                            },
                          ]}
                        >
                          <Text style={{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }}>{item.name}</Text>
                          <Text style={{ fontSize: 8, marginTop: 2 }}>{item?.position_details ? item?.position_details[0]?.short_name : "N/A"}</Text>
                        </View>
                      ))}
                    </View>
                    <View
                      style={{
                        width: "100%",
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                        backgroundColor: "white",
                      }}
                    >
                      {profileHeading.map((items, index) => (
                        <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                          <View
                            style={[
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                fontSize: 10,
                                width: "20%",
                                maxWidth: "100%",
                                paddingHorizontal: 12,
                                paddingVertical: 12,
                              },
                            ]}
                          >
                            <Text style={[{ fontFamily: "RobotoMedium", width: "100%", fontSize: 10, lineHeight: 1 }]}>{items.name}</Text>
                          </View>
                          {row.successors_details.map((item, index2) => (
                            <View
                              key={index + "_" + index2}
                              style={[
                                {
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                  fontSize: 10,
                                  width: "20%",
                                  maxWidth: "100%",
                                  paddingHorizontal: 12,
                                  paddingVertical: 12,
                                },
                              ]}
                            >
                              {index === 0 && <Text style={{ fontSize: 9 }}>{item?.education_details && item?.education_details.length > 0 ? item?.education_details[0]?.name : "N/A"}</Text>}
                              {index === 1 && <Text style={{ fontSize: 9 }}>{item?.cu_details && item?.cu_details.length > 0 ? item?.cu_details[0]?.name : "N/A"}</Text>}
                              {index === 2 && <Text style={{ fontSize: 9 }}>{item?.experience_details && item?.experience_details.length > 0 ? item?.experience_details[0]?.name : "N/A"}</Text>}
                              {index === 3 &&
                                (item.retirement_details.length > 0 && item?.retirement_details[0].isPass ? (
                                  <Text style={{ fontSize: 9 }}>
                                    {Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0 ? (item?.retirement_details[0]?.isPass ? "Pass: " : "Fail: ") : ""}
                                    {item?.retirement_details && item?.retirement_details.length > 0 ? item?.retirement_details[0]?.name : "N/A"}
                                  </Text>
                                ) : (
                                  <Text style={{ fontSize: 9, color: "red" }}>
                                    {Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0 ? (item?.retirement_details[0]?.isPass ? "Pass: " : "Fail: ") : ""}
                                    {item?.retirement_details && item?.retirement_details.length > 0 ? item?.retirement_details[0]?.name : "N/A"}
                                  </Text>
                                ))}
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  </View>
                )}
              </View>
            );
          })}

        <View style={{ position: "relative", marginTop: 10 }} wrap={false}>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", columnGap: 10 }}>
            <View style={{ flexDirection: "row", width: "25%" }}>
              <View
                style={{
                  flexDirection:'column',
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderRadius: 16,
                  overflow: "hidden",
                  marginBottom: 20,
                  color: "#ffffff",
                }}
              >
                <View style={{ flexDirection: "row", backgroundColor: "#0f172a", borderTopLeftRadius: 16, borderTopRightRadius: 16, paddingVertical: 6, paddingHorizontal: 10,flexShrink:0 }}>
                  <Text style={{ fontSize: 11, fontFamily: "RobotoBold", color: "#ffffff" }}>{t("education_summary")}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#e2e8f0",
                    overflow: "hidden",
                    flexShrink: 0
                  }}
                >
                  <View
                    style={[
                      {
                        fontSize: 12,
                        width: "100%",
                        paddingVertical: 6,
                        paddingHorizontal: 10,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                        color: "#334155",
                      }}
                    >
                      {t("level_of_education")}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    paddingVertical: 6,
                    paddingHorizontal: 10,
                    backgroundColor: "#ffffff",
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    flexShrink: 1,
                    flexGrow: 1
                  }}
                >
                  {educations &&
                    educations.length > 0 &&
                    educations.map((item, index) => {
                      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let finalSuccessor = successors.filter((obj) => !isEmptyObject(obj));
                      let educationCount =
                        finalSuccessor.length > 0 &&
                        finalSuccessor
                          .map((person) => person.successors_details)
                          .flat()
                          .map((successor) => successor.education_details)
                          .flat()
                          .filter((education) => education._id === item?._id).length;
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                          key={index}
                        >
                          <View
                            style={[
                              {
                                fontSize: 10,
                                maxWidth: "100%",
                                paddingVertical: 6,
                                width: "75%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoMedium",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  whiteSpace: "nowrap",
                                  color: "#334155",
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoLight",
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            style={[
                              {
                                fontSize: 10,
                                maxWidth: "100%",
                                paddingVertical: 6,
                                width: "25%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoMedium",
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  textAlign: "right",
                                  color: "#334155",
                                },
                              ]}
                            >
                              {educationCount ? educationCount : 0}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", width: "25%" }}>
              <View
                style={{
                  flexDirection: 'column',
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderRadius: 16,
                  overflow: "hidden",
                  marginBottom: 20,
                  color: "#ffffff",
                }}
              >
                <View style={{ flexDirection: "row", backgroundColor: "#0f172a", borderTopLeftRadius: 16, borderTopRightRadius: 16, paddingVertical: 6, paddingHorizontal: 10, flexShrink: 0 }}>
                  <Text style={{ fontSize: 11, fontFamily: "RobotoBold", color: "#ffffff" }}>{t("years_of_service")}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#e2e8f0",
                    overflow: "hidden",
                    flexShrink: 0
                  }}
                >
                  <View
                    style={[
                      {
                        fontSize: 12,
                        width: "100%",
                        paddingVertical: 6,
                        paddingHorizontal: 10,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                        color: "#334155",
                      }}
                    >
                      {t("years_with_company")}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    paddingVertical: 6,
                    paddingHorizontal: 10,
                    backgroundColor: "#ffffff",
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    flexShrink: 1,
                    flexGrow: 1
                  }}
                >
                  {creditUnion &&
                    creditUnion.length > 0 &&
                    creditUnion
                      ?.sort((a, b) => b.ordering - a.ordering)
                      ?.map((item, index) => {
                        const _isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
                        let _finalSuccessor = successors.filter((obj) => !_isEmptyObject(obj));
                        let yearCount =
                          _finalSuccessor.length > 0 &&
                          _finalSuccessor
                            .map((person) => person.successors_details)
                            .flat()
                            .map((successor) => successor.cu_details)
                            .flat()
                            .filter((credit) => credit._id === item?._id).length;
                        return (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                            key={index}
                          >
                            <View
                              style={[
                                {
                                  fontSize: 10,
                                  maxWidth: "100%",
                                  paddingVertical: 6,
                                  width: "75%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    whiteSpace: "nowrap",
                                    color: "#334155",
                                  },
                                ]}
                              >
                                {item?.name}
                              </Text>
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoLight",
                                    fontSize: 6,
                                    lineHeight: 1,
                                    color: "#9CA38F",
                                  },
                                ]}
                              >
                                {item?.option}
                              </Text>
                            </View>
                            <View
                              style={[
                                {
                                  fontSize: 10,
                                  maxWidth: "100%",
                                  paddingVertical: 6,
                                  width: "25%",
                                  display: "flex",
                                  flexDirection: "row",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    textAlign: "right",
                                    color: "#334155",
                                  },
                                ]}
                              >
                                {yearCount ? yearCount : 0}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", width: "25%" }}>
              <View
                style={{
                  flexDirection: 'column',
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderRadius: 16,
                  overflow: "hidden",
                  marginBottom: 20,
                  color: "#ffffff",
                }}
              >
                <View style={{ flexDirection: "row", backgroundColor: "#0f172a", borderTopLeftRadius: 16, borderTopRightRadius: 16, paddingVertical: 6, paddingHorizontal: 10, flexShrink: 0 }}>
                  <Text style={{ fontSize: 11, fontFamily: "RobotoBold", color: "#ffffff" }}>{t("management_experience")}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#e2e8f0",
                    overflow: "hidden",
                    flexShrink: 0
                  }}
                >
                  <View
                    style={[
                      {
                        fontSize: 12,
                        width: "100%",
                        paddingVertical: 6,
                        paddingHorizontal: 10,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                        color: "#334155",
                      }}
                    >
                      {t("management_experience")}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    paddingVertical: 6,
                    paddingHorizontal: 10,
                    backgroundColor: "#ffffff",
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    flexShrink: 1,
                    flexGrow: 1
                  }}
                >
                  {experiences &&
                    experiences.length > 0 &&
                    experiences.map((item, index) => {
                      const _isEmptyObjectExp = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let _finalExpSuccessor = successors.filter((obj) => !_isEmptyObjectExp(obj));
                      let expCount =
                        _finalExpSuccessor.length > 0 &&
                        _finalExpSuccessor
                          .map((person) => person.successors_details)
                          .flat()
                          .map((successor) => successor.experience_details)
                          .flat()
                          .filter((experience) => experience._id === item?._id).length;
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                          key={index}
                        >
                          <View
                            style={[
                              {
                                fontSize: 10,
                                maxWidth: "100%",
                                paddingVertical: 6,
                                width: "75%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoMedium",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  whiteSpace: "nowrap",
                                  color: "#334155",
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoLight",
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            style={[
                              {
                                fontSize: 10,
                                maxWidth: "100%",
                                paddingVertical: 6,
                                width: "25%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoMedium",
                                  width: "100%",
                                  fontSize: 10,
                                  lineHeight: 1,
                                  textAlign: "right",
                                  color: "#334155",
                                },
                              ]}
                            >
                              {expCount ? expCount : 0}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
            <View style={{ flexDirection: "row", width: "25%" }}>
              <View
                style={{
                  flexDirection: 'column',
                  width: "100%",
                  border: "1px solid #e2e8f0",
                  borderRadius: 16,
                  overflow: "hidden",
                  marginBottom: 20,
                  color: "#ffffff",
                }}
              >
                <View style={{ flexDirection: "row", backgroundColor: "#0f172a", borderTopLeftRadius: 16, borderTopRightRadius: 16, paddingVertical: 6, paddingHorizontal: 10, flexShrink: 0 }}>
                  <Text style={{ fontSize: 11, fontFamily: "RobotoBold", color: "#ffffff" }}>{t("retirement_summary")}</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#e2e8f0",
                    overflow: "hidden",
                    flexShrink: 0
                  }}
                >
                  <View
                    style={[
                      {
                        fontSize: 12,
                        width: "100%",
                        paddingVertical: 6,
                        paddingHorizontal: 10,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <Text
                      style={{
                        width: "100%",
                        fontFamily: "RobotoMedium",
                        fontSize: 10,
                        color: "#334155",
                      }}
                    >
                      {t("retirement_window")}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    paddingVertical: 6,
                    paddingHorizontal: 10,
                    backgroundColor: "#ffffff",
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    flexShrink: 1,
                    flexGrow: 1
                  }}
                >
                  {retirementWindow &&
                    retirementWindow.length > 0 &&
                    retirementWindow.map((item, index) => {
                      const _isEmptyObjectExp = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
                      let _finalRetirementSuccessor = successors.filter((obj) => !_isEmptyObjectExp(obj));
                      let retirementCount =
                        _finalRetirementSuccessor.length > 0 &&
                        _finalRetirementSuccessor
                          .map((person) => person.successors_details)
                          .flat()
                          .map((successor) => successor.retirement_details)
                          .flat()
                          .filter((retirement) => retirement._id === item?._id).length;
                      let isPass = item?.isPass;
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                          key={index}
                        >
                          <View
                            style={[
                              {
                                fontSize: 10,
                                maxWidth: "100%",
                                paddingVertical: 6,
                                width: "75%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            {isPass ? (
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    whiteSpace: "nowrap",
                                    color: "#334155",
                                  },
                                ]}
                              >
                                {item?.name}
                              </Text>
                            ) : (
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    whiteSpace: "nowrap",
                                    color: "red",
                                  },
                                ]}
                              >
                                {item?.name}
                              </Text>
                            )}
                            <Text
                              style={[
                                {
                                  fontFamily: "RobotoLight",
                                  fontSize: 6,
                                  lineHeight: 1,
                                  color: "#9CA38F",
                                },
                              ]}
                            >
                              {item?.option}
                            </Text>
                          </View>
                          <View
                            style={[
                              {
                                fontSize: 10,
                                maxWidth: "100%",
                                paddingVertical: 6,
                                width: "25%",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            {isPass ? (
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    textAlign: "right",
                                    color: "#334155",
                                  },
                                ]}
                              >
                                {retirementCount ? retirementCount : 0}
                              </Text>
                            ) : (
                              <Text
                                style={[
                                  {
                                    fontFamily: "RobotoMedium",
                                    width: "100%",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    textAlign: "right",
                                    color: "red",
                                  },
                                ]}
                              >
                                {retirementCount ? retirementCount : 0}
                              </Text>
                            )}
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ position: "relative", marginTop: 2 }} wrap={false}>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", columnGap:10 }}>

            <View style={{ width: "33.33%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 12,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoBold",
                          fontSize: 11,
                          textAlign: "center",
                        }}
                      >
                        {t("average_years_of_service")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "0 auto",
                      width: "100%",
                      border: "1px solid #e2e8f0",
                      backgroundColor: "white",
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      overflow: "hidden",
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 10,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign: "center",
                            fontFamily: "RobotoRegular",
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {creditUnionAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "33.33%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 12,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoBold",
                          fontSize: 11,
                          textAlign: "center",
                        }}
                      >
                        {t("management_experience_average")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "0 auto",
                      width: "100%",
                      border: "1px solid #e2e8f0",
                      backgroundColor: "white",
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      overflow: "hidden",
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 10,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign: "center",
                            fontFamily: "RobotoRegular",
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {experienceAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "33.33%" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#0f172a",
                      color: "#ffffff",
                      overflow: "hidden",
                      borderTopLeftRadius: 16,
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 12,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          width: "100%",
                          fontFamily: "RobotoBold",
                          fontSize: 11,
                          textAlign: "center",
                        }}
                      >
                        {t("average_retirement_window")}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      margin: "0 auto",
                      width: "100%",
                      border: "1px solid #e2e8f0",
                      backgroundColor: "white",
                      borderBottomRightRadius: 16,
                      borderBottomLeftRadius: 16,
                      overflow: "hidden",
                    }}
                  >
                    <View
                      style={[
                        {
                          fontSize: 10,
                          maxWidth: "100%",
                          paddingHorizontal: 8,
                          paddingVertical: 8,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            textAlign: "center",
                            fontFamily: "RobotoRegular",
                            fontSize: 10,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                          },
                        ]}
                      >
                        {retirementAvgLabel}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CompanyReport;
