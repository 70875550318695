import React from "react";
import Th from "./Th";
import IdenticalCheckbox from "../../form/IdenticalCheckbox";

const Thead = ({
  tableHeadData,
  getSortValue = () => {},
  getSortKey = () => {},
  totalDataCount,
  selectable,
  selectAllHandler = () => {},
  checked = false,
}) => {
  return (
    <>
      <thead>
        <tr className="text-white text-xs leading-none border-b border-slate-200 bg-slate-900">
          {selectable && (
            <th className="py-3 px-2 text-left max-w-[240px] border-none bg-slate-900 first:rounded-tl-lg last:rounded-tr-lg last:sticky last:right-0">
              <IdenticalCheckbox onChange={() => selectAllHandler()} checked={checked} />
            </th>
          )}
          {tableHeadData.map((item) => (
            <Th
              key={item?._id}
              width={item?.width}
              name={item?.name}
              value={item?.value}
              nameAlign={item?.align}
              isSort={item?.isSort}
              isFilter={item?.isFilter}
              getSortValue={getSortValue}
              functions={(val) => getSortKey(val)}
              dropdownData={item?.filterData}
              onFilter={item?.onFilter}
              isFilterSearch={item.isFilterSearch}
              totalDataCount={totalDataCount}
              type={item.type}
              checkboxChecked={item.checked || false}
              onCheckboxChange={() =>
                item.onCheckboxChange
                  ? item.onCheckboxChange(item.itemDetails || {})
                  : () => {}
              }
            />
          ))}
        </tr>
      </thead>
    </>
  );
};

export default Thead;
