import React, { useCallback, useEffect, useState } from "react";
import { postData } from "../services/api";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

const DeveloperPage = () => {
  const [successors, setSuccessors] = useState([]);
  const [boardMember, setBoardMember] = useState([]);
  const [users, setUsers] = useState([]);
  const [list, setList] = useState([]);
    const userData = useSelector((state) => state.auth?.user);


  const loadTotalList = useCallback(async () => {
    try {
      const result = await postData("verify-company-position-manage", {
        limit: 9999999999999,
        email: userData?.email,
      });
      if (result.data) {
        const data = result.data && result.data?.length > 0 && result.data[0];
        // setList(result.data);
        setBoardMember(data?.boardmember_details);
        setSuccessors(data?.successor_details);
        setUsers(data?.user_details);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, [userData]);

  useEffect(() => {
    loadTotalList();
  }, [loadTotalList]);

  const generateExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Define column headers
    worksheet.columns = [
      { header: "_id", key: "_id", width: 30 },
      { header: "Name", key: "name", width: 30 },
      { header: "Position Id", key: "positionId", width: 30 },
      { header: "Position Name", key: "positionName", width: 35 },
      { header: "Category", key: "category", width: 25 },
    ];

    // Helper function to add data to the worksheet
    const addDataToWorksheet = (data, category) => {
      data.forEach((item) => {
        let name = item.name;
        let positionName = "";
        let positionId = "";

        if (category === "Board Member") {
          positionId = item.position || "";
          positionName = item.position_name || "";
        } else {
          // Handle Users and Successors
          positionId = item.designation || "";
          positionName = item.position_name || "";
        }

        worksheet.addRow({
          _id: item._id,
          name: name,
          positionId: positionId,
          positionName: positionName,
          category: category,
        });
      });
    };

    // Add data for Successors, Board Members, and Users
    addDataToWorksheet(successors, "Successors");
    addDataToWorksheet(boardMember, "Board Member");
    addDataToWorksheet(users, "Users");

    // Write the workbook to a Blob and save it as an Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "data.xlsx");
    });
  };

  return (
    <div>
      <button
        onClick={generateExcel}
        className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
      >
        Download CSV
      </button>
      <div className="bg-white p-4">
        <h2 className="font-bold text-[30px]">
          Successors Position--------Total Data: {successors?.length}
        </h2>
        {successors?.length > 0 &&
          successors?.map((item) => (
            <div
              className="flex gap-24 border-b border-gray-800 p-1"
              key={item}
            >
              <ul>
                <li>{item?._id}</li>
              </ul>
              <ul>
                <li>{item?.name}</li>
              </ul>
              <ul>
                <li>{item?.designation || "N/A"}</li>
              </ul>
              <li> {item?.position_name || "N/A"}</li>
            </div>
          ))}
      </div>
      <div className="bg-pink-100 p-4 mt-5">
        <h2 className="font-bold text-[30px]">
          Board Member Position--------Total Data: {boardMember?.length}
        </h2>
        {boardMember?.length > 0 &&
          boardMember?.map((item) => (
            <div
              className="flex gap-24 border-b border-gray-800 p-1"
              key={item}
            >
              <ul>
                <li>{item?._id}</li>
              </ul>
              <ul>
                <li>{item?.name || "name"}</li>
              </ul>
              <ul>
                <li>{item?.position || "N/A"}</li>
              </ul>
              <li> {item?.position_name || "N/A"}</li>
            </div>
          ))}
      </div>
      <div className="bg-yellow-100 p-4 mt-5">
        <h2 className="font-bold text-[30px]">
          Users Position--------Total Data: {users?.length}
        </h2>
        {users?.length > 0 &&
          users?.map((item) => (
            <div
              className="flex gap-32 border-b border-gray-800 p-1"
              key={item}
            >
              <ul>
                <li>{item?._id}</li>
              </ul>
              <ul>
                <li>{item?.name}</li>
              </ul>
              <ul>
                <li>{item?.designation || "N/A"}</li>
              </ul>
              <li> {item?.position_name || "N/A"}</li>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DeveloperPage;
