import React from "react";

const ProfileCardSkeleton = () => {
  return (
    <div className="relative w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden p-6">
    <div className="absolute top-0 left-0 p-2">
      <div className="w-20 h-5 bg-gray-300 rounded-br-xl shimmer"></div>
    </div>

    <div className="flex flex-col items-center space-y-4 mt-8">
      <div className="w-16 h-16 bg-gray-300 rounded-full shimmer"></div>
      <div className="w-36 h-5 bg-gray-300 rounded shimmer"></div>
      <div className="w-24 h-4 bg-gray-200 rounded shimmer"></div>
    </div>
  </div>
  );
};

export default ProfileCardSkeleton;
