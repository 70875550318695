import React from "react";
import NoData from "../assets/image/no-data.png";
const NoDataFound = ({ label }) => {
  return (
    <>
      <div className="flex items-center justify-center flex-col gap-4 min-h-[24rem] px-4 bg-white">
        <div className="">
          <img
            src={NoData}
            alt="No Data"
            className="h-28 w-28 md:h-28 md:w-28 object-contain"
          />
        </div>
        <div className="text-center text-teal-600 text-lg md:text-xl font-normal max-w-xl mx-auto">
          {label || "No Data Available"}
        </div>
      </div>
    </>
  );
};
export default NoDataFound;
