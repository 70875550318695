import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const BuyPlanPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  type,
}) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [promoCode, setPromoCode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPay, setIsPay] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const paymentData = await postData("stripe/plan-create", {
        planId: data?.plan_id,
        company_id: data?._id,
      });
      if (paymentData.status && paymentData.status === 200) {
        setLoading(false);
        window.location.href = paymentData?.data?.redirect_url;
      } else {
        toastr.error(paymentData.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const onAutoPay = async () => {
    setIsPay(true);
    if (transactionId === "") {
      toastr.error("Transaction # field is required");
      setIsPay(false);
    } else if (checkNumber === "") {
      toastr.error("Cheque/ACH Number field is required");
      setIsPay(false);
    } else {
      try {
        const results = await postData("check-promocode", {
          promo_code: promoCode,
          company_id: data?._id,
          transaction_id: transactionId,
          check_number: checkNumber,
        });
        if (results.status && results.status === 200) {
          setIsPay(false);
          setIsOpen(false);
          return navigate("/companies");
        } else {
          setIsPay(false);
        }
      } catch (error) {
        setIsPay(false);
      }
    }
  };

  useEffect(() => {
    async function promoUser() {
      try {
        const result = await postData("check-promo-user", {
          company_id: data?._id,
        });
        if (result.data && result.data.promo_code) {
          setPromoCode(result.data.promo_code);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
    promoUser();
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("make_payment")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4">
                    <p className="text-sm text-gray-500 text-center mb-2">
                      {t(
                        "If_company_paid_via_cheque_ACH_for_activated_his_account"
                      )}
                    </p>
                    <div className="relative mb-3">
                      <Input
                        label={t("Transaction_#")}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t("enter_transaction_#")}
                        inputValue={transactionId}
                        setInput={setTransactionId}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        label={t("cheque_ACH_Number")}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t("enter_check_ach_number")}
                        inputValue={checkNumber}
                        setInput={setCheckNumber}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Button
                        buttonClasses="!bg-teal-500 mx-auto"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          isPay
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-arrow-right-to-arc"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("activated_account")}
                        functions={onAutoPay}
                        buttonHasLink={false}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="text-lg font-medium leading-6 text-gray-900 text-center">
                    OR
                  </div>
                  <div className="relative mt-4">
                    <p className="text-sm text-gray-500 text-center mb-2">
                      {t(
                        "through_your_staff_that_you_received_credit_card_information_from_the_company"
                      )}
                    </p>
                    <div className="relative mb-3">
                      <Button
                        buttonClasses="!bg-teal-500 mx-auto"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          loading
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-credit-card-front"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("pay_now")}
                        functions={onSubmit}
                        buttonHasLink={false}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default BuyPlanPopup;
