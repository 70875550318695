import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { useSelector } from "react-redux";
import { postData } from "../services/api";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import NoDataFound from "../components/NoDataFound";
import { API_URL } from "../config/host";

import { useTranslation } from "react-i18next";
import ReportTableSkeleton from "../components/loader/ReportTableSkeleton";
import PerspectiveOverallReport from "./pdf/PerspectiveOverallReport";

const ProspectiveBoardMemberReport = () => {
    setTitle("Succession Planning Software for your Business with Succession Now | Prospective Board Member Report");
    const { i18n, t } = useTranslation();
    const user = useSelector((state) => state.auth?.user);
    const [loading, setLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [perspectives, setPerspectives] = useState([]);

    useEffect(() => {
      async function proposedBoardMemberReport() {
          try {
              const result = await postData("proposed-board-member/report", {
              sortQuery: { created_at: 1 },
              type: "boardmember",
              });
              if (result.data) {
              setPerspectives(result.data);
              } else {
              console.log("Advance desire message", result.message);
              }
          } catch (error) {
              console.log("Error advance desire catch", error.message);
          }
      }
      proposedBoardMemberReport();
    }, []);

    const getDownload = (e) => {
        e.preventDefault();
        if (e.target.tagName.toLowerCase() === "a") {
        window.open(e.target.href, "_blank");
        }
    };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("prospective_board_member_report") + " - " + user?.company_name}</div>         
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-solid fa-file-pdf"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <PerspectiveOverallReport
                        title={t("prospective_board_member_report")}
                        headingTitle={t("prospective_board_members")}
                        user={user}
                        companyLogo={API_URL + user?.image}
                        type={"boardmember"}
                        perspectiveReport={perspectives}
                    />
                  </Document>
                }
                fileName="scorecard.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
              </PDFDownloadLink>
            </div>
          </button>          
        </div>
      </div>
      {/* <div className="relative flex gap-4 items-center mb-4">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
      </div> */}

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px]">
        <table className="min-w-max w-full table-auto">          
          <tbody className="text-slate-700 text-sm font-light bg-slate-300 divide-y divide-slate-200">
            <tr className="text-white font-bold text-sm leading-none bg-slate-900 !border-none">
                <th className="py-4 px-2 text-left max-w-[240px] z-10">Name</th>
                <th className="py-4 px-2  max-w-[240px] z-10">Level of Education</th>
                <th className="py-4 px-2  max-w-[240px] z-10">Field of Study</th>
                <th className="py-4 px-2  max-w-[240px] z-10">Industry Experience</th>
            </tr>
            {perspectives && perspectives.length > 0 ? (
                perspectives.map((items, index) => (
                    <tr className="bg-white" key={index}>
                        <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                            {items?.first_name + " " + items?.last_name}
                            {/* <span className="block text-xs font-bold leading-tight text-teal-500">{items?.position_details ? items?.position_details[0]?.short_name : "N/A"}</span> */}
                        </td>
                        <td key={index} className="py-3 px-2 text-left max-w-[240px] align-top">
                            <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                            {items?.education_details ? items?.education_details?.name : "N/A"}
                            </div>
                        </td>
                        <td key={index} className="py-3 px-2 text-left max-w-[240px] align-top">
                            <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                            {items?.study_details ? items?.study_details?.name : "N/A"}
                            </div>
                        </td>
                        <td key={index} className="py-3 px-2 text-left max-w-[240px] align-top">
                            <div className="relative flex flex-col justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded">
                                <div className="relative before:content-[''] before:absolute before:top-1.5 before:left-0 before:w-1 before:h-1 before:bg-black before:rounded-full pl-4">
                                {items?.industry_details ? items?.industry_details?.name : "N/A"}
                                </div>
                                <div className="relative before:content-[''] before:absolute before:top-1.5 before:left-0 before:w-1 before:h-1 before:bg-black before:rounded-full pl-4">
                                {items?.industry_one_details ? items?.industry_one_details?.name : "N/A"}
                                </div>
                                <div className="relative before:content-[''] before:absolute before:top-1.5 before:left-0 before:w-1 before:h-1 before:bg-black before:rounded-full pl-4">
                                {items?.industry_two_details ? items?.industry_two_details?.name : "N/A"}
                                </div>
                            </div>
                        </td>
                    </tr>
                ))
                ):(
                <tr className="!border-none">
                    {loading ? (
                    <td className="p-0 text-left" colSpan={11}>
                        <div className="space-y-5">
                        <ReportTableSkeleton />
                        <ReportTableSkeleton />
                        </div>
                    </td>
                    ) : (
                    <td className="text-left p-0" colSpan={11}>
                        <NoDataFound label={t("no_data_found")} />
                    </td>
                    )}
                </tr>
                )
            }            
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProspectiveBoardMemberReport;
