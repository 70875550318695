import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback,
} from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import Input from "../form/Input";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../form/Select";
import Textarea from "../form/Textarea";

const AddEditConcernHeadingsPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  loadList = () => {},
  id,
  type,
}) => {
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit " +
        (type === "concern")
        ? "Concern"
        : "Predictor" + " Heading"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Add " +
        (type === "concern")
        ? "Concern"
        : "Predictor" + " Heading"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [description, setDescription] = useState("");
  const [weight, setWeight] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [tierList, setTierList] = useState([]);
  const [selectedTier, setSelectedTier] = useState("");

  useEffect(() => {
    // Industry details
    const loadDetails = async () => {
      try {
        const result = await postData("heading/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    const defaultTier =
      tierList &&
      Array?.isArray(tierList) &&
      tierList.length > 0 &&
      tierList?.filter((item) => item?.setDefault === 1)[0];
    setName(details?.name);
    setAlias(details?.alias);
    setDescription(details?.description);
    setWeight(details?.weight);
    setSelectedTier(details?.tierId || defaultTier?._id);
  }, [details, tierList]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setAlias(details?.point);
    } else {
      setName("");
      setAlias("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = {};
      if (id) {
        path = "heading/update";
        payload = {
          id: id,
          name: name,
          alias: alias,
          description: description,
          weight: weight,
          type: type,
          tierId: selectedTier,
        };
      } else {
        path = "heading/create";
        payload = {
          name: name,
          alias: alias,
          description: description,
          weight: weight,
          type: type,
          tierId: selectedTier,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        if (type === "concern") {
          //   return navigate("/concern-headings");
          loadList();
          setIsOpen({ open: false, _id: null });
        } else {
          loadList();
          setIsOpen({ open: false, _id: null });
          //   return navigate("/predictor-headings");
        }
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const loadTierList = useCallback(async () => {
    try {
      const result = await postData("filter-item/list", {
        sortQuery: { ["created_at"]: -1 },
        type: "position-group",
      });
      if (result.status === 200) {
        setTierList(result.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    loadTierList();
  }, [loadTierList]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  {id ? t("edit") + " " : t("add") + " "}
                  {type === "concern" ? t("concern") : t("predictor")}{" "}
                  {t("heading")}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={
                      "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                    }
                    functions={() => {
                      setIsOpen({ open: false, _id: null });
                      setDetails("");
                      setName("");
                      setAlias("");
                      setDescription("");
                      setWeight("");
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative mt-4 border bg-white border-gray-200 p-4  rounded-xl">
                  <div className="relative grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                      <div className="space-y-4">
                        <div className="grid sm:grid-cols-12 gap-4">
                          <div className="relative col-span-12">
                            <Input
                              label={t("name")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("enter_name")}
                              inputValue={name}
                              setInput={(val) => {
                                setName(val);
                                setAlias(
                                  val.replaceAll(" ", "-").toLowerCase()
                                );
                              }}
                              requiredDisplay={true}
                            />
                          </div>
                          {type && type === "predictors" && (
                            <div className="relative col-span-12">
                              <Input
                                label={t("weight")}
                                labelClasses={"!text-xs"}
                                inputType={"number"}
                                inputPlaceholder={t("enter_weight")}
                                inputValue={weight}
                                setInput={setWeight}
                                requiredDisplay={true}
                              />
                            </div>
                          )}

                          <div className="relative col-span-12">
                            <Select
                              label={"Select Tier"}
                              labelClasses={"!text-xs !font-semibold"}
                              xPlacement={"left"}
                              selectedValue={selectedTier}
                              dropdownClass={"!w-auto"}
                              dropdownData={[
                                {
                                  _id: " ",
                                  label: "Select Tier",
                                  value: "",
                                },
                                ...tierList.map((item, index) => ({
                                  _id: item?._id,
                                  label: item?.name,
                                  value: item?._id,
                                })),
                              ]}
                              getSelectedValue={(e) => {
                                setSelectedTier(e?._id);
                              }}
                              requiredDisplay={true}
                            />
                          </div>
                          <div className="relative col-span-12">
                            <Textarea
                              label={t("description")}
                              labelClasses={"!text-xs"}
                              inputValue={description}
                              inputPlaceholder={t("enter_description")}
                              setTextarea={setDescription}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-end gap-4 mt-5">
                        <Button
                          buttonClasses={
                            "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                          }
                          buttonLabelClasses="text-dark-teal-500 "
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          functions={() => {
                            setIsOpen({ open: false, _id: null });
                            setDetails("");
                            setName("");
                            setAlias("");
                            setDescription("");
                            setWeight("");
                          }}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses=""
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={
                            isManage
                              ? "fa-light fa-spinner fa-spin"
                              : "fa-light fa-floppy-disk"
                          }
                          buttonIconPosition={"left"}
                          buttonLabel={t("save")}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={isManage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditConcernHeadingsPopup;
