import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import { Editor } from "@tinymce/tinymce-react";
import { tinymceApiKey } from "../../helpers";
import useWindowDimensions from "../../helper/useWindowDimensions";
import { useSelector } from "react-redux";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const EmailContentPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
  code,
}) => {
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const { height, width } = useWindowDimensions();
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [preview, setPreview] = useState("");
  const [actionValue, setActionValue] = useState({});

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    async function emailDetails() {
      try {
        const result = await postData("email-template/details", {
          code: code,
        });
        if (result.data) {
          setDescription(result.data?.content);
          let description = result.data?.content;
          let getName = data.length>0 && data.filter((item) => item?.conclusion_details?.length === 0)
          let year = new Date().getFullYear();
          let updatedDescription = description.replace(/{%USER_NAME%}/g, (getName.length>0?getName[0]?.name:''));
          let _updatedDescription = updatedDescription.replace(/{%ADMIN_NAME%}/g, user?.name).replace(/{%EMAIL%}/g, result.data?.from_email).replace(/{%YEAR%}/g, year);
          let imageUrl = "../logo-succession-now.svg";
          _updatedDescription = _updatedDescription.replace(/{%LOGO%}/g, imageUrl);
          setPreview(_updatedDescription)                    
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    emailDetails();
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);
      let path = "";
      let payload = {};
      if (code && code === "incomplete-reminder-email") {
        path = "assign/reminder-send";
        payload = {
          description: description,
          code: code,
        };
      } else if (code && code === "newplan-reminder-email") {
        path = "assign/reminder-send";
        payload = {
          description: description,
          code: code,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("reminder")}
                  </Dialog.Title>
                  <div className="relative w-full">
                    <Editor
                      apiKey={tinymceApiKey()}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      value={preview}
                      name={"description"}
                      init={{
                        height: width > 480 ? 500 : 240,
                        menubar: false,
                        plugins: [
                          "code",
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "code | undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help ",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onEditorChange={(newText) => setPreview(newText)}
                      wrapperClassName={"wrapperClassName"}
                    />
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t("send")}
                      functions={onSubmit}
                      buttonHasLink={false}
                    />
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500 "
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EmailContentPopup;
