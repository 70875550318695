import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import Checkbox from "../../form/Checkbox";
import Select from "../../form/Select";
import { useTranslation } from "react-i18next";

const ConcernPredictorsItem = ({
  item,
  index,
  tierDetails,
  getCheckboxValue = () => {},
  getSelectedValue = () => {},
  isSelectTiers = true,
}) => {
  const { i18n, t } = useTranslation();
  const [data, setData] = useState([
    {
      name: "Management",
      _id: 1,
      question: [
        {
          name: "Have you or members of your sales team ever been in a demo where the prospect wasn’t talking at all?",
          _id: 1,
        },
        {
          name: "How do you know if they’re really listening, or interested in what you’re presenting to them?",
          _id: 2,
        },
        {
          name: "What can you do to gauge their temperature and adapt your presentation to their interests or concerns?",
          _id: 3,
        },
      ],
    },
    {
      name: "Non-Exempt",
      _id: 2,
      question: [
        {
          name: "Have you or members of your sales team ever been in a demo where the prospect wasn’t talking at all?",
          _id: 4,
        },
        {
          name: "How do you know if they’re really listening, or interested in what you’re presenting to them?",
          _id: 5,
        },
        {
          name: "What can you do to gauge their temperature and adapt your presentation to their interests or concerns?",
          _id: 6,
        },
      ],
    },
    {
      name: "Executive",
      _id: 3,
      question: [
        {
          name: "Have you or members of your sales team ever been in a demo where the prospect wasn’t talking at all?",
          _id: 7,
        },
        {
          name: "How do you know if they’re really listening, or interested in what you’re presenting to them?",
          _id: 8,
        },
        {
          name: "What can you do to gauge their temperature and adapt your presentation to their interests or concerns?",
          _id: 9,
        },
      ],
    },
  ]);
  return (
    <div>
      <div
        className={`flex items-center justify-between gap-2 border border-dark-teal-300 rounded-md p-3 ${
          !item?.disabled && item?.isDefault ? "bg-blue-50" : "bg-transparent"
        }`}
        key={index}
      >
        <div className="flex-shrink flex-grow">
          <Checkbox
            checkboxLableClass={"text-slate-800"}
            checkboxInputClass={""}
            checkboxClass={"!rounded-full"}
            divClass={"!items-start"}
            checkboxName={item?._id}
            isChecked={item?.isDefault}
            checkboxLabel={item?.name}
            checkboxValue={item?._id}
            getCheckboxValue={(val, event) => getCheckboxValue(val, event)}
            isDisabled={item?.disabled}
          />
        </div>
        {isSelectTiers && !item?.disabled && (
          <div className="flex-shrink-0">
            <Select
              label={""}
              labelClasses={"!text-xs"}
              xPlacement={"bottomRight"}
              selectedValue={item?.isDefault ? item?.tierId : null}
              dropdownClass={"!w-32"}
              dropdownData={[
                {
                  _id: "",
                  label: t("select_tires"),
                  value: null,
                },
                ...tierDetails?.map((items, index) => ({
                  _id: items?.tire_id,
                  label: items?.title,
                  value: items?.tire_id,
                })),
              ]}
              getSelectedValue={(e) => getSelectedValue(e)}
            />
          </div>
        )}

        <div
          className="flex-shrink-0"
          data-tooltip-id={"info-tooltip-" + item?._id}
          data-tooltip-place="bottom"
        >
          {item?.description && (
            <>
              <Tooltip
                id={"info-tooltip-" + item?._id}
                className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                render={() => (
                  <div className="w-60 p-1 rounded-md">
                    <div className="text-[14px] font-normal">
                      <p className="font-normal text-white">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                )}
              />
              <i
                className="fa-light fa-circle-info"
                style={{ color: "#14b8a6" }}
              ></i>
            </>
          )}
        </div>
      </div>

    </div>
  );
};

export default ConcernPredictorsItem;
