import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Select from "../form/Select";
import { useSelector } from "react-redux";
import { postData } from "../../services/api";
import toastr from "toastr";
import moment from "moment/moment";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";
import EmailContentPopup from "./EmailContentPopup";
import { useTranslation } from "react-i18next";

const SendReminderPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [users, setUsers] = useState([]);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [planStatus, setPlanStatus] = useState("");
  const [enabledEmailContentPopup, setEnabledEmailContentPopup] =
    useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    async function usersData() {
      try {
        setProcessing(true);
        const result = await postData("assign/list", {
          is_invited: 1,
          planStatus: planStatus,
        });
        if (result.data) {
          setUsers(result.data.filter((it) => it?._id !== user?._id));
          setProcessing(false);
        } else {
          console.log("User list message", result.message);
        }
      } catch (error) {
        console.log("Error user list catch", error.message);
      }
    }
    usersData();
  }, [planStatus]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const result = await postData("assign/create-non-invited", {});
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("send_reminder")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>

                  <div className="relative flex justify-start items-center mb-4">
                    <div className="relative">
                      <Select
                        xPlacement={"left"}
                        dropdownButtonClass={"!bg-white"}
                        transitionClass={"!w-auto"}
                        selectedValue={planStatus}
                        dropdownData={[
                          { _id: "", label: "Select Status" },
                          { _id: 1, label: "Completed", value: 1 },
                          { _id: 2, label: "Incompleted", value: 2 },
                        ]}
                        getSelectedValue={(e) => setPlanStatus(e.value)}
                      />
                    </div>
                  </div>
                  <div className="relative overflow-x-auto mt-4 flex items-center justify-center h-full">
                    {users && users.length > 0 ? (
                      <table className="w-full text-sm text-left">
                        <thead className="text-xs text-white uppercase bg-gray-900">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3  font-normal text-xs whitespace-nowrap rounded-tl-md"
                            >
                              {t("name")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 font-normal text-xs whitespace-nowrap"
                            >
                              {t("position")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3  font-normal text-xs whitespace-nowrap"
                            >
                              {t("invitation_sent")}
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3  font-normal text-xs whitespace-nowrap"
                            >
                              {t("plan_status")}
                            </th>
                            {/* <th scope="col" className="px-6 py-3 text-slate-400 font-normal text-xs whitespace-nowrap rounded-tr-md">
                              {t("action")}
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((item, index) => {
                            //if(item?._id !== user?._id)
                            return (
                              <tr className="bg-white border-b" key={index}>
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {item?.name}
                                  <div className="">{item?.email}</div>
                                </th>
                                <td className="px-6 py-4">{item?.title}</td>
                                <td className="px-6 py-4">
                                  {item && item?.created_at
                                    ? moment(new Date(item?.created_at)).format(
                                        "MMM Do YYYY"
                                      )
                                    : "N/A"}
                                </td>
                                <td className="px-6 py-4">
                                  <div className="flex items-center">
                                    {item?.conclusion_details &&
                                    item?.conclusion_details?.length > 0 ? (
                                      <div
                                        className={
                                          "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-teal-100 text-teal-600"
                                        }
                                      >
                                        {t("complete")}
                                      </div>
                                    ) : (
                                      <div
                                        className={
                                          "text-[10px] font-medium leading-tight rounded py-1 px-2 bg-rose-100 text-rose-600"
                                        }
                                      >
                                        {t("incomplete")}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                {/* <td className="px-6 py-4 flex justify-end">
                                    {
                                      (item?.subscription_details?.subscription_type && item?.subscription_details?.subscription_type === 'manual') && 
                                      <Button
                                        buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"}
                                        buttonType={"button"}
                                        buttonIcon={"fa-regular fa-pencil"}
                                        buttonIconPosition={"left"}
                                        buttonHasLink={false}
                                        functions={() => {
                                          setRenewalInfo({
                                            _id:item?._id,
                                            price : item?.price,
                                            start_date : item?.start_date,
                                            end_date : item?.end_date,
                                            status: item?.status,
                                          })
                                          setIsShow(true)
                                        }}
                                      />
                                    }
                                  </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : processing ? (
                      <Searching label={t("searching")} />
                    ) : (
                      <NoDataFound label={t("no_data_found")} />
                    )}
                  </div>
                  {users && users.length > 0 && (
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                      <Button
                        buttonClasses="!bg-teal-500"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          loading
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-info-circle"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("incomplete_reminder")}
                        functions={() => {
                          setEnabledEmailContentPopup(true);
                          setCode("incomplete-reminder-email");
                        }}
                        buttonHasLink={false}
                      />
                      <Button
                        buttonClasses="!bg-teal-500"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          loading
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-plus"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("new_plan_reminder")}
                        functions={() => {
                          setEnabledEmailContentPopup(true);
                          setCode("newplan-reminder-email");
                        }}
                        buttonHasLink={false}
                      />
                      {/* <Button 
                            buttonLabelClasses=''
                            buttonType={'button'} 
                            buttonIcon={'fa-light fa-times'} 
                            buttonIconPosition={'left'} 
                            buttonLabel={t('cancel')} 
                            buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                            functions={closeModal}
                            buttonHasLink={false}
                          /> */}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {enabledEmailContentPopup && (
        <EmailContentPopup
          isOpen={enabledEmailContentPopup}
          setIsOpen={(val) => setEnabledEmailContentPopup(val)}
          data={users}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          code={code}
        ></EmailContentPopup>
      )}
    </>
  );
};

export default SendReminderPopup;
