import React from 'react';

const SecHelpHtml = () => {
  return (
    <>
      <div className="relative mb-8 space-y-2">
        <div className="text-3xl uppercase font-bold text-center">
          Tutorial/Instruction Manual
        </div>
        <div className="text-xl uppercase font-bold text-center">
          SuccessionNow!
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">General Overview</div>
        <div className="text-sm">
          SuccessionNow! is designed to first and foremost create an effective and efficient pathway for succession planning within companies. Its aim is to 1) Minimize service disruptions during
          times of management transition; 2) Ensure organizational viability over the long term; 3) Clarify employee development paths; 4) Recognize (identify), evaluate, and develop current
          talent; 5) Create opportunities for employees, and relieve the stress created by turnover in key positions. Successions Plans are being recognized as vital in many industries. As an
          example, the company industries’ regulatory arm (The NCUA) has proposed regulations requiring Companies to have functioning succession plans. Required or not, succession plans importance
          is critical for the on-going management of successful organizations.
        </div>
        <div className="text-sm">SuccessionNow is designed to do this with a straight forward methodology that is time-sensitive, user friendly, and comprehensive.</div>
        <div className="text-sm">The following sections outline each of the components of SuccessionNow!</div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">The Profile Section:</div>
        <div className="text-sm">
          This Section allows the user to sign-in and set up their own profile; their name, current position (the position being evaluated for future replacement) and the evaluator (generally the
          same person person).
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Positions & Competencies</div>
        <div className="text-sm">
          This section lists many Sr. Management positions found within companies. The titles are generic, (your exact titles may differ), but the competencies & descriptions will be easy to
          identify. By selecting a position from the drop-down (from the <strong>Profile Section</strong>), the competency for that position will automatically appear providing key information
          regarding the position. If desired, the Administration can edit the information provided and add additionally competencies. On the <strong>Replacement Readiness</strong> section, the
          evaluator will be asked to determine if the competency is a good fit for the Potential Successor.
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Successor Section</div>
        <div className="text-sm">
          This section allows the evaluator to identify a roster of potential successors. Typically, this includes the evaluator’s direct reports, but outliers (not a direct report) can also be
          included. List their name and their current title within the organization (maximum of four per position). The screen will then direct you to create their individual profile. The
          following succession factors will appear
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Individual Profile</div>
        <div className="space-y-2">
          <div className="text-sm">
            <strong>1. Years of Executive Experience:</strong> Self-explanatory with a drop-down scale to select from. Years of executive
            experience can come from both inside the current company or outsidefrom other organizations and is defined as vice-president or higher.
          </div>
          <div className="text-sm">
            <strong>2. Years with Company:</strong> Self-explanatory with a drop-down scale to select from.The results of this item arenot
            calculated in the numerical ratings or on the scorecard; it is provided for informational purposes only and can be used as a “tie-breaker” if needed.
          </div>
          <div className="text-sm">
            <strong>3. Highest Level Education:</strong> Self-explanatory with a drop-down scale to select from. The highest rating is an MBA
            or equivalent. of higher. Any Master’s Degree qualifies as an MBA equivalent. PHD’s or law degrees are considered at the same level as MBA’s or equivalent even though they are higher.
            Designations such as CPA, AEP, etc. are not included – only the highest level of graduation from an accredited university or college.
          </div>
          <div className="text-sm">
            <strong>4. Recent Performance Review:</strong> Self-explanatory with a drop-down to select from. Your company’s Performance level
            designations should be included on this drop-down. If a candidate has not yet had a formal performance rediv, an informal or subjective rating from what the evaluator has witnessed to
            date, can be included in this section.
          </div>
          <div className="text-sm">
            <strong>5. Loyalty towards the Company:</strong> Self-explanatory with a drop-down scale to select from. This is a Pass/Fail
            rating. Any Potential Successor graded “questionable” will receive a zero score and be eliminated from succession consideration. Reasoning: hopefully obvious!
          </div>
          <div className="text-sm">
            <strong>6. Desire to Advance:</strong> Self-explanatory with a drop-down scale to select from. A key consideration is the
            Candidate’s actual desire for upward mobility. Upward mobility may not be important to all employees, but, important to know! This is a Pass/Fail rating. The choices are, No Desire to
            advance, Desires Advancement and Unquestionable Desire to advance. Any Candidate graded “no desire”, fails this question and will be eliminated from final succession consideration. To
            be clear, a rating of “no desire” is not an indicator of a bad employee,simply, the employee may be satisfied and secure in their current position, with no aspiration to advance.
          </div>
          <div className="text-sm">
            <strong>7. Retirement Window:</strong> Self-explanatory with a drop-down to select from. Understanding when a Potential
            Successor’s plan on retiring is key to succession planning. Knowing when Potential Successors plan on retiring, clearly directs succession thinking and planning.If a Candidate’s
            retirement window is short, it may not warrant the time, effort and energy for evaluation. A candidate with a Retirement Window of 0-2 years will be eliminated from succession
            consideration. If the evaluator would still like to consider a Candidate with a short retirement window, this section can easily be over-ridden.
          </div>
          <div className="text-sm">
            <strong>8. Additional Comments:</strong> Area to free-style, provide justification comments or informational notes
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Conditions of Concerns</div>
        <div className="space-y-2">
          <div className="text-sm">1. Each Potential Successor will automatically appear on the columns (from the listing on the prior section)– maximum of four candidates per position.</div>
          <div className="text-sm space-y-2">
            <div className="text-sm">2. Current Potential Concerns:</div>
            <div className="text-sm">Eight potential concerns are listed. “Concerns” are issues that could be detrimental to a candidate’s advancement, if not identified and corrected. “Concerns” couldslow-down or even thwart aPotential Successor’s advancement if not addressed. The “Concerns” are conditions of criticality; necessary for succession readiness…they are weighted equally, and scored on a scale from 1-5:</div>
            <div className="pl-4 space-y-2">
              <div className="text-sm">
                <strong>No Concern (5.0):</strong> Concernsare not an issue at all;
              </div>
              <div className="text-sm">
                <strong>Easily Correctable Concern(4.0): </strong> Infrequent lapses requiring some attention;
              </div>
              <div className="text-sm">
                <strong>Minor Concern (3.0): </strong> Attention is required;
              </div>
              <div className="text-sm">
                <strong>Significant Concern (2.0): </strong> Potential deal-breaker if not addressed & corrected;
              </div>
              <div className="text-sm">
                <strong>Major Concern (1.0).</strong> Immediate attention & remedial action required.
              </div>
            </div>
            <div className="pl-4 space-y-4">
              <div className="text-sm"><strong>Following are the list of Concerns:</strong> Evaluate the Candidate’s current levels of concerns</div>
              <div className="pl-4 space-y-2">
                <div className="text-sm">
                  <strong>Lack of Leadership Ability </strong> (Ability to Inspire)
                </div>
                <div className="text-sm">
                  <strong>Lack of Management Breadth </strong> (Understanding of all required areas of the replacement position)
                </div>
                <div className="text-sm">
                  <strong>Lack of Operational Expertise </strong> (Depth of understanding & ability to deliver)
                </div>
                <div className="text-sm">
                  <strong>Lack of ExecutiveDemenor </strong> (Outward or professional conduct or behavior – the candidate “looks the part”)
                </div>
                <div className="text-sm">
                  <strong>Inadequate Public/Business Image </strong> (Inadequate Public/Business Image )
                </div>
                <div className="text-sm">
                  <strong>Lack of Organizational Maturity </strong> (Understands tact, diplomacy, effective reasoning – also infuses an element of
                  humility)
                </div>
                <div className="text-sm">
                  <strong>Lack of Organizational Energy</strong> (Easily winded, unable to perform required assignments)
                </div>
                <div className="text-sm">
                  <strong>Lack of Strategic Vision</strong> (Focus is on long-term plans, ideas, improvements & programs – not just tactical)
                </div>
              </div>
            </div>
          </div>
          <div className="text-sm space-y-2">
            <div className="text-sm">3. Overall Score (1-5; 5 = Best): The final score for the Conditions of Concern will be shown for each Potential
              Successor. It will be a weighted average of all of the eight “Concerns” scored. </div>
          </div>
          <div className="text-sm space-y-2">
            <div className="text-sm">4. Additional Comments: Area to free-style, provide justification comments or informational notes. This can
              also be an area to provide counsel for a Candidate in areas of concern. </div>
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Potential Predictors</div>
        <div className="space-y-2">
          <div className="text-sm">
            <strong>1. Potential Predictors </strong> are behaviors, competencies or characteristics that can signal readiness for advancement.
            They form the make-up for Executive & Senior Management level positions. Successful Successors will score relatively high in each of the listed predictors. Unlike performance rediv factors, that score current or past performance, these predictors are used to help determine a candidate’s preparation or propensity for future needs. It provides a vital evaluation for succession planning and is the key-stone for mapping out future development needs and concerns for future executives. Each Potential Successor will automatically appear on the columns – maximum of four per position.
          </div>
          <div className="text-sm">
            <strong>2. Weightings: </strong> The Potential Predictors are weighted according to their importance. SuccessionNow! has pre-set the Predictor weightings at levels deemed appropriate and consistent with their overall value. However, each client can re-set the weights according to their desires and/or needs. The weightings must total 100%.
          </div>
          <div className="text-sm">
            <strong>3. Potential Predictors and Descriptions: </strong> Each Potential Predictor is identified by title and description. The
            descriptors provide depth and completeness to the title. Read them carefully. The Predictors are;  <strong>Propensity to Lead, Capacity to Advance, Strategic Thinking, Results Oriented, Teamwork & Cooperation, Agent for Change, Decision-Making and Professionalism.</strong> Each Predictor will be rated from a 5-pointdrop-down scale. The overall weighted score will appear on the bottom of the section per candidate. When completed, the evaluator will be able to
            “sore-thumb” or do a side-by-side peer comparison. If any adjustments or edits are desired, the evaluator simply needs to hit the drop-down and make the appropriate change. The rating scale is:
          </div>
          <div className="pl-4 space-y-2">
            <div className="text-sm">
              <strong>5.0:</strong> Clear Strength: Requires little to no improvement
            </div>
            <div className="text-sm">
              <strong>4.5:</strong> Strength: Strong performer; minor refinement helpful
            </div>
            <div className="text-sm">
              <strong>4.0:</strong> Skilled & Capable: Additional seasoning would be beneficial
            </div>
            <div className="text-sm">
              <strong>3.5:</strong> Progressing very well: On-going refinement & seasoning needed
            </div>
            <div className="text-sm">
              <strong>3.0:</strong> Progressing positively: Continued development & seasoning necessary
            </div>
            <div className="text-sm">
              <strong>2.5:</strong> Needs Improvement: Clearly needs Improvement; not yet there
            </div>
            <div className="text-sm">
              <strong>2.0:</strong> Needs Significant Improvement: A long road ahead with a doubtful futuret
            </div>
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Succession Plan Scorecard</div>
        <div className="space-y-2">
          <div className="text-sm">
            <strong>1. The Scorecard:</strong> The Scorecard provides the final or summary scoring on all input and evaluated sections in a 1-5
            rating scale (automatically); it is weighted according to the weights set on the left-hand side of the section. In essence, provides the final Succession Planning Ranking. Corrections
            or edits can easily be made by going back to any section in question and making the adjustment/edit. The Scorecard screen will automatically and immediately re-calculate. Weights are
            pre-set, but may be adjusted according to the desires of the company. Totally 100%.
          </div>
          <div className="text-sm">
            <strong>2. Additional Comments: </strong>
            Area to free-style, provide justification comments or informational notes. This can also be an area to provide counsel for a candidate in areas of concern.
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Succession Planning Summary </div>
        <div className="space-y-2">
          <div className="text-sm"> This section simply displays the results from the Scorecard in a Bar Graph for easy rediv and peer comparison</div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Development Plan</div>
        <div className="space-y-2">
          <div className="text-sm">
            <strong>Current Needs: Needed Skills & Attributes:</strong> This section provides the opportunity to provide a development plan
            for each Potential Successor based on needed skills & attributes. This could include development of problem-solving skills, decision-making, presenting to large or small groups,
            learning to play nice with superiors, peers or subordinates, expressing oneselfconvincingly, assertiveness, tact & diplomacy, etc. A timeline to accompany the plan is also recommended.
          </div>
          <div className="text-sm">
            <strong>Current Needs:Experience: </strong> A listing of needed seasoning, exposure and experience in critical areas of the
            Potential Successor’s framework. As an example, this could include more depth and understanding in Lending, Operations, IT, Supervision, HR, etc. A time-line puts teeth into the plan.
          </div>
          <div className="text-sm">
            <strong>Current Needs: Education:</strong> This can include any additional formal (college or university) education and also
            specific education by sponsoring entities like CUNA, SHRM or other training providers, including in-house course study. The same recommendation on time-lines.
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Advancement Readiness</div>
        <div className="space-y-2">
          <div className="text-sm">
            <strong>Readiness Timeline:</strong> The section provides the evaluator with timeline choices for candidate advancement, namely;
            “Immediate, 1-3 years, 4-5 years, more than 5, or never”. Even though SuccessionNow! uniquely provides for quantitative evaluation, the final determination can be both quantitative and
            qualitative. As an example, a high numeric score of 4.25 or higher may indicate a Candidate’s “immediate” readiness for advancement, however, there could still be a factor(s) that
            might suggest further seasoning, education or behavioral changes yet need to occur. This section, provides the evaluator the flexibility and latitude to make that final call,
            considering all aspects of advancement readiness, both objective and subjective.
          </div>
          <div className="text-sm">
            <strong>Competency Fit: </strong> As described earlier, this section provides the evaluator the opportunity to compare the
            Candidate’s overall qualifications and advancement readiness with the competencies required for the future position. When making a determination, consider the level of Competency Fit
            based on the Candidates Readiness TimeLine, i.e., does the Candidate meet the necessary competencies when ready for advancement. It matches the position competencies detailed in the
            section “Positions & Competencies” with the “Readiness Timeline”. The choices are: Excellent Fit, Good Fit, Marginal Fit, Unlikely Fit & Poor Fit. A selection of “Excellent Fit” or
            “Good Fit” would confirm the Candidate’s capability for advancement (at the assigned readiness date). A selection of anything less, might put in serious questionthe
            candidate’squalifications for advancement.
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Replacement Conclusion:</div>
        <div className="space-y-2">
          <div className="text-sm">
            This Section invites the evaluator to make a “First, Second, Third, and Fourth” choice for advancement. It asks the question, is there a Candidate ready for immediate advancement
            (defined as 0-1 year), or will it take longer (1-3 years or 4-5 years)? If no one is immediately ready, the choice would be an external hire.
          </div>
          <div className="text-sm">
            A company may need to also designate an employee for succession in times of “Emergency” or as an “Interim” while an executive search is being conducted. This “Emergency” or “Interim
            Replacement” may not be the first, second, or even third choice for ultimate succession (perhaps because the candidate has a short retirement window- 0-2 years),but fits the need on a
            short-term basis. The final column in this section provides for identification and selection of this individual.
          </div>
          <div className="text-sm">
            <strong>Approvals: </strong>
            Each Succession Plan needs to be approved by the appropriate Executive. Generally, this will fall to the President/CEO, but it may be the responsibility of another designated
            executive. The Approval section should be completed and dated, with the approvers name & title.
          </div>
        </div>
      </div>
      <div className="relative mb-8 space-y-2">
        <div className="text-lg font-bold underline">Annual Updates:</div>
        <div className="space-y-2">
          <div className="text-sm">
            SuccessionNow! is designed for annual rediv to accommodate the on-going changes in organizations, candidates, candidates’ qualifications and the needs of the organization. For
            effectiveness, compliance and peace of mind (Boards and Executives), this process should be updated on an annual basis.SuccessionNow! will automatically renew each year unless notified
            by the client to discontinue.
          </div>
        </div>
      </div>
    </>
  );
};

export default SecHelpHtml;