import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

const Testimonials = ({}) => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Testimonials"
  );
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [testimonialData, setTestimonialData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    // List Data
    const loadList = async () => {
      setLoading(true);
      try {
        const result = await postData("testimonial/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
        });
        if (result.data) {
          setTestimonialData(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                rowData: [
                  {
                    _id: 1,
                    width: 40,
                    type: "text",
                    data: value?.name?.[i18n?.language],
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: value?.designation?.[i18n?.language],
                  },
                  // { _id:3, width:15, type:'text', data:value?.qualification },
                  {
                    _id: 4,
                    width: 15,
                    type: "text",
                    data: value?.company?.[i18n?.language],
                  },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "Do MMM YYYY"
                          )
                        : "N/A",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("deactivated"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                  },
                  {
                    _id: 7,
                    width: 10,
                    type: "action",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("deactivated"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                  },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadList();
  }, [keyword, limit, offset, sortQuery, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount, isUpdate]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("testimonial/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setTestimonialData(
              testimonialData.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue?.row_id && actionValue?.button_type === "delete") {
      const deleteTestimonial = async () => {
        const statusData = await postData("testimonial/delete", {
          id: actionValue?.row_id,
        });

        if (statusData.status === 200) {
          setTestimonialData((testimonialData) =>
            testimonialData.filter((item) => item._id !== actionValue.row_id)
          );
          toastr.success(statusData.message);
        } else {
          toastr.error(statusData.message);
        }
      };
      deleteTestimonial();
    }
  }, [actionValue]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "user",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("title"),
      value: "designation",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    // {_id:3, width:15, name:'Qualification', value:'qualification', align:'left', isSort:true, isFilter:false},
    {
      _id: 4,
      width: 15,
      name: t("company"),
      value: "company",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 20,
      name: t("created_date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      testimonialData,
      result.source.index,
      result.destination.index
    );
    setTestimonialData(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{t("client_reviews")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={t("add")}
            buttonHasLink={true}
            buttonLink={"/add-testimonial"}
            buttonClasses={"text-sm"}
          />
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        {/* <div className="relative ml-auto flex justify-end items-center gap-3">
                <div className="relative">
                <Search 
                placeholder={'Search'} 
                setInput={ setKeyword } 
                />
                </div>
                <div className="relative">
                <Select 
                xPlacement={'right'} 
                dropdownButtonClass={'!bg-white'} 
                transitionClass={'!w-auto'}
                selectedValue={status} 
                dropdownData={[
                    { _id: '', label: 'Select' },
                    { _id: 1, label: 'Active', value: 1 },
                    { _id: 2, label: 'Deactivated', value: 2 },
                ]}
                getSelectedValue={(e) => setStatus(e.value)}
                />
                </div>
            </div> */}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={testimonialData}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={"/edit-testimonial"}
            isDraggable={false}
            isStatusUpdate={true}
            isDeleteEnabled={true}
            getActionValue={(obj) => setActionValue(obj)}
            tableImage={false}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
export default Testimonials;
