import React from "react";
import { setTitle } from "../../helpers/MetaTag";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import SecHelp from "../../components/section/Help/SecHelp";
import { API_URL } from "../../config/host";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SecHelpHtml from "../../components/section/Help/SecHelpHtml";

const Help = () => {
  setTitle("Succession Now | User Guide");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("user_guide") + " - " + user?.company_name}</div>
        <div className="ml-auto flex justify-end items-center space-x-2">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"div-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <SecHelp companyLogo={API_URL + user?.image} />
                  </Document>
                }
                fileName="help.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("export"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="py-4 sm:py-8 px-4 section-help">
          <SecHelpHtml />
        </div>
      </div>
    </>
  );
};

export default Help;
