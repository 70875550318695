import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

const Leads = ({}) => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Leads"
  );
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [leadData, setLeadData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    // List Data
    const loadList = async () => {
      setLoading(true);
      try {
        const result = await postData("lead-content/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
        });
        if (result.data) {
          setLeadData(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                rowData: [
                  { _id: 1, width: 40, type: "text", data: value?.name },
                  { _id: 2, width: 15, type: "text", data: value?.slug },
                  { _id: 3, width: 15, type: "text", data: value?.description },
                  //{ _id:4, width:15, type:'text', data:value?.company },
                  {
                    _id: 5,
                    width: 20,
                    type: "text",
                    data:
                      value && value?.created_at
                        ? moment(new Date(value?.created_at)).format(
                            "Do MMM YYYY"
                          )
                        : "N/A",
                  },
                  {
                    _id: 6,
                    width: 15,
                    type: "status",
                    statusLabel:
                      value?.status && value?.status === 1
                        ? t("active")
                        : t("deactivated"),
                    statusType:
                      value?.status && value?.status === 1
                        ? "success"
                        : "warning",
                  },
                  { _id: 7, width: 10, type: "action" },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadList();
  }, [keyword, limit, offset, sortQuery, status]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  useEffect(() => {
    if (actionValue?.row_id && actionValue?.button_type === "delete") {
      const deleteTestimonial = async () => {
        const statusData = await postData("testimonial/delete", {
          id: actionValue?.row_id,
        });

        if (statusData.status === 200) {
          setLeadData((leadData) =>
            leadData.filter((item) => item._id !== actionValue.row_id)
          );
          toastr.success(statusData.message);
        } else {
          toastr.error(statusData.message);
        }
      };
      deleteTestimonial();
    }
  }, [actionValue]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "user",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("slug"),
      value: "slug",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("description"),
      value: "description",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 20,
      name: t("created_date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      leadData,
      result.source.index,
      result.destination.index
    );
    setLeadData(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 ">
        <div className="text-2xl font-bold">{t("leads")}</div>
        {/* <div className="sm:ml-auto flex justify-end items-center">
          <Button buttonType={"button"} buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonLabel={t("add_lead")} buttonHasLink={true} buttonLink={"/add-lead"} buttonClasses={"text-sm"}/>
        </div> */}
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={leadData}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={"/edit-lead"}
            isDraggable={false}
            isDeleteEnabled={false}
            getActionValue={(obj) => setActionValue(obj)}
            tableImage={false}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
export default Leads;
