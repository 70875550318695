import React from "react";

const TableSkeleton = ({ tableImage = false }) => {
  return (
    <div className="divide-y divide-slate-200 p-1">
      {[...Array(10)].map((_, index) => (
        <div key={index} className="flex gap-4 w-full">
          {[...Array(10)].map((_, index) => (
            <div key={index} className="w-full py-4">
              <div className="skeleton-loader w-full h-3 bg-gray-200 relative overflow-hidden rounded-xl">
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-50 animate-shine"></div>
                <div className="flex items-center h-full px-6 space-x-4">{tableImage && <div className="w-8 h-8 bg-gray-300 rounded-full flex-shrink-0"></div>}</div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TableSkeleton;
