import React from "react";
import { Document, Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const ExecutiveStrengthReport = ({ executives, reatingOptions, totalEffectivenessAvg, user, companyLogo }) => {
  const { i18n, t } = useTranslation();

  const overallScoreDescription = (avgRating) => {
    if (avgRating && avgRating >= 9 && avgRating <= 10) {
      return "Highly Effective";
    } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
      return "Very Effective";
    } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
      return "Effective";
    } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
      return "Somewhat Effective";
    } else if (avgRating && avgRating < 5) {
      return "Effectiveness Improvement Needed";
    }
  };

  const uniqueArrayWithCount = (input) => {
    const flattened = input.flat();
    const idCounts = flattened.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const uniqueIds = [];
    for (const [id, count] of Object.entries(idCounts)) {
      uniqueIds.push({ id, count });
    }
    return uniqueIds;
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={{padding: 20,fontFamily: "RobotoRegular",lineHeight: 1.2,fontSize: 9,color: "#334155",backgroundColor: "#f1f5f9",}}>
        <View style={{position: "absolute",left: 0,top: 0,right: 0,bottom: 0,zIndex: 0,}} fixed >
          <View style={{position: "absolute",left: 0,top: 0,right: 0,width: "100%",height: 160,zIndex: -1,opacity: 0.1,}}>
            <Image style={{ objectFit: "cover" }} src={Shape} />
          </View>
          <View style={{position: "absolute",width: "100%",left: 0,bottom: -10,right: 0,height: 160,zIndex: -1,opacity: 0.075, }} >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
        </View>
        <View style={{ position: "absolute", right: 30, top: 30 }}>
          <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
        </View>
        <View style={{paddingBottom: 20,width: "100%",margin: "0 auto",display: "flex",flexDirection: "column",alignItems: "center", }}>
          <View style={{textAlign: "center",display: "flex",flexDirection: "column",justifyContent: "center",fontSize: 16,alignItems: "center",position: "relative",width: "100%",}}>
            <Image style={{width: 200,height: 40,objectFit: "contain",display: "flex",alignItems: "center",justifyContent: "center", }} src={companyLogo} />
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[ {display: "flex",flexDirection: "row",fontFamily: "RobotoBold",fontSize: 20,textTransform: "capitalize", }, ]}>
              Company Name: {user?.company_name}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[ {fontFamily: "RobotoBold",fontSize: 12,textTransform: "uppercase",color: "#14b8a6",letterSpacing: 3,textAlign: "center", }, ]} >
              {t("executive_strength_report")}
            </Text>
          </View>
        </View>

        <View style={{display: "flex",flexDirection: "row",justifyContent: "center",marginBottom: 20, }} wrap={false}>
          <View>
            <View style={{display: "flex",flexDirection: "row",backgroundColor: "#0f172a",color: "#ffffff",overflow: "hidden",borderTopLeftRadius: 16,borderTopRightRadius: 16,borderBottomRightRadius: 0,borderBottomLeftRadius: 0, }} >
              <View style={[{maxWidth: "100%",paddingHorizontal: 8,paddingVertical: 8,width: "25%",display: "flex",flexDirection: "row",flexWrap: "wrap",},]}>
                <Text style={{width: "100%",fontFamily: "RobotoBold",fontSize: 11,textAlign: "center",}}>
                  {t("overall_executive_audit_score")}
                </Text>
              </View>
            </View>
            <View style={{margin: "0 auto",width: "100%",border: "1px solid #e2e8f0",backgroundColor: "white",borderBottomRightRadius: 16,borderBottomLeftRadius: 16,overflow: "hidden",}}>
              <View style={[{fontSize: 10,maxWidth: "100%",paddingHorizontal: 8,paddingVertical: 8,width: "100%",display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center",},]}>
                <Text style={[ {textAlign: "center",fontFamily: "RobotoBold",fontSize: 10,lineHeight: 1,whiteSpace: "nowrap",},]}>
                  {totalEffectivenessAvg}
                </Text>
                <Text style={[{textAlign: "center",fontFamily: "RobotoRegular",fontSize: 10,lineHeight: 1,},]}>
                  {overallScoreDescription(totalEffectivenessAvg)}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{border: "1px solid #e2e8f0",borderRadius: 16,overflow: "hidden",marginBottom: 10, }}>
          <View style={{display: "flex",flexDirection: "row",backgroundColor: "#0f172a",color: "#ffffff",overflow: "hidden",borderTopLeftRadius: 16,borderTopRightRadius: 16,}} >
            <View style={[ {fontSize: 12,maxWidth: "100%",paddingHorizontal: 12,paddingVertical: 12,backgroundColor: "#0f172a",color: "#ffffff",width: "25%",display: "flex",flexDirection: "row",flexWrap: "wrap", },]}>
              <Text style={{width: "100%",fontFamily: "RobotoBold",fontSize: 12,color: "#ffffff", }}>
                {t("executive_audit_360")}
              </Text>
            </View>
          </View>
          <View style={{ width: "100%", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, paddingHorizontal: 12, paddingVertical: 12, backgroundColor: "white",}}>
            {reatingOptions &&
              reatingOptions.length > 0 &&
              reatingOptions.map((item, index) => {
                let ratingRowAnswers = item?.question?.answers;
                const ratedExecutive = executives.filter((it) => it?.executiveAudits).map((itm) => itm.executiveAudits.filter((row) => row.questionId === item?.question?._id));
                let totalSum = 0;
                let _comments = [];
                let _ans = [];
                if (ratedExecutive && ratedExecutive.length > 0) {
                  ratedExecutive.forEach((obj) => {
                    obj.forEach((ob) => {
                      totalSum += ob?.rating ? ob?.rating : 0;
                      if (ob?.comments) {
                        _comments.push(ob?.comments);
                      }
                      if (ob?.ids && ob?.ids?.length > 0) {
                        _ans.push(ob?.ids);
                      }
                    });
                  });
                }
                let totalExecutive = ratedExecutive?.length;
                let avgRating = Number(totalSum / totalExecutive) > 0 ? Number(totalSum / totalExecutive).toFixed(2) : 0;
                let _score = "";
                if (avgRating && avgRating >= 9 && avgRating <= 10) {
                  _score = "Highly Effective";
                } else if (avgRating && avgRating >= 8 && avgRating <= 8.9) {
                  _score = "Very Effective";
                } else if (avgRating && avgRating >= 6 && avgRating <= 7.9) {
                  _score = "Effective";
                } else if (avgRating && avgRating >= 5 && avgRating <= 5.9) {
                  _score = "Somewhat Effective";
                } else if (avgRating && avgRating < 5) {
                  _score = "Effectiveness Improvement Needed";
                }
                let _uniqueArrayWithCount = uniqueArrayWithCount(_ans);
                return (
                  <View wrap={false}>
                    <View style={{display: "flex",flexDirection: "row",columnGap: 16, }} >
                      <View key={index} style={[{fontSize: 10,maxWidth: "100%",paddingVertical: 12,width: "75%",columnGap: 4,flexDirection: "row",},]}>
                        <View style={{ width: 16, flexShrink: 0 }}>
                          <Text style={[{fontSize: 10,lineHeight: 1,whiteSpace: "nowrap",color: "#334155",textAlign: "right",fontFamily: "RobotoMedium",},]} >
                            {Number(index + 1) + "."}
                          </Text>
                        </View>
                        <View style={{ flexShrink: 1, flexGrow: 1, paddingRight: 16 }}>
                          <Text style={[{fontFamily: "RobotoRegular",fontSize: 10,lineHeight: 1,color: "#334155",whiteSpace: "normal",},]} >
                            {item?.question?.name}
                          </Text>
                        </View>
                      </View>
                      <View key={index} style={[{fontSize: 10,maxWidth: "100%",paddingVertical: 12,width: "8%",display: "flex",flexDirection: "row",flexWrap: "wrap",justifyContent: "center",}, ]}>
                        <Text style={[{fontSize: 10,lineHeight: 1,color: "#334155",textAlign: "center",fontFamily: "RobotoBold",}, ]}>
                          {avgRating}
                        </Text>
                      </View>
                      <View key={index} style={[{fontSize: 10,maxWidth: "100%",paddingVertical: 12,width: "17%",display: "flex",flexDirection: "row",flexWrap: "wrap",}, ]}>
                        <Text style={[{width: "100%",fontSize: 10,lineHeight: 1,color: "#334155",fontFamily: "RobotoRegular",}, ]}>
                          {_score}
                        </Text>
                      </View>
                    </View>
                    {_uniqueArrayWithCount && _uniqueArrayWithCount.length > 0 && (
                      <View className="relative">
                        {_uniqueArrayWithCount.map((an, i) => {
                          let rowData = ratingRowAnswers.filter((row) => row._id === an.id);
                          if (rowData) {
                            return (
                              <View style={{paddingRight: 16,paddingLeft: 20,display: "flex",flexDirection: "row",alignItems: "center",marginBottom: 4, }}>
                                <View style={{backgroundColor: "#000000",width: 4,height: 4,borderRadius: 9999,overflow: "hidden",flexShrink: 0, }}/>
                                <View style={{flexShrink: 1,flexGrow: 1,marginHorizontal: 8, }}>
                                  <Text style={{fontSize: 10,color: "#334155", }}>
                                    {rowData[0]?.description}
                                  </Text>
                                </View>
                                <View style={{ marginLeft: "auto", flexShrink: 0, }}>
                                  <Text style={{ fontSize: 10, color: "#334155", fontFamily: "RobotoMedium",}}>
                                    {an.count}
                                  </Text>
                                </View>
                              </View>
                            );
                          }
                        })}
                      </View>
                    )}
                    {_comments?.length > 0 && (
                      <View style={{ paddingHorizontal: 12, paddingVertical: 8, width: "100%", borderBottomWidth: 1, borderColor: "#ccc", }}>
                        <Text style={{ color: "#000", fontSize: 10, textTransform: "capitalize", fontFamily: "RobotoBold", marginBottom: 5, }} >
                          comments
                        </Text>
                        {_comments?.map((itm, idx) => (
                          <Text style={{ fontSize: 10, color: "#334155", paddingHorizontal: 8, textTransform: "capitalize", paddingVertical: 8, fontFamily: "RobotoBold", }} key={idx} >
                            {itm}
                          </Text>
                        ))}
                      </View>
                    )}
                  </View>
                );
              })}
          </View>
        </View>
        <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: 20, }} wrap={false}>
          <View>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color: "#ffffff", overflow: "hidden", borderTopLeftRadius: 16, borderTopRightRadius: 16, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, }} >
              <View style={[{maxWidth: "100%",paddingHorizontal: 8,paddingVertical: 8,width: "25%",display: "flex",flexDirection: "row",flexWrap: "wrap",}, ]} >
                <Text style={{width: "100%",fontFamily: "RobotoBold",fontSize: 11,textAlign: "center", }}>
                  {t("overall_executive_audit_score")}
                </Text>
              </View>
            </View>
            <View style={{ margin: "0 auto", width: "100%", border: "1px solid #e2e8f0", backgroundColor: "white", borderBottomRightRadius: 16, borderBottomLeftRadius: 16, overflow: "hidden", }}>
              <View style={[ {fontSize: 10,maxWidth: "100%",paddingHorizontal: 8,paddingVertical: 8,width: "100%",display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center", }, ]} >
                <Text style={[{ textAlign: "center", fontFamily: "RobotoBold", fontSize: 10, lineHeight: 1, whiteSpace: "nowrap",}, ]}>
                  {totalEffectivenessAvg}
                </Text>
                <Text style={[{ textAlign: "center", fontFamily: "RobotoRegular", fontSize: 10, lineHeight: 1,}, ]}>
                  {overallScoreDescription(totalEffectivenessAvg)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ExecutiveStrengthReport;
