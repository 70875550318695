import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Button from "../../components/form/Button";
import Table from "../../components/elements/table/Table";
import { postData } from "../../services/api";
import moment from "moment";
import ReactPaginate from "react-paginate";

import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";

const NewsLetters = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Newsletter"
  );
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [noOfPage, setNoOfPage] = useState(0);
  const [sortQuery, setSortQuery] = useState("");
  const [newsletter, setNewsletter] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [status, setStatus] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      name: t("first_name"),
      value: "lower_first_name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("last_name"),
      value: "lower_last_name",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      width: 30,
      name: t("email"),
      value: "lower_email",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 4,
      width: 10,
      name: t("date"),
      value: "created_at",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 5,
      width: 10,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      try {
        const result = await postData("newsletter/list", {
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
        });
        if (result.data) {
          setNewsletter(
            result.data.map((item, key) => {
              return {
                _id: item?._id,
                rowData: [
                  //{_id:1, width:15,type:'checkbox',value:item?.status, data:(item?.status && item?.status === 1)?true:false},
                  {
                    _id: 1,
                    width: 15,
                    type: "text",
                    value: "last_name",
                    data: item?.first_name,
                  },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    value: "last_name",
                    data: item?.last_name,
                  },
                  {
                    _id: 3,
                    width: 30,
                    type: "text",
                    value: "email",
                    data: item?.email,
                  },
                  {
                    _id: 4,
                    width: 10,
                    type: "text",
                    data:
                      item && item?.created_at
                        ? moment(new Date(item?.created_at)).format(
                            "Do MMM YYYY"
                          )
                        : "N/A",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel:
                      item?.status && item?.status === 1
                        ? t("active")
                        : t("deactivated"),
                    statusType:
                      item?.status && item?.status === 1
                        ? "success"
                        : "warning",
                  },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };
    loadList();
  }, [limit, sortQuery, offset, status, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{t("newsletter")}</div>
      </div>
      <div className="relative flex gap-3 items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative">
          <Select
            xPlacement={"right"}
            dropdownButtonClass={"!bg-white"}
            transitionClass={"!w-auto"}
            selectedValue={status}
            dropdownData={[
              { _id: "", label: t("select_all") },
              { _id: 1, label: t("active"), value: 1 },
              { _id: 2, label: t("deactivated"), value: 2 },
            ]}
            getSelectedValue={(e) => setStatus(e.value)}
          />
        </div>
      </div>
      <div className="w-full bg-white rounded-md shadow overflow-hidden">
        <Table
          tableData={newsletter}
          tableHeadData={tableHeadData}
          totalRecord={totalDataCount}
          loading={loading}
          getSortValue={setSortValue}
          getSortKey={setSortKey}
          editUrl={""}
          isDraggable={false}
          tableImage={false}
          //updateCheckboxHandler={(obj) => updateCheckboxHandler(obj?.id,obj?.isEnabled)}
        />
      </div>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default NewsLetters;
