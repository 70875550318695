import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./form/Button";
import { useTranslation } from "react-i18next";

const DeletePopup = ({
  isOpen,
  setIsOpen = () => {},
  currentId,
  getActionValue = () => {},
  message,
}) => {
  const { i18n, t } = useTranslation();
  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = () => {
    getActionValue({
      button_type: "delete",
      row_id: currentId,
      current_status: "",
    });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t("delete_record")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => closeModal()}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="text-center my-3 text-slate-500">
                    {message ? (
                      message
                    ) : (
                      <>
                        You are trying to delete the record,
                        <br /> click &quot;Confirm&quot; below.
                      </>
                    )}
                  </div>
                  <div className="flex gap-3 justify-center">
                    <Button
                      buttonIconPosition={"left"}
                      buttonIcon={"fa-regular fa-close"}
                      buttonLabel={"Cancel"}
                      functions={closeModal}
                      buttonClasses={
                        "!bg-dark-teal-500 !border-dark-teal-500 hover:!bg-dark-teal-500/80"
                      }
                    />
                    <Button
                      functions={onSubmit}
                      buttonIconPosition={"left"}
                      buttonIcon={"fa-regular fa-trash-can"}
                      buttonLabel={"Confirm"}
                      buttonClasses={
                        "!bg-rose-500 !border-rose-500 hover:!bg-rose-500/80"
                      }
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default DeletePopup;
