import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";
import Search from "./Search";
import { useTranslation } from "react-i18next";
import { classNames } from "../../helper/classNames";
import { Tooltip } from "react-tooltip";

const DropdownCheckbox = ({
  data,
  search = true,
  functions = () => {},
  isTooltip,
  ...props
}) => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [allData, setAllData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectCount, setSelectCount] = useState(0);

  useEffect(() => {
    if (keyword) {
      const filteredArray = data.filter((item) =>
        item?.label.toLowerCase().includes(keyword.toLowerCase())
      );
      setAllData(filteredArray.sort((a, b) => a.label.localeCompare(b.label)));
    } else {
      setAllData(data.sort((a, b) => a.label.localeCompare(b.label)));
    }

    let checkData =
      data.length > 0 && data?.filter((row) => row.isEnabled === true)?.length;
    if (checkData === data.length) {
      setIsAll(true);
    } else {
      setIsAll(false);
    }
    let count = data.filter((item) => item.isEnabled).length;
    setSelectCount(count);
  }, [data, keyword, props?.count]);

  return (
    <>
      <div className="relative">
        <button
          type="button"
          className="relative w-full h-10 focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-white rounded border border-slate-200 flex items-center cursor-pointer text-left "
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="text-[13px] text-slate-500 font-medium whitespace-nowrap truncate w-full">
            {selectCount > 0
              ? selectCount + " " + t("selected")
              : t("selected")}
          </div>
          <div className="text-xs text-slate-400">
            <i className={"fa-light fa-fw fa-chevron-down"}></i>
          </div>
        </button>
        <div
          className={
            "absolute top-full left-0 w-full min-w-[192px] bg-white rounded shadow mt-1 transition-all duration-200 z-10" +
            (isOpen ? "" : " opacity-0 invisible")
          }
        >
          {search && (
            <div className="px-2 py-1">
              <Search
                placeholder={"Search"}
                divClasses={"!h-8"}
                iconClasses={"!w-8 !h-8 !text-sm"}
                classes={"!text-xs"}
                setInput={setKeyword}
              />
            </div>
          )}
          <div className="overflow-auto max-h-[200px] smooth-scroll scrollbar py-2">
            <div className="px-3 py-1">
              <Checkbox
                checkboxName={t("select_all")}
                isChecked={isAll}
                checkboxLabel={t("select_all")}
                checkboxValue={0}
                getCheckboxValue={(id, event) => {
                  if (event.target.checked) {
                    setIsAll(true);
                    setAllData(
                      data
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((item) => {
                          if (
                            item?.label
                              .toLowerCase()
                              .includes(keyword.toLowerCase())
                          ) {
                            return {
                              ...item,
                              isEnabled: true,
                            };
                          } else {
                            return null;
                          }
                        })
                        .filter((item) => item !== null)
                    );
                  } else {
                    setIsAll(false);
                    setAllData(
                      data
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((item) => {
                          if (
                            item?.label
                              .toLowerCase()
                              .includes(keyword.toLowerCase())
                          ) {
                            return {
                              ...item,
                              isEnabled: false,
                            };
                          } else {
                            return null;
                          }
                        })
                        .filter((item) => item !== null)
                    );
                  }
                  functions("all", event.target.checked);
                  setIsOpen(!isOpen);
                }}
                checkboxLableClass={"!text-xs"}
              />
            </div>
            {allData.map((item, index) => (
              <div className="px-3 py-1" key={index}>
                <div
                  className="group relative"
                  data-tooltip-id={`userName${item?._id}`}
                  data-tooltip-place="top"
                >
                  <Checkbox
                    checkboxName={item?.label}
                    isChecked={item?.isEnabled}
                    checkboxLabel={item?.label}
                    checkboxValue={item?._id}
                    getCheckboxValue={(id, event) => {
                      functions(id, event.target.checked);
                      setIsOpen(!isOpen);
                    }}
                    checkboxLableClass={"!text-xs"}
                    isDisabled={item?.isDisabled}
                  />

                  {item?.isDisabled && (
                    <Tooltip
                      id={`userName${item?._id}`}
                      className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
                      render={() => <h1>Survey Completed</h1>}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropdownCheckbox;
