import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Image from "../../components/elements/Image";
import ButtonFile from "../../components/form/ButtonFile";
import Textarea from "../../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { allowedImageExtensions } from "../../helpers";
import { postData } from "../../services/api";

import { useTranslation } from "react-i18next";

const ManageBoardEffectiveness = ({type}) => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Board Effectiveness");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Board Effectiveness");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [fieldType,setFieldType] = useState('');

  useEffect(() => {
    // Banner details
    const loadDetails = async () => {
      try {
        const result = await postData("employee-rating-option/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
          setAnswers(
            result?.data?.answers
              ? result?.data?.answers
              : [
                  {
                    label: "",
                    value: "",
                    description: "",
                  },
                ]
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  useEffect(() => {
    setName(details?.question?.name);
    //setSlug(details?.question?.code);
    setDescription(details?.question?.description);
    setFieldType(details?.question?.fieldType);
    if (Object.keys(details).length > 0) {
      setAnswers(details?.question?.answers);
    } else {
      setAnswers([
        {
          label: "",
          value: "",
          description: "",
        },
      ]);
    }
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.question?.name);
      //setSlug(details?.question?.code);
      setDescription(details?.question?.description);
      setAnswers(details?.question?.answers);
    } else {
      setName("");
      setDescription("");
      setSlug("");
      setAnswers([]);
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "employee-rating-option/update";
        payload = {
          id: id,
          name: name,
          description: description,
          fieldType: fieldType,
          type: type,
          answers: answers,
        };
      } else {
        path = "employee-rating-option/create";
        payload = {
          name: name,
          description: description,
          fieldType: fieldType,
          type: type,
          answers: answers,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        if(type === 'boardmember'){
          return navigate("/board-effectiveness");
        }else if(type === 'executive'){
          return navigate("/executive-audit-360");
        }        
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const addRow = async () => {
    setAnswers((answers) => [
      ...answers,
      {
        label: "",
        value: "",
        description: "",
      },
    ]);
  };

  const removeRow = async (index) => {
    setAnswers((answers) => answers.filter((item, i) => i !== index));
  };

  const handelUpdate = async (field, val, index) => {
    if (field === "label") {
      setAnswers((answers) =>
        answers.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              label: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (field === "value") {
      setAnswers((answers) =>
        answers.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              value: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (field === "description") {
      setAnswers((answers) =>
        answers.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              description: val,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{id ? t("edit_board_effectiveness") : t("add_board_effectiveness")}</div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
              <div className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-12 gap-x-6 gap-y-3">
                    <div className="col-span-12">
                        <div className="relative">
                            <div className="text-lg font-bold">Question</div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <div className="relative w-full">
                        <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={name} setInput={setName} />
                      </div>
                      {/* <div className="relative w-full">
                              <Input
                                  label={t('slug')}
                                  labelClasses={'!text-xs'}
                                  inputType={'text'}
                                  inputPlaceholder={t('enter_slug')}
                                  inputValue={slug}
                                  setInput={setSlug}
                                  isDisabled={true}
                              />
                          </div> */}
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <Select 
                        label={t('type')}
                        labelClasses={'!text-xs !font-semibold'}
                        xPlacement={'left'} 
                        selectedValue={fieldType} 
                        dropdownClass={'!w-full'} 
                        dropdownData={
                            [
                              { _id: ' ', label: t('select_type'),value:''} ,
                              { _id: 'input', label: t('input'),value:'input'},                          
                              { _id: 'select', label: t('select'),value:'select'},
                              { _id: 'checkbox', label: t('checkbox'),value:'checkbox'},
                              { _id: 'radio', label: t('radio'),value:'radio'}, 
                            ]                          
                        }
                        getSelectedValue={(e) => {setFieldType(e._id)}}
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="relative w-full">
                        <Textarea label={t("description")} inputClasses={"!h-16"} labelClasses={"!text-xs"} inputValue={description} inputPlaceholder={t("enter_description")} setTextarea={setDescription} />
                      </div>
                    </div>
                   
                    <div className="col-span-12">
                        <div className="relative">
                            <div className="text-lg font-bold">Answers</div>
                        </div>
                    </div>              
                    <div className="col-span-12 grid grid-cols-1 sm:grid-cols-12 gap-x-6 gap-y-3">
                    {answers.map((item, key) => {
                      return (
                        <div className="pb-2 col-span-4" key={key}>
                          {
                            fieldType !== 'checkbox' && 
                              <div className="flex flex-col justify-end gap-2 relative mb-2">
                                <div className="relative w-full">
                                  <Input label={""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_label")} inputValue={item?.label} setInput={(val) => handelUpdate("label", val, key)} />
                                </div>
                                <div className="relative w-full">
                                  <Input label={""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_value")} inputValue={item?.value} setInput={(val) => handelUpdate("value", val, key)} />
                                </div>                          
                              </div>
                          }                      
                          <div className="flex items-end gap-2 relative">
                            <div className="relative w-full">
                              <Textarea inputClasses={"!h-16"} labelClasses={"!text-xs"} inputValue={item?.description} inputPlaceholder={t("enter_description")} setTextarea={(val) => handelUpdate("description", val, key)} />                          
                            </div>
                            { key === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0"} buttonType={"button"} functions={(e) => addRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500"} buttonType={"button"} functions={(e) => removeRow(key)} buttonHasLink={false} />}
                          </div>
                        </div>
                      );
                    })}
                    </div>
                </div>  
              </div>

              <div className="flex items-center justify-end gap-2  mt-5">
                  <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-floppy-disk"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
              </div>
      </div>
    </div>
  );
};

export default ManageBoardEffectiveness;
