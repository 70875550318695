import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const PolicyReport = ({ data, user, companyLogo }) => {
  return (
    <Page
      size="A4"
      orientation="landscape"
      style={{
        padding: 20,
        fontFamily: "RobotoRegular",
        lineHeight: 1.2,
        fontSize: 9,
        color: "#334155",
        backgroundColor: "#f1f5f9",
      }}
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        fixed
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: 16,
          alignItems: "center",
          position: "relative",
          width: "100%",
          marginBottom: 20,
        }}
      >
        <Image
          style={{
            width: 200,
            height: 80,
            objectFit: "contain",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0  auto",
          }}
          src={companyLogo}
        />
        <View style={{ position: "absolute", right: 0, top: 0 }}>
          <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Text style={{ fontFamily: "RobotoBold", fontSize: 20, textTransform: "capitalize" }}>Policy Statement</Text>
        <Text
          style={{
            fontSize: 11,
            fontFamily: "RobotoRegular",
            lineHeight: 1.4,
            marginBottom: 10,
          }}
        >
          {data}
        </Text>
      </View>
    </Page>
  );
};

export default PolicyReport;
