import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import DropdownCheckbox from "../form/DropdownCheckbox";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import toastr from "toastr";

import { useTranslation } from "react-i18next";
import Checkbox from "../form/Checkbox";
import { Tooltip } from "react-tooltip";
import { useDebounce } from "../../helpers/useDebounce";
import Search from "../form/Search";
import NoDataFound from "../NoDataFound";
import { ManageDefaultConcernAndPredictorsSkleton } from "../loader/ManageDefaultConcernAndPredictorsSkleton";

const InvitationPopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {}, type, completedUsers }) => {
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [completedEmployee, setCompletedEmployee] = useState([]);
  const [tabType, setTabType] = useState("invited");
  const [selectedMember, setSelectedMember] = useState([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    async function employeeData() {
      setProcessing(true);
      try {
        const result = await postData("assign/list", {
          sortQuery: { created_at: -1 },
          status: 1,
          is_invited: 1,
          keyword: search,
        });
        if (result.data) {
          const updatedResult =
            result?.data?.length > 0 &&
            result?.data?.map((user) => {
              const isCompleted = completedUsers.some((completed) => completed?.parent_id === user._id);
              return {
                ...user,
                isCompleted: isCompleted ? true : false,
              };
            });

          setEmployees(
            updatedResult?.length > 0 &&
              updatedResult
                ?.filter((employee) => !employee?.isCompleted)
                .map((items) => {
                  let firstName = "";
                  let lastName = "";
                  if (!items?.first_name) {
                    let nameParts = items?.name?.split(/\s+/);
                    firstName = nameParts?.[0] || "";
                    lastName = nameParts?.slice(1).join(" ") || "";
                  } else {
                    firstName = items?.first_name;
                    lastName = items?.last_name;
                  }
                  return {
                    _id: items?._id,
                    label: firstName + " " + lastName,
                    value: items?.email,
                    email: items?.email,
                    position: items?.designation,
                    first_name: firstName,
                    last_name: lastName,
                    isEnabled: items?.isCompleted,
                    isDisabled: items?.isCompleted,
                  };
                })
          );
          setCompletedEmployee(
            updatedResult?.length > 0 &&
              updatedResult
                ?.filter((employee) => employee?.isCompleted)
                .map((items) => {
                  let firstName = "";
                  let lastName = "";
                  if (!items?.first_name) {
                    let nameParts = items?.name?.split(/\s+/);
                    firstName = nameParts?.[0] || "";
                    lastName = nameParts?.slice(1).join(" ") || "";
                  } else {
                    firstName = items?.first_name;
                    lastName = items?.last_name;
                  }
                  return {
                    _id: items?._id,
                    label: firstName + " " + lastName,
                    value: items?.email,
                    email: items?.email,
                    position: items?.designation,
                    first_name: firstName,
                    last_name: lastName,
                    isEnabled: items?.isCompleted,
                    isDisabled: items?.isCompleted,
                  };
                })
          );
        } else {
          console.log("Employee list message", result.message);
        }
        setProcessing(false);
      } catch (error) {
        console.log("Error employee list catch", error.message);
        setProcessing(false);
      }
    }
    employeeData();
  }, [search]);

  const onSubmit = async () => {
    const payloadData = selectedMember
      ?.filter((item) => !item?.isDisabled)
      ?.map((item) => ({
        ...item,
        isEnabled: true,
      }));
    try {
      setLoading(true);
      const result = await postData("executive/create", {
        employees: payloadData,
        sendFor: "new",
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  const handleSelectedMember = (item) => {
    setSelectedMember((prevSelected) => {
      const isAlreadySelected = prevSelected.some((member) => member?._id === item?._id);

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers = employees?.filter((member) => member._id);

    if (selectedMember.length === filteredMembers.length) {
      setSelectedMember([]);
    } else {
      setSelectedMember(filteredMembers);
    }
  };

  useEffect(() => {
    const filteredMembers = employees?.filter((member) => member?._id);
    const isAllSelected = selectedMember.length === filteredMembers.length && filteredMembers.length !== 0;

    if (isAllSelected) {
      setAreAllSelected(true);
    } else {
      setAreAllSelected(false);
    }
  }, [selectedMember, employees, areAllSelected]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("add_executive")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={"!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"}
                      functions={() => {
                        setIsOpen(false);
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4">
                    <div className="relative flex justify-between items-center mb-3 gap-4">
                      <div className="text-xl font-semibold">{"Invite New"}</div>
                    </div>
                    <div className="flex justify-start items-start space-x-5">
                      {employees?.filter((member) => member?._id).length > 0 && (
                        <div className="" data-tooltip-id={`selectAllButton${14434}`} data-tooltip-place="top">
                          <Button
                            buttonType={"button"}
                            buttonClasses={"bg-transparent border border-transparent !text-dark-teal-500 !px-0"}
                            buttonLabelClasses="text-dark-teal-500"
                            buttonIcon={areAllSelected ? "fa-solid fa-square-check" : "fa-light fa-square"}
                            buttonIconPosition={"left"}
                            buttonLabel={areAllSelected ? "Unselect All Employee to Invite" : "Select All Employee to Invite"}
                            buttonHasLink={false}
                            functions={() => handleSelectAll()}
                          />
                          <Tooltip
                            id={`selectAllButton${14434}`}
                            className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                            render={() => (
                              <div className="gap-x-1">
                                <div className="text-[13px] font-normal leading-none flex items-center">
                                  <div className="font-semibold">{areAllSelected ? "Unselect All Employee to Invite" : "Select All Employee to Invite"}</div>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                      <div className="relative w-52">
                        <Search
                          placeholder={t("search")}
                          setInput={setKeyword}
                          valueReset={() => {
                            setSelectedMember([]);
                          }}
                        />
                      </div>
                      {selectedMember?.length > 0 && (
                        <div className="relative flex justify-center gap-4">
                          <Button
                            buttonClasses="!bg-teal-500"
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("send_invitation")}
                            functions={onSubmit}
                            buttonHasLink={false}
                          />
                        </div>
                      )}
                    </div>
                    <div className="relative mt-1">
                      {!processing && (
                        <div className="grid grid-cols-3 gap-2 py-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-280px)]">
                          {Array.isArray(employees) && employees.length > 0 ? (
                            employees?.map((employee) => (
                              <div
                                className={`flex items-start justify-between gap-2 border border-teal-500 rounded-md py-3 px-3 relative overflow-hidden ${
                                  !employee?.disabled && employee?.isDefault ? "bg-blue-50" : "bg-transparent"
                                }`}
                                key={employee?._id}
                              >
                                <div className="flex items-start justify-start flex-shrink-0 mt-1">
                                  <input
                                    type="checkbox"
                                    id={`${employee?._id}`}
                                    checked={selectedMember.some((member) => member?._id === employee?._id)}
                                    onChange={() => handleSelectedMember(employee)}
                                    className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-teal-500  checked:bg-teal-500"
                                  />
                                </div>
                                <div className="flex flex-col flex-shrink flex-grow">
                                  <h3 className="text-sm font-semibold text-teal-600 capitalize">{employee?.label || "N/A"}</h3>
                                  <div className="text-xs text-gray-400 whitespace-pre-wrap break-all">{employee?.email || "N/A"}</div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="col-span-2">
                              <NoDataFound />
                            </div>
                          )}
                        </div>
                      )}
                      {processing && <ManageDefaultConcernAndPredictorsSkleton rowValue={"!grid-cols-3 !max-h-[calc(100vh-280px)]"} count={8} />}
                    </div>

                    <div className="relative py-2 mt-4">
                      <div className="relative flex justify-between items-center mb-3 gap-2">
                        <div className="text-xl font-semibold">{"Invite Completed"}</div>
                      </div>
                      <div className="relative mt-1">
                        {!processing && (
                          <div className="grid grid-cols-3 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-280px)]">
                            {Array.isArray(completedEmployee) && completedEmployee.length > 0 ? (
                              completedEmployee?.map((employee) => (
                                <div className={`flex items-start justify-between gap-2 border border-teal-500 rounded-md py-3 px-3`} key={employee?._id}>
                                  <div className="flex flex-col flex-shrink flex-grow">
                                    <h3 className="text-sm font-semibold text-teal-600 capitalize">{employee?.label || "N/A"}</h3>
                                    <div className="text-xs text-gray-400 whitespace-pre-wrap break-all">{employee?.email || "N/A"}</div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <NoDataFound />
                            )}
                          </div>
                        )}
                        {processing && <ManageDefaultConcernAndPredictorsSkleton rowValue={"!grid-cols-3 !max-h-[calc(100vh-280px)]"} count={8} />}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default InvitationPopup;
