import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import { allowedImageExtensions } from "../../helpers";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import NoDataFound from "../NoDataFound";
import Searching from "../Searching";

const AdminAddEditPopup = ({ isOpen, setIsOpen = () => {}, data, loadList = () => {}, id }) => {
  //   const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Admin");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Admin");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const [details, setDetails] = useState({});
  const [image, setImage] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [showLogo, setShowLogo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [commission, setCommission] = useState("");
  const [salesCommission, setSalesCommission] = useState();
  const [consultantCommission, setConsultantCommission] = useState();
  const [appointmentSetterCommission, setAppointmentSetterCommission] = useState();
  const [salesAdminCommission, setSalesAdminCommission] = useState();
  const [role, setRole] = useState("");
  const [userRole, setUserRole] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const [processing, setProcessing] = useState(false);

  const staffCommissionArray = [
    {
      code: "sales-staff",
      commission: salesCommission,
    },
    {
      code: "consultant",
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      commission: salesAdminCommission,
    },
  ];

  useEffect(() => {
    setCommission(staffCommissionArray);
  }, [salesCommission, consultantCommission, appointmentSetterCommission, salesAdminCommission]);

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    async function RolePermissionData() {
      setProcessing(true);
      try {
        const results = await postData("role/list", {
          sortQuery: { ordering: 1 },
          status: 1,
          codes: ["master-admin", "basic-administrator"],
        });
        if (results.data) {
          setRolePermission(
            results.data.map((item, key) => {
              let matchCode = Object.keys(details).length > 0 && details?.role?.filter((itm) => itm === item?._id);
              if (matchCode && matchCode.length > 0) {
                return {
                  _id: item?._id,
                  title: item?.name,
                  code: item?.code,
                  isEnabled: true,
                };
              } else {
                return {
                  _id: item?._id,
                  title: item?.name,
                  code: item?.code,
                  isEnabled: false,
                };
              }
            })
          );
          setProcessing(false);
        } else {
          setProcessing(false);
          console.log("role list message", results?.message);
        }
      } catch (error) {
        console.log("Error role list catch", error.message);
        setProcessing(false);
      }
    }
    RolePermissionData();
  }, [details]);

  const updateHandler = (_id, event) => {
    if (event) {
      setUserRole((userRole) => [...userRole, _id]);
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item?._id === _id) {
            return {
              ...item,
              isEnabled: true,
            };
          } else {
            return item;
          }
        })
      );
    } else {
      setUserRole((userPermission) => userPermission.filter((item) => item !== _id));
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item?._id === _id) {
            return {
              ...item,
              isEnabled: false,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(details).length > 0) {
      setFirstName(details?.first_name);
      setLastName(details?.last_name);
      setEmail(details?.email);
      setCommission(details?.commission);
      setRole(details?.role);
      if (Array.isArray(details?.role)) {
        setUserRole([...details?.role]);
      } else {
        setUserRole([details?.role]);
      }
      setPhone(details?.phone);
      setShowLogo(details?.image);
    }
  }, [details]);

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    if (userRole?.length <= 0) {
      toastr.error("Please Select Role !!");
      setIsManage(false);
      return;
    }

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("commission", JSON.stringify(commission));
    formData.append("phone", phone);
    formData.append("image", image);
    try {
      let payload;
      let path;
      if (id) {
        path = "usertype/adminintrator-update";
        formData.append("id", id);
        for (const key_3 in userRole) {
          formData.append("roles[]", userRole[key_3]);
        }
        //formData.append("code", userRole);
        payload = formData;
      } else {
        path = "usertype/adminintrator-create";
        formData.append("email", email);
        formData.append("password", password);
        formData.append("confirm_password", confirmPassword);
        for (const key in userRole) {
          formData.append("roles[]", userRole[key]);
        }
        //formData.append("code", userRole);
        payload = formData;
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        // navigate("/admins");
        loadList();
        setIsOpen({ open: false, _id: null });
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setIsManage(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                  {id ? t("edit_admin") : t("Add New Admin")}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={"!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"}
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                      setDetails({});
                      setShowLogo("");
                      setFirstName("");
                      setLastName("");
                      setEmail("");
                      setPhone("");
                      setPassword("");
                      setConfirmPassword("");
                      setCommission("");
                      setSalesCommission("");
                      setConsultantCommission("");
                      setAppointmentSetterCommission("");
                      setSalesAdminCommission("");
                      setUserRole([]);
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative mt-4 bg-white">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 lg:col-span-3 border-r border-slate-200">
                      <div className="p-2 2xl:p-4">
                        <div className="py-6 px-6 flex flex-col space-y-4 relative">
                          <div className="relative group w-full aspect-1 border-2  mx-auto overflow-hidden rounded-full border-dark-teal-500">
                            <ButtonFile
                              buttonType={"button"}
                              buttonIcon={"fa-light fa-image"}
                              buttonIconPosition={"left"}
                              buttonLabel={t("upload_image")}
                              buttonClasses={"!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"}
                              functions={onImgChange}
                              accepts={"image/*"}
                            />
                            <Image src={showLogo} alt={"Admin"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-fit"} id={"image1"} />
                          </div>
                        </div>
                        <div className="divide-x divide-slate-200 flex items-center justify-center rounded-b border-t border-slate-200 pt-5">
                          <ButtonFile
                            buttonType={"button"}
                            buttonIcon={"fa-regular fa-upload"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("upload_image")}
                            functions={onImgChange}
                            accepts={"image/*"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12 lg:col-span-9 flex flex-col">
                      <div className="space-y-4">
                        <div className="grid sm:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("first_name")}
                              labelClasses={"!text-xs"}
                              inputType={"input"}
                              inputPlaceholder={t("enter_first_name")}
                              inputValue={firstName}
                              setInput={setFirstName}
                              requiredDisplay={true}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("last_name")}
                              labelClasses={"!text-xs"}
                              inputType={"input"}
                              inputPlaceholder={t("enter_last_name")}
                              inputValue={lastName}
                              setInput={setLastName}
                              requiredDisplay={true}
                            />
                          </div>
                        </div>
                        <div className="grid sm:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("work_email")}
                              labelClasses={"!text-xs"}
                              inputType={"email"}
                              inputPlaceholder={t("work_email_address")}
                              inputValue={email}
                              setInput={setEmail}
                              isDisabled={id ? true : false}
                              requiredDisplay={true}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("phone")}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_phone")}
                              inputValue={phone}
                              setInput={setPhone}
                              requiredDisplay={true}
                            />
                          </div>
                        </div>
                        <div className="grid sm:grid-cols-2 2xl:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("sales_commission")}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_sales_commission")}
                              inputValue={salesCommission}
                              setInput={setSalesCommission}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("consultant_commission")}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_consultant_commission")}
                              inputValue={consultantCommission}
                              setInput={setConsultantCommission}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("appointment_setter_commission")}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_appointment_setter_commission")}
                              inputValue={appointmentSetterCommission}
                              setInput={setAppointmentSetterCommission}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("sales_admin_commission")}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_sales_admin_commission")}
                              inputValue={salesAdminCommission}
                              setInput={setSalesAdminCommission}
                            />
                          </div>
                          {/* <div className="relative w-full">
                        <Input
                          label={t('commission')}
                          labelClasses={"!text-xs"}
                          inputType={"number"}
                          inputPlaceholder={t('enter_commission')}
                          inputValue={commission}
                          setInput={setCommission}
                        />
                      </div> */}
                        </div>
                        <div className="grid grid-cols-12 gap-2">
                          {rolePermission.length > 0 ? (
                            rolePermission.map((item, index) => (
                              <div className="relative col-span-4" key={index}>
                                <Checkbox
                                  checkboxLableClass={""}
                                  checkboxInputClass={""}
                                  checkboxName={item?.code}
                                  isChecked={item?.isEnabled}
                                  checkboxLabel={item?.title}
                                  checkboxValue={item?._id}
                                  getCheckboxValue={(_id, event) => updateHandler(_id, event.target.checked)}
                                />
                              </div>
                            ))
                          ) : (
                            <div className="relative col-span-12">
                              <div className="w-full flex items-center justify-center text-slate-600 text-base">No Data Found</div>
                            </div>
                          )}
                        </div>

                        {!id && (
                          <div className="grid sm:grid-cols-2 gap-4">
                            <div className="relative w-full">
                              <Input label={"Password"} labelClasses={"!text-xs"} inputType={"password"} inputPlaceholder={"Your Password"} inputValue={password} setInput={setPassword} />
                            </div>
                            <div className="relative w-full">
                              <Input
                                label={"Confirm Password"}
                                labelClasses={"!text-xs"}
                                inputType={"password"}
                                inputPlaceholder={"Confirm your password"}
                                inputValue={confirmPassword}
                                setInput={setConfirmPassword}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="flex justify-end gap-4 mt-3">
                        <Button
                          buttonClasses={"bg-white border border-slate-300 !text-slate-400 ml-2"}
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          functions={() => {
                            setIsOpen({ _id: null, open: false });
                            setDetails({});
                            setShowLogo("");
                            setFirstName("");
                            setLastName("");
                            setEmail("");
                            setPhone("");
                            setPassword("");
                            setConfirmPassword("");
                            setCommission("");
                            setSalesCommission("");
                            setConsultantCommission("");
                            setAppointmentSetterCommission("");
                            setSalesAdminCommission("");
                            setUserRole([]);
                          }}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses=""
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-regular fa-floppy-disk"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("save")}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={isManage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdminAddEditPopup;
