import React,{useEffect,useState,useRef} from 'react';
import {setTitle} from '../helpers/MetaTag';
import moment from 'moment';
import { postData } from "../services/api";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { PDFDownloadLink } from '@react-pdf/renderer';
import BoardMemberDetailsReport from './pdf/BoardMemberDetailsReport';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API_URL } from '../config/host';

const BoardMamberDetails = () => {
  setTitle('Succession Now | Board Member Details');
  const { i18n,t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth?.user);
  const { id } = useParams();
  const [boardMemberDetails,setBoardMemberDetails] = useState({});
  const [reatingOptions,setReatingOptions] = useState([]);

  useEffect(() => {
    async function boardMemberDetails() {
      try {
        const result = await postData("board-member/details", {
          _id:id
        });
        if(result.status === 403){
          return navigate("/board-members");
        }else{
          if (result.data) {
            setBoardMemberDetails(result.data);
          } else {
            console.log('Board Member Details message',result.message)
          }
        }        
      } catch (error) {
        console.log('Error board member details catch',error.message)
      }
    }
    boardMemberDetails();

    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
            sortQuery:{'created_at':1},
            status:1,
            limit :50,
            type: "boardmember"
        });
        if (result.data) {
          setReatingOptions(result.data);
        } else {
          console.log('Employee rating message',result.message)
        }
      } catch (error) {
        console.log('Error employee rating catch',error.message)
      }
    }
    employeeRatingOptionList();
  },[])

  const getInitials = function (string) {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, '_blank');
    }
  }

  const uniqueArrayWithCount = (input) => {
    const flattened = input.flat();
    const idCounts = flattened.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    const uniqueIds = [];
    for (const [id, count] of Object.entries(idCounts)) {
      uniqueIds.push({ id, count });
    }
    return uniqueIds;
  };

  return (
    <>
    <div className="relative flex justify-between items-center mb-4">
      <div className="text-3xl text-slate-800 font-bold">{t('board_member_details')}</div>
      <div className="ml-auto flex justify-end items-center space-x-2">
        <button
          type={'button'}
          className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "}
          onClick={(e) => getDownload(e)}
        >
          <i className={"fa-fw fa-light fa-download"}></i>
          <div className={"text-sm font-medium whitespace-nowrap"}>
            <PDFDownloadLink document={
            <BoardMemberDetailsReport 
            details={boardMemberDetails} 
            reatingOptions={reatingOptions}
            user={user} 
            companyLogo={API_URL+user?.image}
            />} fileName="profile_report.pdf">
              {({ blob, url, loading, error }) => (
                loading ? t('loading_document') : t('report'))}
            </PDFDownloadLink>
          </div>
        </button>
      </div>
    </div>
    <div className="relative grid grid-cols-4 gap-4">
        <div className="col-span-1">
        <form>
          <div className="sticky top-12 bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className='w-14 h-14 overflow-hidden bg-slate-100 rounded-full relative flex items-center justify-center border border-white font-bold'>
                { getInitials(boardMemberDetails.first_name+' '+boardMemberDetails.last_name)}
              </div>
              <div className="w-full space-y-1">
                <div className="text-xl font-bold text-slate-900">{boardMemberDetails.first_name+' '+boardMemberDetails.last_name}</div>
                {
                  (boardMemberDetails.position_details) && 
                  <div className="flex items-center space-x-2 text-slate-500 text-sm">
                    <i className="fa-solid fa-fw fa-briefcase"></i>
                    <div className="text-[13px] font-light"><span className="font-semibold">{boardMemberDetails.position_details?.name}</span>  {(boardMemberDetails?.company_name)?t('of')+' '+boardMemberDetails?.company_name:''}</div>
                  </div>
                }
                <div className="flex items-center space-x-2 text-slate-500 text-sm">
                  <i className="fa-solid fa-fw fa-clock"></i>
                  <div className="text-[13px] font-light">{t('since')} <span className="font-semibold">{(boardMemberDetails)?moment(new Date(boardMemberDetails?.created_at)).format('MMM YYYY'):'N/A'}
                  </span></div>
                </div>
              </div>
              {/* {
                (profileData?.role_details && profileData?.role_details.length>0) && 
                <div className="w-full">
                  <div className="text-xs text-slate-400">Role</div>
                  <div className="text-sm text-slate-500">{profileData?.role_details[0]?.name}</div>
                </div>
              } */}
              <div className="w-full">
                <div className="text-xs text-slate-400">{t('email_address')}</div>
                <div className="text-sm text-slate-500">{(boardMemberDetails)?boardMemberDetails?.email:'N/A'}</div>
              </div>
              {
                boardMemberDetails?.phone && 
                <div className="w-full">
                  <div className="text-xs text-slate-400">{t('phone')}</div>
                  <div className="text-sm text-slate-500">{boardMemberDetails?.phone}</div>
                </div>
              }
              <div className="w-full">
                <div className="text-xs text-slate-400">{t('last_login')}</div>
                <div className="text-sm text-slate-500">
                  {(boardMemberDetails && boardMemberDetails?.last_login)?moment(new Date(boardMemberDetails?.last_login)).format('Do MMM YYYY'):'N/A'}
                </div>
              </div>
            </div>
          </div>
        </form>
        </div>
        <div className="col-span-3 space-y-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">{t('welcome_to_successionNow')}!</div>
              <div className="space-y-4 mt-4">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('years_on_the_board')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.board_year_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('board_retirement_window')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.retirement_window_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('current_board_position_held')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.position_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('previous_board_position_held')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.previous_position_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('previous_board_position_held')+' - 2'}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.previous_position_two_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('previous_board_position_held')+' - 3'}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.previous_position_three_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('education')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.education_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('field_of_study')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.study_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('industry_experience')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.industry_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('primary_area_of_expertise')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.primary_expertise_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('years_of_primary_experience')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(Number(boardMemberDetails?.primary_experience)>1)?Number(boardMemberDetails?.primary_experience) +' Years':Number(boardMemberDetails?.primary_experience) +' Year'}</dd>
                </div>
                {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('secondary_area_of_expertise')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.secondary_expertise_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('years_of_secondary_experience')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{(Number(boardMemberDetails?.secondary_experience)>1)?Number(boardMemberDetails?.secondary_experience) +' Years':Number(boardMemberDetails?.secondary_experience) +' Year'}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('management_level')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.management_level_details?.name}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('years_of_management_experience')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.management_experience_details?.name}</dd>
                </div> */}

                
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">{t('desire_for_additional_responsibilities')}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{boardMemberDetails.desire_details?.name}</dd>
                </div>
                <div className="px-4 py-6  sm:px-0 flex items-center gap-16">
                  <div className="text-sm font-medium leading-6 text-gray-900">{t('additional_certifications_or_licenses')}</div>
                  <div className=''>
                  {
                    (boardMemberDetails.additional_certifications && boardMemberDetails.additional_certifications.length>0) && 
                    boardMemberDetails.additional_certifications.map((itm) => (
                      <div className="mt-1 flex items-center space-x-2">
                      <div className='w-1 h-1 rounded-full overflow-hidden bg-slate-400'></div> 
                      <div className='text-sm !leading-6 text-gray-700'>{itm}</div>
                      </div>
                    ))                    
                  }
                  </div>
                  
                </div>
                
              </dl>
              </div>
            </div> 
          </div> 
        </div>
        {/* <div className="col-span-4 space-y-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">{t('board_effectiveness')}</div>            
                <dl className="space-y-5 mt-5">
                  {
                    (reatingOptions && reatingOptions.length>0) && 
                    reatingOptions.map((item,index) => {
                      let ratingRowAnswers = item?.question?.answers;
                      const ratedBoardMember = boardMemberDetails?.ratingOptions && boardMemberDetails?.ratingOptions.filter((row) => row.questionId === item?.question?._id);
                      let totalRating = 0;
                      let ansLabel = ''; 
                      let comment = '';
                      let _ans = [];
                      if(ratedBoardMember && ratedBoardMember.length>0){
                        totalRating =  ratedBoardMember[0]?.rating;
                        ansLabel =  ratedBoardMember[0]?.answerLabel;
                        comment = ratedBoardMember[0]?.comments;
                        _ans = ratedBoardMember[0]?.ids;
                      } 
                      if(ratingRowAnswers && ratingRowAnswers.length>0){
                        ratingRowAnswers.forEach((obj) => {
                          if (obj?.company_id === user?._id) {
                              _ans.push(obj?._id);
                          }
                        });
                      }                      
                      return (
                        <div className=' rounded-md shadow-md px-3 py-3 space-y-5'>
                          <div className="px-4  flex items-start">
                            <dt className="w-4/5 text-sm font-medium leading-6 text-gray-900 flex-shrink">{Number(index+1)}.{' '+item?.question?.name}</dt>
                            <dd className="ml-auto text-sm font-semibold leading-6 text-teal-600 sm:col-span-2 sm:mt-0 text-right ">{((totalRating>0)?totalRating:'')+((ansLabel)?' ('+ansLabel+')':'')}</dd>
                          </div> 
                          {
                            (_ans && _ans.length>0) && 
                            <div className='relative'>
                              {
                                [...new Set(_ans)].map((an,i) =>{
                                  let rowData = ratingRowAnswers.filter((row) => row._id === an);
                                  if(rowData && rowData.length>0){
                                    return (
                                      <div className="px-4 flex items-center gap-2">
                                        <div className='w-2 h-2 rounded-full overflow-hidden bg-slate-600'></div>
                                        <div className='text-slate-600 text-sm'>{rowData[0]?.description}</div>
                                      </div>
                                    )
                                  }
                                })
                              }
                            </div>
                          }
                          {
                            comment && 
                            <div className="px-4  flex items-start whitespace-pre-wrap">
                              <i className="fa-solid fa-comment mr-1"></i>
                              <dt className="w-full text-sm font-medium leading-6 text-gray-900 flex-shrink-0 ">{' '+comment}</dt>
                            </div> 
                          }                        
                        </div> 
                      )                    
                    })
                  }                                
                </dl>
            </div> 
          </div> 
        </div> */}
      </div>
    </>
  );
};

export default BoardMamberDetails;