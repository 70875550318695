import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { useSelector } from "react-redux";
import { postData } from "../services/api";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import Select from "../components/form/Select";
import ConclusionIndividualReport from "./pdf/ConclusionIndividualReport";
import DropdownCheckbox from "../components/form/DropdownCheckbox";
import NoDataFound from "../components/NoDataFound";
import Searching from "../components/Searching";
import { API_URL } from "../config/host";

import { useTranslation } from "react-i18next";
import ReportTableSkeleton from "../components/loader/ReportTableSkeleton";

const IndividualConclusion = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Succession Plan Scorecard");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [successors, setSuccessors] = useState([]);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [creator, setCreator] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [yearWithCompanyWeight, setYearWithCompany] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const heading = [
    {
      name: t("time_horizon"),
      option: "(" + t("if_0_1_year") + ")",
      isColor: "",
    },
    {
      name: t("time_horizon"),
      option: "(" + t("if_1_3_years") + ")",
      isColor: "",
    },
    {
      name: t("time_horizon"),
      option: "(" + t("if_3_5_years") + ")",
      isColor: "",
    },
    {
      name: t("emergency_or_interim_replacement"),
      option: "",
      isColor: "bg-red-500",
    },
  ];

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          type: 1,
          status: 1,
          isStep: 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name + " (" + value?.short_name + ")",
                isEnabled: false,
              };
            })
          );
          //setPositions(result.data);
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    const loaWeights = async () => {
      try {
        const result = await postData("scorecard/list", {
          status: 1,
        });
        if (result.data) {
          let total = 0;
          let pWeight = 0;
          let exWeight = 0;
          let con = 0;
          let pref = 0;
          let edu = 0;
          let ywc = 0;
          result.data.map((value, key) => {
            if (value.alias === "potential_predictor_score") {
              pWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "executive_experience") {
              exWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "concerns") {
              con = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "recent_performance_review") {
              pref = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "highest_level_education") {
              edu = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "years_with_company") {
              ywc = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            }
            setPredictorWeight(pWeight);
            setExperienceWeight(exWeight);
            setConcernWeight(con);
            setPerformanceWeight(pref);
            setEducationWeight(edu);
            setYearWithCompany(ywc);
            setTotalWeight(total);
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loaWeights();
  }, []);

  useEffect(() => {
    async function userData() {
      try {
        const result = await postData("successor/list", {
          created_by: creator,
        });
        if (result.data) {
          setUsers(result.data);
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    if (creator && creator.length > 0) {
      userData();
    }
  }, [creator]);

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0);
    try {
      const result = await postData("conclusion/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        isReport: true,
        //is_interim: 2,
      });
      if (result.data) {
        let record = [];
        let _creator = [];
        result.data.map((item, key) => {
          _creator.push(item?._id);
          record.push({
            name: item.name,
            position: item.position,
            retirement: item.retirement,
            pos_details: item.pos_details,
            created_retirement_details: item.created_retirement_details,
            conclusion: item.conclusion,
            successors_details: item?.successors_details
              .map((value, key) => {
                let predictorCalculation = (predictorWeight * value?.overall_weighted_score) / 100;
                let experienceCalculation = (experienceWeight * (value.experience_details ? value?.experience_details[0]?.point : 0)) / 100;
                let concernsCalculation = (concernsWeight * value?.overall_score) / 100;
                let performanceCalculation = (performanceWeight * (value.performance_details ? value?.performance_details[0]?.point : 0)) / 100;
                let educationCalculation = (educationWeight * (value.education_details ? value?.education_details[0]?.point : 0)) / 100;
                let yearWithCompanyCalculation = (yearWithCompanyWeight * (value.cu_details ? value?.cu_details[0]?.point : 0)) / 100;
                let desireResult = value?.desire_details && value?.desire_details.length > 0 ? (value?.desire_details[0]?.isPass ? 1 : 2) : null;
                let loyaltyResult = value?.loyalty_details && value?.loyalty_details.length > 0 ? (value?.loyalty_details[0]?.isPass ? 1 : 2) : null;
                let RetirementResult = value?.retirement_details && value?.retirement_details.length > 0 ? (value?.retirement_details[0]?.isPass ? 1 : 2) : null;
                return {
                  ...value,
                  overallWeightScore:
                    desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2
                      ? 0.0
                      : (
                          parseFloat(predictorCalculation) +
                          parseFloat(experienceCalculation) +
                          parseFloat(concernsCalculation) +
                          parseFloat(performanceCalculation) +
                          parseFloat(educationCalculation) +
                          parseFloat(yearWithCompanyCalculation)
                        ).toFixed(2),
                  desireResult: desireResult,
                  loyaltyResult: loyaltyResult,
                  RetirementResult: RetirementResult,
                };
              })
              .filter((item) => item.is_interim === 2),
          });
        });
        setCreator(_creator);
        setSuccessors(record);
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const positionSelect = async (id, checked) => {
    if (id && id === "all") {
      setPositions(
        positions.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
    } else {
      setPositions(
        positions.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  useEffect(() => {
    let SelectedPosition = [];
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }

    if (SelectedPosition.length > 0) {
      loadSuccessors(SelectedPosition);
    } else {
      setSuccessors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  useEffect(() => {
    let SelectedPosition = [];
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
  }, [positions]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  // console.log('users',users)
  console.log("successors", successors);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("succession_plan_conclusion") + " - " + user?.company_name}</div>
          <div className="text-sm text-slate-800 font-bold">{t("please_make_a_selection_from_the_dropdown_to_generate_report")}</div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <ConclusionIndividualReport successors={successors} heading={heading} user={user} users={users} companyLogo={API_URL + user?.image} />
                  </Document>
                }
                fileName="scorecard.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          {/* <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">Choices</th>
              {heading.length > 0 ? (
                heading.map((item, index) => {
                  return (
                    <th key={index} className={"py-4 px-2  max-w-[240px] sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md " + item.isColor} >
                      {item.name}
                      <span className="block text-[11px] font-medium mt-1">
                        {item.option}
                      </span>
                    </th>
                  )
                })
              ) : ''}
            </tr>
          </thead> */}
          <tbody className="text-slate-700 text-sm font-light bg-slate-300 divide-y divide-slate-200">
            {Array.isArray(successors) && successors.length > 0 ? (
              successors.map((row) => {
                const totalConclusion = row.conclusion && row?.conclusion?.length > 0 ? row?.conclusion?.length : 0;
                return (
                  <>
                    {totalConclusion && totalConclusion > 0 ? (
                      <>
                        {row.conclusion && row.conclusion.length > 0 && (
                          <>
                            <tr className="!border-none">
                              <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tl-md" colSpan={3}>
                                {row.position} : {row.name}
                              </td>
                              <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tr-md" colSpan={8}>
                                {t("retirement_window")} : {row.retirement}
                              </td>
                            </tr>
                          </>
                        )}
                        {row.conclusion && row.conclusion.length > 0 && (
                          <tr className="text-white font-bold text-sm leading-none bg-slate-900">
                            <th className="py-4 px-2 text-left max-w-[240px] z-10 ">{t("choices")}</th>
                            {heading.length > 0
                              ? heading.map((item, index) => {
                                  return (
                                    <th key={index} className={"py-4 px-2  max-w-[240px] z-10 " + item.isColor}>
                                      {item.name}
                                      <span className="block text-[11px] font-medium mt-1">{item.option}</span>
                                    </th>
                                  );
                                })
                              : ""}
                          </tr>
                        )}
                        {row.conclusion &&
                          row.conclusion.map((items, index) => {
                            let zero_to_one = users.filter((itm) => itm?._id === items?.time_horizon_zero_to_one);
                            let one_to_three = users.filter((itm) => itm?._id === items?.time_horizon_one_to_three);
                            let three_to_five = users.filter((itm) => itm?._id === items?.time_horizon_three_to_five);

                            let time_horizon_zero_to_one_record = row?.successors_details?.length > 0 && row?.successors_details?.filter((rv) => rv._id === items?.time_horizon_zero_to_one);
                            let time_horizon_zero_to_one_name = "";
                            let time_horizon_zero_to_one_score = 0;
                            if (time_horizon_zero_to_one_record.length > 0) {
                              time_horizon_zero_to_one_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.time_horizon_zero_to_one)?.name;
                              time_horizon_zero_to_one_score =
                                row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.time_horizon_zero_to_one)?.overallWeightScore;
                            }

                            let time_horizon_one_to_three_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === items?.time_horizon_one_to_three);
                            let time_horizon_one_to_three_name = "";
                            let time_horizon_one_to_three_score = "";
                            if (time_horizon_one_to_three_record.length > 0) {
                              time_horizon_one_to_three_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.time_horizon_one_to_three)?.name;
                              time_horizon_one_to_three_score =
                                row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.time_horizon_one_to_three)?.overallWeightScore;
                            }

                            let time_horizon_three_to_five_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === items?.time_horizon_three_to_five);
                            let time_horizon_three_to_five_name = "";
                            let time_horizon_three_to_five_score = "";
                            if (time_horizon_three_to_five_record.length > 0) {
                              time_horizon_three_to_five_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.time_horizon_three_to_five)?.name;
                              time_horizon_three_to_five_score =
                                row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.time_horizon_three_to_five)?.overallWeightScore;
                            }

                            let replacement_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === items?.replacement);
                            let replacement_name = "";
                            let replacement_score = "";
                            if (replacement_record.length > 0) {
                              replacement_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.replacement)?.name;
                              replacement_score = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === items?.replacement)?.overallWeightScore;
                            }
                            return (
                              <tr className="bg-white" key={index}>
                                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">{items?.name}</td>
                                {heading.length > 0
                                  ? heading.map((item, index1) => {
                                      if (index1 === 0) {
                                        return (
                                          <td key={index + "_" + index1} className="py-3 px-2 text-left max-w-[240px] align-middle">
                                            <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded min-h-[34px]">
                                              {items?.isComment
                                                ? items?.comment_zero_to_one
                                                : time_horizon_zero_to_one_record.length > 0
                                                ? time_horizon_zero_to_one_name + " (" + (time_horizon_zero_to_one_score > 0 ? time_horizon_zero_to_one_score : 0) + ")"
                                                : (zero_to_one && zero_to_one.length > 0) || items?.time_horizon_zero_to_one === "external-hire"
                                                ? items?.time_horizon_zero_to_one_text
                                                : ""}
                                            </div>
                                          </td>
                                        );
                                      } else if (index1 === 1) {
                                        return (
                                          <td key={index + "_" + index1} className="py-3 px-2 text-left max-w-[240px] align-middle">
                                            <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded min-h-[34px]">
                                              {items?.isComment
                                                ? items?.comment_one_to_three
                                                : time_horizon_one_to_three_record.length > 0
                                                ? time_horizon_one_to_three_name + " (" + (time_horizon_one_to_three_score > 0 ? time_horizon_one_to_three_score : 0) + ")"
                                                : (one_to_three && one_to_three.length > 0) || items?.time_horizon_one_to_three === "external-hire"
                                                ? items?.time_horizon_one_to_three_text
                                                : ""}
                                            </div>
                                          </td>
                                        );
                                      } else if (index1 === 2) {
                                        return (
                                          <td key={index + "_" + index1} className="py-3 px-2 text-left max-w-[240px] align-middle">
                                            <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded min-h-[34px]">
                                              {items?.isComment
                                                ? items?.comment_three_to_five
                                                : time_horizon_three_to_five_record.length > 0
                                                ? time_horizon_three_to_five_name + " (" + (time_horizon_three_to_five_score > 0 ? time_horizon_three_to_five_score : 0) + ")"
                                                : (three_to_five && three_to_five.length > 0) || items?.time_horizon_three_to_five === "external-hire"
                                                ? items?.time_horizon_three_to_five_text
                                                : ""}
                                            </div>
                                          </td>
                                        );
                                      } else if (index1 === 3) {
                                        return (
                                          <td key={index + "_" + index1} className="py-3 px-2 text-left max-w-[240px] align-middle">
                                            <div className={"relative flex justify-center text-xs font-medium bg-white py-2 px-3 border border-slate-200 rounded text-slate-700 h-[34px]"}>
                                              {items?.isComment
                                                ? items?.comment_replacement
                                                : replacement_record.length > 0
                                                ? replacement_name + " (" + (replacement_score > 0 ? replacement_score : 0) + ")"
                                                : items?.replacement_text}
                                            </div>
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td key={index + "_" + index1} className="py-3 px-2 text-left max-w-[240px] align-middle">
                                            <div className="relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded min-h-[34px]"></div>
                                          </td>
                                        );
                                      }
                                    })
                                  : ""}
                              </tr>
                            );
                          })}
                        <tr className="bg-slate-100 !border-none">
                          <td className="py-3 px-2" colSpan={11}></td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )
                    }
                  </>
                );
              })
            ) : (
              <tr className="!border-none bg-slate-100">
                {loading ? (
                  <td className="py-3 px-2 text-left" colSpan={11}>
                    <div className="mt-2 space-y-5">
                      <ReportTableSkeleton />
                      <ReportTableSkeleton />
                    </div>
                  </td>
                ) : (
                  <td colSpan={11}>
                    <NoDataFound label={t("please_make_a_selection_from_the_dropdown_to_generate_report")} />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IndividualConclusion;
