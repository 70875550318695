import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import { Editor } from "@tinymce/tinymce-react";

import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { postData } from "../services/api";
import { tinymceApiKey } from "../helpers";

import { useTranslation } from "react-i18next";

const ManageEmailTemplate = () => {
  const { id } = useParams();
  const editorRef = useRef(null);
  if (id) {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Edit Email Template"
    );
  } else {
    setTitle(
      "Succession Planning Software for your Business with Succession Now | Add Email Template"
    );
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [subject, setSubject] = useState("");
  const [fromEmail, setFromEmail] = useState();
  const [content, setContent] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [bccMail, setBccMail] = useState([]);
  const [bccInputValue, setBccInputValue] = useState("");
  //const [isCancel,setIsCancel] = useState(false);

  useEffect(() => {
    // Email template details
    const loadEmailTemplateDetails = async () => {
      try {
        const result = await postData("email-template/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadEmailTemplateDetails();
  }, [id]);

  useEffect(() => {
    setSubject(details?.subject);
    setFromEmail(details?.from_email);
    setContent(details?.content);
    setEmails(details?.cc_email || []);
    setBccMail(details?.bcc_email || []);
  }, [details]);

  // const onCancel = async (e) => {
  //     setIsCancel(true);
  //     if(id){
  //         setSubject(details?.subject);
  //         setFromEmail(details?.from_email);
  //     }else{
  //         setSubject('');
  //         setFromEmail('');
  //     }
  //     setIsCancel(false);
  // }

  const onSubmit = async (e) => {
    setIsAdd(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "email-template/update";
        payload = {
          id: id,
          subject: subject,
          from_email: fromEmail,
          content: content,
          ccMail: emails,
          bccMail:bccMail
        };
      } else {
        path = "email-template/create";
        payload = {
          subject: subject,
          from_email: fromEmail,
          content: content,
          ccMail: emails,
          bccMail:bccMail
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsAdd(false);
        return navigate("/email-templates");
      } else {
        toastr.error(result.message);
        setIsAdd(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsAdd(false);
    }
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email.trim());
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addEmail(inputValue.trim());
    }
  };

  const addEmail = (email) => {
    if (email && isValidEmail(email) && !emails.includes(email)) {
      setEmails([...emails, email]);
      setInputValue("");
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const addBccEmail = (mail) => {
    if (mail && isValidEmail(mail) && !bccMail.includes(mail)) {
      setBccMail([...bccMail, mail]);
      setBccInputValue("");
    }
  };

  const handleBccKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addBccEmail(bccInputValue.trim());
    }
  };
  const handleRemoveBccEmail = (emailToRemove) => {
    console.log(emailToRemove)
    setBccMail(bccMail.filter((email) => email !== emailToRemove));
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">
          {id ? t("edit_email_template") : t("add_email_template")}{" "}
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="space-y-4">
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="relative w-full">
                <Input
                  label={t("subject")}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={t("enter_subject")}
                  inputValue={subject}
                  setInput={setSubject}
                />
              </div>
              <div className="relative w-full">
                <Input
                  label={t("from_email")}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={t("enter_from_email")}
                  inputValue={fromEmail}
                  setInput={setFromEmail}
                />
              </div>
            </div>
          </div>
          <div className="space-y-4 mt-4">
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="w-full">
                <label className="text-xs font-medium text-slate-500 mb-1">
                  CC Emails
                </label>
                <div className="flex flex-wrap items-center border border-gray-300 rounded p-2">
                  {emails &&
                    Array.isArray(emails) &&
                    emails?.length > 0 &&
                    emails?.map((email, index) => (
                      <div
                        key={index}
                        className="flex items-center font-semibold bg-green-50 text-dark-teal-700 text-sm rounded px-2 py-1 m-1"
                      >
                        {email}
                        <button
                          type="button"
                          className="ml-2 text-red-500 hover:text-red-700"
                          onClick={() => handleRemoveEmail(email)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  <input
                    type="text"
                    className="flex-grow outline-none border-none focus:ring-0 text-sm p-1"
                    placeholder="Enter Email And Press Enter or Comma"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                {inputValue && !isValidEmail(inputValue) && (
                  <p className="text-red-500 text-sm mt-1">
                    Invalid email format.
                  </p>
                )}
              </div>
              <div className="w-full">
                <label className="text-xs font-medium text-slate-500 mb-1">
                  BCC Emails
                </label>
                <div className="flex flex-wrap items-center border border-gray-300 rounded p-2">
                  {bccMail &&
                    Array.isArray(bccMail) &&
                    bccMail?.length > 0 &&
                    bccMail?.map((email, index) => (
                      <div
                        key={index}
                        className="flex items-center font-semibold bg-green-50 text-dark-teal-700 text-sm rounded px-2 py-1 m-1"
                      >
                        {email}
                        <button
                          type="button"
                          className="ml-2 text-red-500 hover:text-red-700"
                          onClick={() => handleRemoveBccEmail(email)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  <input
                    type="text"
                    className="flex-grow outline-none border-none focus:ring-0 text-sm p-1"
                    placeholder="Enter Email And Press Enter or Comma"
                    value={bccInputValue}
                    onChange={(e) => setBccInputValue(e.target.value)}
                    onKeyDown={handleBccKeyDown}
                  />
                </div>
                {bccInputValue && !isValidEmail(bccInputValue) && (
                  <p className="text-red-500 text-sm mt-1">
                    Invalid email format.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="space-y-4 mt-4">
            <div className="flex items-start gap-4">
              <div className="relative w-full">
                <Editor
                  apiKey={tinymceApiKey()}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  value={content}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "code",
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "code | undo redo | formatselect | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help ",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onEditorChange={(newText) => setContent(newText)}
                />
                {/* <Editor
                        editorState={content}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}                
                        /> */}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-5">
            {/* <Button 
                    buttonLabelClasses=''
                    buttonType={'button'} 
                    buttonIcon={(isCancel)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={'Cancel'} 
                    buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                    functions={onCancel}
                    buttonHasLink={false}
                    buttonDisabled={isCancel}
                    /> */}
            <Button
              buttonClasses=""
              buttonLabelClasses=""
              buttonType={"button"}
              buttonIcon={
                isAdd ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
              }
              buttonIconPosition={"left"}
              buttonLabel={t("save")}
              functions={onSubmit}
              buttonHasLink={false}
              buttonDisabled={isAdd}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEmailTemplate;
