import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";

import { useTranslation } from "react-i18next";
import { classNames } from "../../helper/classNames";

const ConfirmationPopup = ({
  isOpen,
  setIsOpen = () => {},
  getActionValue = () => {},
  title,
  message,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    getActionValue({
      button_type: "close",
      row_id: "",
      current_status: "",
    });
  };

  const onSubmit = () => {
    setLoading(true);
    getActionValue({
      button_type: "confirm",
      row_id: "",
      current_status: "",
    });
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-3xl font-medium leading-6 text-gray-900 text-center"
                    >
                    {(title)?title:'Confirmation'}
                    </Dialog.Title> */}
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className={classNames("md:py-2 py-2 md:px-6 px-4")}>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <div className="flex justify-start w-full items-center">
                        <i className="fa-regular fa-exclamation-triangle text-teal-600 rounded-full mr-2 text-2xl"></i>
                        <div className="text-lg font-bold pl-1">
                          {title ? title : "Confirmation"}
                        </div>
                      </div>
                      <hr className="w-full border-1 border-slate-400"></hr>
                      <div className="flex pt-6 text-sm text-gray-700 font-semibold text-center">
                        {message ? (
                          <p>{message}</p>
                        ) : (
                          <p>{t("reminder_input_all_necessary_information")}</p>
                        )}
                      </div>
                      <div className="flex items-center gap-4 mt-3">
                        <Button
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={
                            cancelButtonLabel ? cancelButtonLabel : t("cancel")
                          }
                          buttonClasses={
                            "!bg-rose-600 !text-base !rounded-lg !py-2 !px-6 !text-white  hover:!bg-rose-600/80"
                          }
                          functions={closeModal}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses={
                            "!bg-dark-teal-500 !border-dark-teal-500 hover:!bg-dark-teal-500/80 !rounded-lg"
                          }
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={
                            loading
                              ? "fa-light fa-spinner fa-spin"
                              : "fa-light fa-check"
                          }
                          buttonIconPosition={"left"}
                          buttonLabel={
                            confirmButtonLabel
                              ? confirmButtonLabel
                              : t("confirm")
                          }
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default ConfirmationPopup;
