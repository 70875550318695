import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import DropdownCheckbox from "../components/form/DropdownCheckbox";
import NoDataFound from "../components/NoDataFound";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { API_URL } from "../config/host";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import ReportTableSkeleton from "../components/loader/ReportTableSkeleton";
import SuccessionCandidateReport from "./pdf/SuccessionCandidateReport";
import { groupByNameAndCalculateAverage } from "../helpers";

const IndividualSuccessionCandidate = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Succession Candidate Strength");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [successors, setSuccessors] = useState([]);
  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [yearWithCompanyWeight, setYearWithCompany] = useState(0);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [candidateStrengthScore, setCandidateStrengthScore] = useState(0);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [avgCandidateStrengthScore, setAvgCandidateStrengthScore] = useState(0);
  const [recurring, setRecurring] = useState([]);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          type: 1,
          status: 1,
          isStep: 1,
          //isReport : 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name + " (" + value?.short_name + ")",
                isEnabled: true,
              };
            })
          );
          //setPositions(result.data);
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    const loaWeights = async () => {
      try {
        const result = await postData("scorecard/list", {
          status: 1,
        });
        if (result.data) {
          let total = 0;
          // eslint-disable-next-line array-callback-return
          let pWeight = 0;
          let exWeight = 0;
          let con = 0;
          let pref = 0;
          let edu = 0;
          let ywc = 0;
          result.data.map((value, key) => {
            if (value.alias === "potential_predictor_score") {
              pWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "executive_experience") {
              exWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "concerns") {
              con = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "recent_performance_review") {
              pref = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "highest_level_education") {
              edu = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "years_with_company") {
              ywc = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            }
            setPredictorWeight(pWeight);
            setExperienceWeight(exWeight);
            setConcernWeight(con);
            setPerformanceWeight(pref);
            setEducationWeight(edu);
            setYearWithCompany(ywc);
            setTotalWeight(total);
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loaWeights();
  }, []);

  const positionSelect = async (id, checked) => {
    if (id && id === "all") {
      setPositions(
        positions.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
    } else {
      setPositions(
        positions.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  useEffect(() => {
    let SelectedPosition = [];
    let SelectedUser = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (users) {
      users.map((usr) => {
        if (usr.isEnabled) {
          SelectedUser.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0 || SelectedUser.length > 0) {
      loadSuccessors(SelectedPosition, SelectedUser);
    } else {
      setSuccessors([]);
    }

    // if(positions && SelectedPosition.length > 0){
    //   loadUsers(SelectedPosition);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, users]);

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0);
    try {
      const result = await postData("highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        created_by: SelectedUser,
        isReport: true,
        is_interim: 2,
      });
      if (result.data) {
        let candidate = 0;
        let totalScore = 0;
        let recurringData = [];
        setSuccessors(
          result.data.map((item, key) => {
            if (item.successors_details && item.successors_details.length > 0) {
              let successors_details = item.successors_details.map((value, index) => {
                let predictorCalculation = (predictorWeight * value?.overall_weighted_score) / 100;
                let experienceCalculation = (experienceWeight * (value.experience_details ? value?.experience_details[0]?.point : 0)) / 100;
                let concernsCalculation = (concernsWeight * value?.overall_score) / 100;
                let performanceCalculation = (performanceWeight * (value.performance_details ? value?.performance_details[0]?.point : 0)) / 100;
                let educationCalculation = (educationWeight * (value.education_details ? value?.education_details[0]?.point : 0)) / 100;
                let yearWithCompanyCalculation = (yearWithCompanyWeight * (value.cu_details ? value?.cu_details[0]?.point : 0)) / 100;
                let desireResult = value?.desire_details && value?.desire_details.length > 0 ? (value?.desire_details[0]?.isPass ? 1 : 2) : null;
                let loyaltyResult = value?.loyalty_details && value?.loyalty_details.length > 0 ? (value?.loyalty_details[0]?.isPass ? 1 : 2) : null;
                let RetirementResult = value?.retirement_details && value?.retirement_details.length > 0 ? (value?.retirement_details[0]?.isPass ? 1 : 2) : null;
                let _score =
                  desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2
                    ? 0.0
                    : (
                        parseFloat(predictorCalculation) +
                        parseFloat(experienceCalculation) +
                        parseFloat(concernsCalculation) +
                        parseFloat(performanceCalculation) +
                        parseFloat(educationCalculation) +
                        parseFloat(yearWithCompanyCalculation)
                      ).toFixed(2);
                candidate += desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2 ? 0 : 1;
                totalScore += Number(_score);
                recurringData.push({
                  creator: item.name,
                  name: value.name,
                  score: _score,
                });
                return {
                  ...value,
                  overallWeightScore: _score,
                };
              });
              return {
                name: item.name,
                position: item.position,
                retirement: item.retirement,
                pos_details: item.pos_details,
                created_retirement_details: item.created_retirement_details,
                successors_details: successors_details,
              };
            } else {
              return [];
            }
          })
        );
        const commonRecords = groupByNameAndCalculateAverage(recurringData, "name");
        setTotalCandidate(candidate);
        setCandidateStrengthScore(totalScore);
        setRecurring(commonRecords.filter((item) => item?.creators.length > 1));
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  useEffect(() => {
    var avgCandidate = Number(candidateStrengthScore / totalCandidate) ? Number(candidateStrengthScore / totalCandidate).toFixed(2) : 0;
    setAvgCandidateStrengthScore(avgCandidate);
  }, [candidateStrengthScore, totalCandidate]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("succession_candidate_strength_report") + " - " + user?.company_name}</div>
          <div className="text-sm text-slate-800 font-bold">{t("please_make_a_selection_from_the_dropdown_to_generate_report")}</div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <SuccessionCandidateReport
                      successors={successors}
                      user={user}
                      totalCandidate={totalCandidate}
                      candidateStrengthScore={candidateStrengthScore}
                      avgCandidateStrengthScore={avgCandidateStrengthScore}
                      recurring={recurring}
                      companyLogo={API_URL + user?.image}
                    />
                  </Document>
                }
                fileName="profile_report.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
      </div>

      {/* Average Section */}
      <div className="relative mt-2">
        <div className="flex justify-center gap-2">
          <div className="max-w-xs w-full">
            <div className="w-full bg-white rounded overflow-hidden shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-sm leading-none text-center">{t("candidate_strength_score")}</div>
              </div>
              <div className="w-full relative">
                <div className={"w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      <div className="text-sm font-medium text-slate-600 text-center">{avgCandidateStrengthScore}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-xs w-full">
            <div className="w-full bg-white rounded overflow-hidden shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-sm leading-none text-center">{t("total_candidates_scored")}</div>
              </div>
              <div className="w-full relative">
                <div className={"w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      <div className="text-sm font-medium text-slate-500 text-center">{totalCandidate}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recurring Candidates */}
      {recurring && recurring.length > 0 && (
        <div className="relative mt-2">
          <div className="flex gap-2">
            <div className="w-full">
              <div className="text-base font-bold text-black py-2">{t("recurring_candidates")}</div>
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="min-w-max w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left max-w-[240px] sticky capitalize -top-6 z-10 bg-slate-900 rounded-tl-md">{t("candidates")}</th>
                      <th className="py-4 px-2 text-left max-w-[240px] sticky capitalize -top-6 z-10 bg-slate-900">{t("created")}</th>
                      <th className="py-4 px-2 text-left max-w-[240px] sticky capitalize -top-6 z-10 bg-slate-900 rounded-tr-md">{t("score")}</th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200 rounded-b overflow-hidden">
                    {recurring
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => {
                        return (
                          <>
                            <tr className={"!border-none"} key={index}>
                              <td className={"py-3 px-2 text-left max-w-[240px]"}>
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">{item?.name}</div>
                                  {/* <div className="block text-xs font-bold leading-tight text-teal-500">
                                {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                                </div> */}
                                </div>
                              </td>
                              <td className={"py-3 px-2 text-left max-w-[240px]"}>
                                <div className="block">
                                  {item?.creators.length > 0 &&
                                    item?.creators.map((it, idx) => {
                                      return (
                                        <>
                                          <div className="text-sm font-medium text-slate-600" key={idx}>
                                            {it?.creator}: <span className="font-bold text-teal-500">{it?.score}</span>
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </td>
                              <td className={"py-3 px-2 text-left max-w-[240px]"}>
                                <div className="block">
                                  <div className="text-sm font-medium text-slate-600">{item?.average_score ? item?.average_score : 0}</div>
                                </div>
                              </td>
                            </tr>
                            <tr className="bg-slate-100 !border-none">
                              <td className="py-3 px-2" colSpan={11}></td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <table className="min-w-max w-full table-auto">
          <tbody className="text-slate-700 text-sm font-light bg-slate-300 divide-y divide-slate-200">
            {Array.isArray(successors) && successors?.length > 0 ? (
              successors.map((row) => {
                return (
                  <>
                    {row.successors_details && (
                      <>
                        <tr className="bg-slate-300 !border-none">
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tl-md" colSpan={3}>
                            {row.position} : {row.name}
                          </td>
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tr-md" colSpan={8}>
                            {t("retirement_window")} : {row.retirement}
                          </td>
                        </tr>
                      </>
                    )}
                    {row.successors_details && (
                      <tr className="text-white font-bold text-xs leading-none bg-slate-900">
                        <th className="py-4 px-2 text-left max-w-[240px] z-10" colSpan={3}>
                          {t("potential_successors")}
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10" colSpan={8}>
                          {t("final_score")}
                        </th>
                      </tr>
                    )}
                    {row.successors_details &&
                      row.successors_details.map((item, index) => (
                        <tr className="bg-white" key={index}>
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top" colSpan={3}>
                            {item?.name}
                            <span className="block text-xs font-bold leading-tight text-teal-500">{item?.position_details ? item?.position_details[0]?.short_name : "N/A"}</span>
                          </td>
                          <td className={"py-3 px-2 text-left text-sm max-w-[240px] "} colSpan={8}>
                            {item?.overallWeightScore}
                          </td>
                        </tr>
                      ))}
                    <tr className="bg-slate-100 !border-none">
                      <td className="py-3 px-2" colSpan={11}></td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr className="">
                <td className="py-3 px-2 text-left" colSpan={20}>
                  {loading ? (
                    <div className="mt-2 space-y-5">
                      <ReportTableSkeleton />
                      <ReportTableSkeleton />
                    </div>
                  ) : (
                    <NoDataFound label={t("please_make_a_selection_from_the_dropdown_to_generate_report")} />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IndividualSuccessionCandidate;
