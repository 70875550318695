import React, { useCallback, useEffect, useState } from "react";
import { postData } from "../../../services/api";
import toastr from "toastr";
import Pagination from "../../Common/Pagination";
import Button from "../../form/Button";
import Loader from "../../loader/Loader";
import SyncingLoader from "../../loader/SyncingLoader";
import { Tooltip } from "react-tooltip";
import { NINEBOX_API_URL, NINEBOX_BASE_URL, ORGCHART_API_URL, ORGCHART_BASE_URL } from "../../../config/host";
import axios from "axios";
import SyncListSkeleton from "../../loader/SyncListSkeleton";
import Select from "../../form/Select";
import { useTranslation } from "react-i18next";

const PositionList = ({ syncingStep, syncFailed, syncSuccess, selectedPosition, setSelectedPosition = () => {}, arePositionAllSelected, setArePositionAllSelected = () => {}, syncDataModalOpen }) => {
  const { i18n, t } = useTranslation();
  const [positionList, setPositionList] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    try {
      const result = await postData("company-position/list", {
        keyword: keyword,
        limit: limit,
        offset: offset,
        keyword: keyword,
      });
      if (result.data) {
        setPositionList(result.data);
      }
      setTotalDataCount(result.count);
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
      console.log(err.message);
    } finally {
      setLoadingData(false);
    }
  }, [keyword, limit, offset]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setCurrentPage(event.selected);
    setOffset(newOffset);

    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    setSelectedPosition([]);
  };

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handleSelectedPosition = (item) => {
    setSelectedPosition((prevSelected) => {
      const isAlreadySelected = prevSelected.some((member) => member?._id === item?._id);

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers = positionList?.filter((member) => member._id);

    if (selectedPosition.length === filteredMembers.length) {
      setSelectedPosition([]);
    } else {
      setSelectedPosition(filteredMembers);
    }
  };

  useEffect(() => {
    if (syncDataModalOpen) {
      const filteredMembers = positionList?.filter((member) => member?._id);
      const isAllSelected = selectedPosition.length === filteredMembers.length && filteredMembers.length !== 0;

      if (isAllSelected) {
        setArePositionAllSelected(true);
      } else {
        setArePositionAllSelected(false);
      }
    }
  }, [arePositionAllSelected, positionList, selectedPosition, syncDataModalOpen]);

  const syncPosition = async (url, headersToken) => {
    const updatedPositionList = positionList.map((item) => {
      const isSelected = selectedPosition.some((member) => member._id === item._id);
      if (isSelected) {
        return { ...item, syncingStatus: "processing" };
      }
      return item;
    });
    setPositionList(updatedPositionList);
    try {
      while (selectedPosition.length > 0) {
        const currentPosition = selectedPosition[0];

        // Validate currentEmployee fields
        if (!currentPosition || !currentPosition.company_info?.email || !currentPosition.name) {
          console.error("Invalid position data:", currentPosition);
          toastr.error("Invalid position data encountered.");
          selectedPosition.shift();
          continue;
        }

        const path = url;
        const payload = {
          companyEmail: currentPosition.company_info?.email,
          position_details: {
            name: currentPosition?.name,
            short_name: currentPosition?.short_name,
          },
          code: "successionNow",
        };

        try {
          const res = await axios.post(path, payload, {
            headers: {
              Authorization: `Bearer ${headersToken}`,
              "Content-Type": "application/json",
            },
          });

          if (res?.data?.status === 200) {
            // toastr.success(`Successfully synced: ${currentEmployee.name}`);
            setPositionList((prevList) => prevList.map((item) => (item._id === currentPosition._id ? { ...item, syncingStatus: "success" } : item)));
            selectedPosition.shift(); // Remove the synced employee from the list
          } else {
            setPositionList((prevList) => prevList.map((item) => (item._id === currentPosition._id ? { ...item, syncingStatus: "failed" } : item)));
            selectedPosition.shift();
            console.log(`Failed to sync: ${currentPosition.name}`);
            // break; // Stop processing further employees
          }
        } catch (err) {
          console.error(`Error syncing employee: ${currentPosition.name}`, err);
          setPositionList((prevList) => prevList.map((item) => (item._id === currentPosition._id ? { ...item, syncingStatus: "failed" } : item)));
          if (err.response) {
            console.error("Server error:", err.response.data);
            toastr.error(`Error: ${err.response.data.message || "Server error occurred."}`);
          } else if (err.request) {
            // Request was made but no response received
            console.error("No response received:", err.request);
            toastr.error("Network error. Please try again later.");
          } else {
            console.error("Unexpected error:", err.message);
            toastr.error(`Unexpected error: ${err.message}`);
          }

          selectedPosition.shift();
          // break;
        }
      }

      if (selectedPosition.length === 0) {
        console.log("All employees have been successfully synced!");
      } else {
        console.log("Sync process stopped. Remaining employees:", selectedPosition);
      }
    } catch (err) {
      console.error("Critical error during the sync process:", err);
      toastr.error("A critical error occurred. Please try again later.");
    }
  };

  const getGenarateTokenForPosition = async (DOMAIN, API_URL = "") => {
    try {
      const res = await postData("create/token", {
        domain: DOMAIN,
      });
      if (res?.status === 200) {
        const url = API_URL + "sync-position";
        const headersToken = res?.data;
        syncPosition(url, headersToken);
      } else {
        toastr.error(res?.data?.message || "Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toastr.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      {loadingData && <SyncListSkeleton type={"position"} count={10} />}
      {!loadingData && (
        <>
          {" "}
          <div className="flex-shrink flex-grow">
            <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2">
              <div className="relative">
                <Select
                  xPlacement={"left"}
                  dropdownButtonClass={"!bg-white"}
                  dropdownClass={"w-auto"}
                  selectedValue={limit}
                  dropdownData={[
                    { _id: 1000000, label: t("all_items"), value: 1000000 },
                    ...[
                      { _id: 10, label: "10 " + t("items"), value: 10 },
                      { _id: 20, label: "20 " + t("items"), value: 20 },
                      { _id: 30, label: "30 " + t("items"), value: 30 },
                      { _id: 50, label: "50 " + t("items"), value: 50 },
                      { _id: 100, label: "100 " + t("items"), value: 100 },
                    ],
                  ]}
                  getSelectedValue={(e) => {
                    setLimit(e.value);
                    setOffset(0);
                    setCurrentPage(0);
                    setSelectedPosition([]);
                  }}
                />
              </div>
              {selectedPosition?.length > 0 && (
              <div className="relative">
                  <h1 className="font-semibold">
                    <span className="text-sm text-gray-500">
                    {selectedPosition.length} Employee
                    {selectedPosition.length > 1 ? "s" : ""} Selected
                  </span>
                </h1>
                </div>
              )}
              {positionList?.filter((member) => member?._id).length > 0 && (
                <div className="ml-auto" data-tooltip-id={`selectAllButton${1434}`} data-tooltip-place="bottom">
                  <Button
                    buttonType={"button"}
                    buttonClasses={"bg-transparent !text-teal-500 !px-0"}
                    buttonLabelClasses="text-teal-500"
                    buttonIcon={arePositionAllSelected ? "fa-solid fa-square-check" : "fa-light fa-square"}
                    buttonIconPosition={"left"}
                    buttonLabel={arePositionAllSelected ? "Unselect All Position" : "Select All Position"}
                    buttonHasLink={false}
                    functions={() => handleSelectAll()}
                  />
                  <Tooltip
                    id={`selectAllButton${1434}`}
                    className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                    render={() => (
                      <div className="gap-x-1">
                        <div className="text-[13px] font-normal leading-none flex items-center">
                          <div className="font-semibold">{arePositionAllSelected ? "Unselect All Position" : "Select All Position"}</div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
              {selectedPosition?.length > 0 && (
                <div className="flex items-center">
                  <Button
                    buttonLabel={"Sync Position"}
                    buttonIcon={"fa-solid fa-rotate"}
                    buttonIconPosition={"left"}
                    buttonClasses={"!bg-teal-500 !text-white"}
                    buttonLabelClasses={"!text-white"}
                    functions={() => {
                      if (syncingStep?.selectedWebsite?.code === "orgChart") {
                        getGenarateTokenForPosition(ORGCHART_BASE_URL, ORGCHART_API_URL);
                      } else if (syncingStep?.selectedWebsite?.code === "9BoxNow") {
                        getGenarateTokenForPosition(NINEBOX_BASE_URL, NINEBOX_API_URL);
                      } else {
                        toastr.error("Please Select a Website For Syncing");
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-250px)] -mx-4 px-4">
              {positionList &&
                Array.isArray(positionList) &&
                positionList?.length > 0 &&
                positionList?.map((user, index) => (
                  <>
                    <div className="bg-white rounded-md border border-slate-200 py-4 px-4 flex items-center gap-2 w-full">
                      <div className="flex justify-start flex-shrink-0">
                        <div className="flex items-center justify-between py-2" key={index}>
                          <input
                            type="checkbox"
                            id={`${user?._id}`}
                            checked={selectedPosition.some((member) => member?._id === user?._id)}
                            onChange={() => handleSelectedPosition(user)}
                            className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-teal-500 checked:hover:bg-teal-500 checked:bg-teal-500 "
                          />
                        </div>
                      </div>
                      <div className="flex-shrink flex-grow">
                        <h3 className="flex items-center gap-1 text-sm font-semibold text-teal-500 capitalize">{user?.name || "N/A"}</h3>
                      </div>
                      {user?.syncingStatus === "processing" && (
                        <div className="flex flex-col items-center ml-auto flex-shrink-0">
                          <Loader className={"scale-50"} />
                          <SyncingLoader className={"!text-xs"} />
                        </div>
                      )}
                      {user?.syncingStatus === "success" && (
                        <div className="flex flex-col items-center ml-auto flex-shrink-0">
                          <div className="w-8 h-8">
                            <img src={syncSuccess} alt="syncSuccess" />
                          </div>
                          <div className="flex text-center justify-center items-center text-xs font-semibold text-teal-600">{"Sync Finished"}</div>
                        </div>
                      )}
                      {user?.syncingStatus === "failed" && (
                        <div className="flex flex-col items-center ml-auto flex-shrink-0">
                          <div className="w-8 h-8">
                            <img src={syncFailed} alt="syncFailed" />
                          </div>
                          <div className="flex text-center justify-center items-center text-xs font-semibold text-rose-600">{"Sync Failed"}</div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
            </div>
          </div>
          <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount} forcePage={currentPage} />
        </>
      )}
    </>
  );
};

export default PositionList;
