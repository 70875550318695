import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import { useTranslation } from "react-i18next";
import Textarea from "../form/Textarea";
import { postData } from "../../services/api";

const ManageSurveyQuestionPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  loadList = () => {},
  id,
  type = "",
}) => {
  const { i18n, t } = useTranslation();
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data?._id) {
      setDescription(data?.question?.description);
      setName(data?.question?.name);
    }
  }, [data?._id]);

  const onSubmit = async () => {
    setLoading(true);
    try {
      let path = "";
      let payload = {};
      if (type === "executive") {
        path = "/employee-rating-option/edit-question";
        payload = {
          id: data?._id,
          name: name,
          description: description,
        };
      } else {
        path = "/employee-rating-option/edit-question";
        payload = {
          id: data?._id,
          name: name,
          description: description,
        };
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        loadList();
        setIsOpen({ isOpen: false, id: null, data: {} });
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setLoading(false);
  };

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {type === "executive"
                      ? "Manage Executive Audit"
                      : "Manage Board Effectiveness"}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={() => {
                        setIsOpen({ isOpen: false, id: null, data: {} });
                        setDescription({});
                        setName({});
                      }}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative mt-4 border bg-white border-gray-200 p-4  rounded-xl">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 flex flex-col">
                        <div className="space-y-4">
                          <div className="grid sm:grid-cols-1 gap-4">
                            <div className="relative w-full">
                              <Textarea
                                label={t("name")}
                                labelClasses={"!text-xs"}
                                inputValue={name}
                                inputPlaceholder={t("enter_name")}
                                setTextarea={setName}
                                requiredDisplay={true}
                              />
                            </div>
                            <div className="relative w-full">
                              <Textarea
                                label={t("description")}
                                labelClasses={"!text-xs"}
                                inputValue={description}
                                inputPlaceholder={t("enter_description")}
                                setTextarea={setDescription}
                                // requiredDisplay={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end gap-4 mt-10">
                          <Button
                            buttonClasses={
                              "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                            }
                            buttonLabelClasses="text-dark-teal-500 "
                            buttonType={"button"}
                            buttonIcon={"fa-solid fa-xmark"}
                            buttonIconPosition={"left"}
                            buttonLabel={t("cancel")}
                            buttonHasLink={false}
                            functions={() => {
                              setIsOpen({ isOpen: false, id: null, data: {} });
                              setDescription({});
                              setName({});
                            }}
                          />
                          <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={
                              loading
                                ? "fa-light fa-spinner fa-spin"
                                : "fa-regular fa-floppy-disk"
                            }
                            buttonIconPosition={"left"}
                            buttonLabel={t("save")}
                            functions={onSubmit}
                            buttonHasLink={false}
                            buttonDisabled={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ManageSurveyQuestionPopup;
