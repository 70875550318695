import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const RatingHistoryReport = ({
  details,
  reatingOptions,
  user,
  companyLogo
}) => {
  const { i18n, t } = useTranslation();  

  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: "100%",
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems:"center",
              fontSize: 16,
              position:'relative',
              width:'100%',
            }}
          >
            <Image style={styles.image} src={companyLogo} />
            <View style={{position:'absolute', right:0, top:0,}}>
              <Image
                style={{width: 120, height: 24, objectFit: "contain",}}
                src={logo}
              />
            </View>
          </View>
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoBold",
                  fontSize: 20,
                  textTransform: "capitalize",
                },
              ]}
            >
              Company Name: {user?.company_name}
            </Text>
          </View>
          <View
            style={[
              styles.tableOther,
              { display: "flex", flexDirection: "row", marginTop: 15 },
            ]}
          >
            <Text
              style={[
                styles.tableOtherData,
                {
                  display: "flex",
                  flexDirection: "row",
                  fontFamily: "RobotoMedium",
                  fontSize: 16,
                  textTransform: "capitalize",
                },
              ]}
            >
              {t("board_strength_report")}
            </Text>
          </View>
        </View>     

        <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }}>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", justifyContent:"space-between", columnGap: 20, padding:"10px 10px", backgroundColor:"#f2f2f2" }]}>
            <View style={{display:"flex", flexDirection:"row", width:"70%"}}>
              <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12 }]}>
                {t('board_effectiveness')}
              </Text>
            </View>                  
            {/* <View style={{display:"flex", flexDirection:"row", width:"30%", gap:5}}>
                <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12, }]}>{t('overall_score')}:</Text>
                <Text style={[styles.tableOtherData, {fontFamily: "RobotoRegular", fontSize: 12, color:"#000" }]}>{avg}</Text>
            </View> */}
          </View>            
          { reatingOptions.length>0 && reatingOptions.map((items, index) => {
              let ratingRowAnswers = items?.question?.answers;
              const ratedSurvey = details && details.filter((row) => row.questionId === items?.question?._id);
              let totalRating = 0;
              let ansLabel = ''; 
              let comment = '';
              let _ans = [];
              if(ratedSurvey && ratedSurvey.length>0){
                totalRating =  ratedSurvey[0]?.rating;
                ansLabel =  ratedSurvey[0]?.answerLabel;
                comment = ratedSurvey[0]?.comments;
                _ans = ratedSurvey[0]?.ids;
              }
              if(ratingRowAnswers && ratingRowAnswers.length>0){
                ratingRowAnswers.forEach((obj) => {
                  if (obj?.company_id === user?._id) {
                      _ans.push(obj?._id);
                  }
                });
              } 
                          
              return (
                <View style={{borderTop: "1px solid #e2e8f0", paddingHorizontal:3}} key={index}>
                  <View key={index} style={{ display: "flex", flexDirection: "row"}}>
                      <View style={[styles.tableData, {width:"70%", display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                        <Text style={[{whiteSpace:"nowrap", fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1, marginBottom:3 }]}>{Number(index+1)}.{' '+items?.question?.name}</Text>
                        {
                          (_ans && _ans.length>0) && 
                          <View style={{position:"relative", paddingLeft:10, marginTop:4}}>
                            {
                              [...new Set(_ans)].map((an,i) => {
                                let rowData = ratingRowAnswers.filter((row) => row._id === an);
                                if(rowData && rowData.length>0){
                                  return (
                                    <View style={{display:"flex", flexDirection:"row", alignItems:"center", gap:2, marginBottom:5}}>
                                      <View style={{width:4, height:4, borderRadius:"50%", backgroundColor:"rgb(71,85,105,0.5)"}} ></View>
                                      <Text style={{color:"rgb(71,85,105,1)", fontSize:9, lineHeight:1,}} >{rowData[0]?.description}</Text>
                                    </View>
                                  )
                                }
                              })
                            }
                          </View>
                        }
                        {
                          comment && 
                          <Text style={[{whiteSpace:"nowrap", fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1, paddingLeft:10, color:"#64748b" }]}>{comment}</Text>
                        }                    
                      </View>
                      
                      <View key={index} style={[styles.tableData, {width:"30%", display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                        <Text style={{ fontSize: 9 }}>
                        {((totalRating>0)?totalRating:'')+((ansLabel)?' ('+ansLabel+')':'')}                   
                        </Text>
                      </View>
                  </View>                  
                </View>
              )            
            }) 
          }
          </View>        
        
      </Page>
    </Document>
  );
};
export default RatingHistoryReport;
