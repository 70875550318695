import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Radio from "../form/Radio";
import Select from "../form/Select";
import { Tooltip } from "react-tooltip";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";
import ConfirmationPopup from "./ConfirmationPopup";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { classNames } from "../../helper/classNames";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { ManageDefaultConcernAndPredictorsSkleton } from "../loader/ManageDefaultConcernAndPredictorsSkleton";
import ConcernPredictorsItem from "../section/ManageTiersConcernPredictors/ConcernPredictorsItem";
import HideableInput from "../form/HideableInput";

const ManageTiersPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => {
  const { i18n, t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [step, setStep] = useState(1);
  const [questionData, setQuestionData] = useState([]);
  const [questionDataPredictor, setQuestionDataPredictor] = useState([]);
  const [processing, setProcesing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isContinue, seIsContinue] = useState(false);
  const [concerns, setConcerns] = useState([]);
  const [predictors, setPredictors] = useState([]);
  const [isEnabledTires, setIsEnabledTires] = useState();
  const [companyTires, setCompanyTires] = useState([]);
  const [tierDetails, setTierDetails] = useState([]);
  const [isUpdateConcern, setIsUpdateConcern] = useState(false);
  const [isUpdatePredictor, setIsUpdatePredictor] = useState(false);
  const [isRemoveTires, setIsRemoveTires] = useState(false);
  const [isLoadingDeleteTires, setIsLoadingDeleteTires] = useState(false);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] =
    useState(false);
  const [message, setMessage] = useState("");
  const [actionValue, setActionValue] = useState({});
  const [tabType, setTabType] = useState("concern");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedPredictorQuestions, setSelectedPredictorQuestions] = useState(
    []
  );
  const [warningData, setWarningData] = useState([]);
  const [warningDataPredictor, setWarningDataPredictor] = useState([]);
  const [rename, setRename] = useState(false);

  const tabHeading = [
    {
      title: t("conditions_of_concern_library"),
      description: "",
      value: "concern",
    },
    {
      title: t("potential_predictor_library"),
      description: "",
      value: "predictors",
    },
  ];

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "confirm") {
      const allConcernQuestions = Array.isArray(questionData)
        ? questionData.reduce((acc, item) => {
            return acc.concat(item.question);
          }, [])
        : [];
      const allPredictorQuestions = Array.isArray(questionDataPredictor)
        ? questionDataPredictor.reduce((acc, item) => {
            return acc.concat(item.question);
          }, [])
        : [];

      let concernsData;
      let predictorsData;
      if (isEnabledTires && isEnabledTires === "1") {
        concernsData =
          allConcernQuestions &&
          Array?.isArray(allConcernQuestions) &&
          allConcernQuestions?.length > 0 &&
          allConcernQuestions?.map((item) => ({
            ...item,
            headingId:
              item?.company_headings?.length > 0 &&
              item?.company_headings[0]?._id
                ? item?.company_headings[0]?._id
                : item?._id,
            // tierId: item.isDefault === true ? item?.tierId : null,
          }));
        predictorsData =
          allPredictorQuestions &&
          Array?.isArray(allPredictorQuestions) &&
          allPredictorQuestions?.length > 0 &&
          allPredictorQuestions?.map((item) => ({
            ...item,
            headingId:
              item?.company_headings?.length > 0 &&
              item?.company_headings[0]?._id
                ? item?.company_headings[0]?._id
                : item?._id,
            // tierId: item.isDefault === true ? item?.tierId : null,
          }));
      } else {
        concernsData =
          concerns &&
          Array?.isArray(concerns) &&
          concerns?.length > 0 &&
          concerns?.map((item) => ({
            ...item,
            tierId: item.isDefault === true ? item?.tierId : null,
          }));
        predictorsData =
          predictors &&
          Array?.isArray(predictors) &&
          predictors?.length > 0 &&
          predictors?.map((item) => ({
            ...item,
            tierId: item.isDefault === true ? item?.tierId : null,
          }));
      }
      const onUpdate = async () => {
        try {
          setLoading(true);
          const result = await postData("heading/update-user-tire", {
            concerns: concernsData,
            predictors: predictorsData,
            isEnabledTires: Number(isEnabledTires),
          });
          if (result.status && result.status === 200) {
            toastr.success(result.message);
            setIsOpen(false);
            setLoading(false);
          } else {
            toastr.error(result.message);
            setLoading(false);
          }
        } catch (error) {
          toastr.error(error.message);
          setLoading(false);
        }
      };
      onUpdate();
    }
  }, [actionValue]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const allConcernQuestions = Array.isArray(questionData)
        ? questionData.reduce((acc, item) => {
            return acc.concat(item.question);
          }, [])
        : [];
      const allPredictorQuestions = Array.isArray(questionDataPredictor)
        ? questionDataPredictor.reduce((acc, item) => {
            return acc.concat(item.question);
          }, [])
        : [];

      console.log(allConcernQuestions, "allConcernQuestions");

      let concernsData;
      let predictorsData;
      if (isEnabledTires && isEnabledTires === "1") {
        concernsData =
          allConcernQuestions &&
          Array?.isArray(allConcernQuestions) &&
          allConcernQuestions?.length > 0 &&
          allConcernQuestions?.map((item) => ({
            ...item,
            headingId:
              item?.company_headings?.length > 0 &&
              item?.company_headings[0]?._id
                ? item?.company_headings[0]?._id
                : item?._id,
            // tierId: item.isDefault === true ? item?.tierId : null,
          }));
        predictorsData =
          allPredictorQuestions &&
          Array?.isArray(allPredictorQuestions) &&
          allPredictorQuestions?.length > 0 &&
          allPredictorQuestions?.map((item) => ({
            ...item,
            headingId:
              item?.company_headings?.length > 0 &&
              item?.company_headings[0]?._id
                ? item?.company_headings[0]?._id
                : item?._id,
            // tierId: item.isDefault === true ? item?.tierId : null,
          }));
      } else {
        concernsData =
          concerns &&
          Array?.isArray(concerns) &&
          concerns?.length > 0 &&
          concerns?.map((item) => ({
            ...item,
            tierId: item.isDefault === true ? item?.tierId : null,
          }));
        predictorsData =
          predictors &&
          Array?.isArray(predictors) &&
          predictors?.length > 0 &&
          predictors?.map((item) => ({
            ...item,
            tierId: item.isDefault === true ? item?.tierId : null,
          }));
      }
      const res = await postData("heading/checking-user-tire", {
        concerns: concernsData,
        predictors: predictorsData,
        isEnabledTires: Number(isEnabledTires),
      });
      if (res.status === 200) {
        const result = await postData("heading/update-user-tire", {
          concerns: concernsData,
          predictors: predictorsData,
          isEnabledTires: Number(isEnabledTires),
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsOpen(false);
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } else {
        if (res.type === 1) {
          toastr.error(res.message);
        } else if (res.type === 2) {
          setMessage(res.message);
          setEnabledConfirmationPopup(true);
        } else {
          toastr.error(res.message);
        }
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadGlobalTires = async () => {
      try {
        const result = await postData("filter-item/list", {
          type: "position-group",
          status: 1,
        });
        if (result.data) {
          setCompanyTires(
            result.data.map((item) => {
              return {
                _id: item?._id,
                name: item?.name,
                isDefault: true,
                disabled: false,
                tierId: item?._id,
              };
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadGlobalTires();

    const loadTierDetails = async () => {
      setProcesing(true);
      try {
        const result = await postData("company-session/details", {});
        if (result.data) {
          setTierDetails(result?.data?.evaluationFrequency);
          setIsEnabledTires(result?.userData?.isEnabledTires.toString());
          if (
            result?.data?.evaluationFrequency &&
            result?.data?.evaluationFrequency.length > 0
          ) {
            setStep(2);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadTierDetails();
  }, []);

  useEffect(() => {
    const loadHeadingList = async () => {
      setProcesing(true);
      try {
        const result = await postData("heading/list", {
          sortQuery: { ordering: 1 },
          isGlobal: true,
        });
        if (result.data) {
          let _concerns = [];
          let _predictors = [];
          result.data.map((item) => {
            let tierId =
              item?.company_headings && item?.company_headings.length > 0
                ? item?.company_headings[0]?.tierId
                : null;
            if (item?.type === "concern") {
              _concerns.push({
                _id: item?._id,
                name: item?.name,
                description: item?.description,
                alias: item?.alias,
                weight: item?.weight,
                created_at: new Date(),
                headingId: item?.headingId ? item?.headingId : item?._id,
                isDefault:
                  item?.company_headings && item?.company_headings.length > 0
                    ? true
                    : false,
                disabled:
                  item?.company_headings && item?.company_headings.length > 0
                    ? false
                    : true,
                tierId: tierId,
              });
            } else if (item?.type === "predictors") {
              _predictors.push({
                _id: item?._id,
                name: item?.name,
                description: item?.description,
                alias: item?.alias,
                weight: item?.weight,
                created_at: new Date(),
                headingId: item?.headingId ? item?.headingId : item?._id,
                isDefault:
                  item?.company_headings && item?.company_headings.length > 0
                    ? true
                    : false,
                disabled:
                  item?.company_headings && item?.company_headings.length > 0
                    ? false
                    : true,
                tierId: tierId,
              });
            }
          });
          setConcerns(_concerns);
          setPredictors(_predictors);
          setIsUpdatePredictor(true);
          setIsUpdateConcern(true);
          setProcesing(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadHeadingList();
  }, []);

  const onManageTiers = async () => {
    try {
      seIsContinue(true);
      const result = await postData("company-session/create", {
        companyTires: isEnabledTires === "1" ? companyTires : [],
        isEnabledTires: isEnabledTires,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setTierDetails(result?.data?.evaluationFrequency);
        setStep(2);
        seIsContinue(false);
      } else {
        toastr.error(result.message);
        seIsContinue(false);
      }
    } catch (error) {
      toastr.error(error.message);
      seIsContinue(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsLoadingDeleteTires(true);
      const result = await postData("company-session/remove", {});
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setStep(1);
        setIsLoadingDeleteTires(false);
        setIsRemoveTires(false);
      } else {
        toastr.error(result.message);
        setIsLoadingDeleteTires(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsLoadingDeleteTires(false);
    }
  };

  useEffect(() => {
    let enabledConcerns =
      concerns.length > 0 && concerns.filter((it) => it?.isDefault)?.length;
    if (enabledConcerns > 0) {
      if (enabledConcerns === 8) {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdateConcern(false);
      } else {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdateConcern(false);
      }
    }
  }, [isUpdateConcern]);

  useEffect(() => {
    let enabledPredictors =
      predictors.length > 0 && predictors.filter((it) => it?.isDefault)?.length;
    if (enabledPredictors > 0) {
      if (enabledPredictors === 8) {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdatePredictor(false);
      } else {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isDefault === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
        setIsUpdatePredictor(false);
      }
    }
  }, [isUpdatePredictor]);

  const updateTireHandler = (tire_id, event) => {
    if (event) {
      setCompanyTires((companyTires) =>
        companyTires.map((it, i) => {
          if (it?._id === tire_id) {
            return {
              ...it,
              isDefault: true,
            };
          } else {
            return it;
          }
        })
      );
    } else {
      setCompanyTires((companyTires) =>
        companyTires.map((it, i) => {
          if (it?._id === tire_id) {
            return {
              ...it,
              isDefault: false,
            };
          } else {
            return it;
          }
        })
      );
    }
  };

  //Question group List Api Call for concern
  const loadList = useCallback(async () => {
    setLoading(true);
    const filteredData = companyTires?.filter((item) => item.isDefault);
    try {
      const result = await postData("heading/question-group", {
        type: "concern",
        status: 1,
        individualIds: tierDetails?.map((item) => item?.tire_id),
      });

      if (result.data) {
        const updatedData = result.data.map((group) => ({
          ...group,
          question: group.question.map((q) => ({
            ...q,
            isDefault: group?.isActive
              ? q?.company_headings && q?.company_headings.length > 0
                ? true
                : false
              : false,
            isChecked: group?.isActive
              ? q?.company_headings && q?.company_headings.length > 0
                ? true
                : false
              : false,
          })),
        }));
        const allTotalQuestions = result?.data?.flatMap(
          (item) => item.total_questions
        );
        setWarningData(allTotalQuestions);
        setQuestionData(updatedData);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [tierDetails]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  //Question group List Api Call
  const loadListPredictors = useCallback(async () => {
    setLoading(true);
    const filteredData = companyTires?.filter((item) => item.isDefault);

    try {
      const result = await postData("heading/question-group", {
        type: "predictors",
        status: 1,
        individualIds: tierDetails?.map((item) => item?.tire_id),
      });

      if (result.data) {
        const updatedData = result.data.map((group) => ({
          ...group,
          question: group.question.map((q) => ({
            ...q,
            isChecked: group?.isActive
              ? q?.company_headings && q?.company_headings.length > 0
                ? true
                : false
              : false,
            isDefault: group?.isActive
              ? q?.company_headings && q?.company_headings.length > 0
                ? true
                : false
              : false,
          })),
        }));
        const allTotalQuestions = result?.data?.flatMap(
          (item) => item.total_questions
        );
        setQuestionDataPredictor(updatedData);
        setWarningDataPredictor(allTotalQuestions);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [tierDetails]);

  useEffect(() => {
    loadListPredictors();
  }, [loadListPredictors]);

  // Update selectedQuestions whenever questionData concern changes
  useEffect(() => {
    const filteredQuestions = questionData
      .flatMap((group) => group.question)
      .filter((question) => question.isChecked && question.isDefault);

    setSelectedQuestions(filteredQuestions);
  }, [questionData]);

  // Update selectedQuestions whenever questionData predictor changes
  useEffect(() => {
    const filteredQuestions = questionDataPredictor
      .flatMap((group) => group.question)
      .filter((question) => question.isChecked && question.isDefault);

    setSelectedPredictorQuestions(filteredQuestions);
  }, [questionDataPredictor]);

  // Handle checkbox for concern changes
  const handleCheckboxChange = (question) => {
    setQuestionData((prevData) =>
      prevData.map((group) => ({
        ...group,
        question: group.question.map((q) =>
          q._id === question._id
            ? {
                ...q,
                isChecked: !q.isChecked, // Toggle isChecked
                isDefault: !q.isChecked, // Sync isDefault with isChecked
              }
            : q
        ),
      }))
    );
  };

  // Handle checkbox for Predictor changes
  const handleCheckboxChangePredictor = (question) => {
    setQuestionDataPredictor((prevData) =>
      prevData.map((group) => ({
        ...group,
        question: group.question.map((q) =>
          q._id === question._id
            ? {
                ...q,
                isChecked: !q.isChecked, // Toggle isChecked
                isDefault: !q.isChecked, // Sync isDefault with isChecked
              }
            : q
        ),
      }))
    );
  };

  const updateHandler = (heading_id, event, type, subType, subTypeId) => {
    if (event) {
      if (type === "concern") {
        let enabledConcerns =
          concerns.length > 0 && concerns.filter((it) => it?.isDefault)?.length;
        if (Number(enabledConcerns + 1) === 8) {
          setConcerns((concern) =>
            concern.map((item, i) => {
              if (subType === "tires" && item?._id === heading_id) {
                return {
                  ...item,
                  tierId: subTypeId,
                };
              } else if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                if (item?.isDefault === false) {
                  return {
                    ...item,
                    disabled: true,
                  };
                } else {
                  return item;
                }
              }
            })
          );
          setIsUpdateConcern(true);
        } else {
          console.log("else");
          setConcerns((concern) =>
            concern.map((item, i) => {
              if (subType === "tires" && item?._id === heading_id) {
                console.log("else 1");
                return {
                  ...item,
                  tierId: subTypeId,
                };
              } else if (item?._id === heading_id) {
                console.log("else 2");
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                console.log("else 3");
                return item;
              }
            })
          );
          setIsUpdateConcern(true);
        }
      } else if (type === "predictors") {
        let enabledPredictors =
          predictors.length > 0 &&
          predictors.filter((it) => it?.isDefault)?.length;
        if (Number(enabledPredictors + 1) === 8) {
          setPredictors((predictors) =>
            predictors.map((item, i) => {
              if (subType === "tires" && item?._id === heading_id) {
                return {
                  ...item,
                  tierId: subTypeId,
                };
              } else if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                if (item?.isDefault === false) {
                  return {
                    ...item,
                    disabled: true,
                  };
                } else {
                  return item;
                }
              }
            })
          );
          setIsUpdatePredictor(true);
        } else {
          setPredictors((predictors) =>
            predictors.map((item, i) => {
              if (subType === "tires" && item?._id === heading_id) {
                return {
                  ...item,
                  tierId: subTypeId,
                };
              } else if (item?._id === heading_id) {
                return {
                  ...item,
                  isDefault: true,
                };
              } else {
                return item;
              }
            })
          );
          setIsUpdatePredictor(true);
        }
      }
    } else {
      if (type === "concern") {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (subType === "tires" && item?._id === heading_id) {
              return {
                ...item,
                tierId: subTypeId,
              };
            } else if (item?._id === heading_id) {
              return {
                ...item,
                isDefault: false,
              };
            } else {
              if (item?.isDefault === false) {
                return {
                  ...item,
                  disabled: false,
                };
              } else {
                return item;
              }
            }
          })
        );
        setIsUpdateConcern(true);
      } else if (type === "predictors") {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (subType === "tires" && item?._id === heading_id) {
              return {
                ...item,
                tierId: subTypeId,
              };
            } else if (item?._id === heading_id) {
              return {
                ...item,
                isDefault: false,
              };
            } else {
              if (item?.isDefault === false) {
                return {
                  ...item,
                  disabled: false,
                };
              } else {
                return item;
              }
            }
          })
        );
        setIsUpdatePredictor(true);
      }
    }
  };

  const handleAssignTier = async (tierId, name) => {
    try {
      setLoading(true);
      const response = await postData("company-session/assign", {
        tire_id: tierId,
        title: name,
      });

      if (response?.status === 200) {
        toastr.success(response?.data?.message);
        loadList();
        loadListPredictors();
      } else {
        toastr.error(response?.data?.message || "Failed to update status.");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toastr.error(
        error?.response?.data?.message ||
          "An unexpected error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full transform rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all ${
                    step === 1 ? "max-w-xl " : "max-w-6xl "
                  }`}
                >
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900 text-center my-5"
                  >
                    {t("manage_tiers")}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={""}
                      buttonClasses={
                        "!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                  <div className="relative divide-y divide-slate-200">
                    <div className="py-1 px-4 sm:px-6 flex flex-col">
                      <div className="flex items-center justify-center gap-3 ">
                        {step === 2 && isEnabledTires === "1" && (
                          <>
                            <div className="p-2 border rounded-md bg-rose-100 border-rose-800">
                              <Checkbox
                                checkboxLableClass={"text-rose-800"}
                                checkboxInputClass={""}
                                checkboxClass={
                                  "!bg-white peer-checked:!bg-rose-600 peer-checked:!border-rose-600 peer-checked:!text-white"
                                }
                                divClass={"!items-start"}
                                checkboxName={"remove-tires"}
                                isChecked={isRemoveTires}
                                checkboxLabel={"Are You Delete Tires?"}
                                checkboxValue={"remove-tires"}
                                getCheckboxValue={(code, event) => {
                                  if (event.target.checked === true) {
                                    setIsRemoveTires(true);
                                  } else {
                                    setIsRemoveTires(false);
                                  }
                                }}
                              />
                            </div>
                            {isRemoveTires && (
                              <Button
                                buttonClasses={classNames(
                                  "!h-9 !bg-rose-600 !rounded-full"
                                )}
                                buttonLabelClasses={"!text-white"}
                                buttonType={"button"}
                                buttonIcon={
                                  isLoadingDeleteTires
                                    ? "fa-light fa-spinner fa-spin"
                                    : "fa-light fa-trash"
                                }
                                buttonIconPosition={"left"}
                                buttonLabel={t("remove_tiers")}
                                functions={onDelete}
                                buttonHasLink={false}
                                buttonDisabled={isRemoveTires ? false : true}
                              />
                            )}
                          </>
                        )}
                      </div>
                      {step && step === 1 ? (
                        <>
                          <div className="relative mb-5 gap-5">
                            <div className="flex gap-4 sm:gap-6">
                              <label
                                className={`flex items-center justify-center gap-2 w-28 h-12 border rounded-xl cursor-pointer text-sm font-medium transition-all duration-300 ease-in-out ${
                                  isEnabledTires === "1"
                                    ? "bg-teal-500 text-white border-teal-600"
                                    : "bg-gray-50 text-gray-800 border-teal-600 hover:border-teal-500 hover:bg-teal-100"
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="type"
                                  value="1"
                                  checked={isEnabledTires === "1"}
                                  onChange={(event) =>
                                    setIsEnabledTires(event.target.value)
                                  }
                                  className="hidden"
                                />
                                <span className="leading-tight">
                                  {t("enabled_tires")}
                                </span>
                              </label>
                              <label
                                className={`flex items-center justify-center gap-2 w-28 h-12 border rounded-xl cursor-pointer text-sm font-medium transition-all duration-300 ease-in-out ${
                                  isEnabledTires === "2"
                                    ? "bg-teal-500 text-white border-teal-600"
                                    : "bg-gray-50 text-gray-800 border-teal-600 hover:border-teal-500 hover:bg-teal-100"
                                }`}
                              >
                                <input
                                  type="radio"
                                  name="type"
                                  value="2"
                                  checked={isEnabledTires === "2"}
                                  onChange={(event) =>
                                    setIsEnabledTires(event.target.value)
                                  }
                                  className="hidden"
                                />
                                <span className="leading-tight">
                                  {" "}
                                  {t("disable_tires")}
                                </span>
                              </label>
                            </div>
                          </div>

                          {isEnabledTires === "1" &&
                            companyTires.length > 0 &&
                            companyTires.map((item, idx) => {
                              return (
                                <div className="relative mb-5" key={idx}>
                                  <div
                                    className={`flex items-center gap-4 p-4 border rounded-md cursor-pointer transition-all duration-300 ease-in-out ${
                                      item?.isDefault
                                        ? "bg-teal-50 border-teal-600"
                                        : "bg-gray-100 border-gray-300 hover:border-teal-500 hover:bg-teal-100"
                                    }`}
                                    onClick={() =>
                                      updateTireHandler(
                                        item?._id,
                                        !item?.isDefault
                                      )
                                    }
                                  >
                                    <Checkbox
                                      checkboxLableClass="text-slate-800"
                                      checkboxInputClass=""
                                      checkboxClass="!rounded-full"
                                      divClass="!items-start"
                                      checkboxName={item?._id}
                                      isChecked={item?.isDefault}
                                      checkboxLabel={item?.name}
                                      checkboxValue={item?._id}
                                      getCheckboxValue={(val, event) =>
                                        updateTireHandler(
                                          val,
                                          event.target.checked
                                        )
                                      }
                                      isDisabled={item?.disabled}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : step && step === 2 ? (
                        isEnabledTires && isEnabledTires === "1" ? (
                          <div className="grid grid-cols-1 gap-8 mt-2">
                            <div className="relative">
                              <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5">
                                {tabHeading?.map((type) => (
                                  <button
                                    key={type}
                                    type="button"
                                    className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform ${
                                      tabType === type?.value
                                        ? "text-teal-600 border-teal-600 font-semibold scale-105"
                                        : "text-slate-500 border-transparent hover:text-teal-500 hover:border-teal-500 hover:scale-105"
                                    }`}
                                    onClick={() => setTabType(type?.value)}
                                  >
                                    {type?.title}
                                  </button>
                                ))}
                              </div>
                              {tabType === "concern" && (
                                <>
                                  {warningData &&
                                    Array.isArray(warningData) &&
                                    warningData.length > 0 && (
                                      <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 rounded-md">
                                        <p className="font-bold mb-2">
                                          The administrator has rearranged or
                                          removed "tires." Please rearrange the
                                          questions under "tires" below on your
                                          end.
                                        </p>
                                        <div className="relative">
                                          {warningData?.map((warData, idx) => (
                                            <div
                                              key={idx}
                                              className="flex items-start space-x-2 mb-2 last:mb-0"
                                            >
                                              <i className="fa-regular fa-star text-orange-500 text-[16px]"></i>
                                              <div className="text-sky-700 font-semibold">
                                                {warData}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}

                                  <div className="grid grid-cols-3 gap-4 p-4 overflow-auto scroll-smooth scrollbar">
                                    {questionData &&
                                      Array?.isArray(questionData) &&
                                      questionData?.length > 0 &&
                                      questionData?.map((category, index) => (
                                        <div
                                          className={`border rounded-lg shadow-md  ${
                                            category?.isActive
                                              ? ""
                                              : "cursor-not-allowed"
                                          }`}
                                          key={category?._id}
                                        >
                                          <div
                                            className={`p-4 flex justify-between items-center border-t  rounded-t-lg ${
                                              category?.isActive
                                                ? "bg-teal-500 cursor-pointer"
                                                : "bg-slate-400 cursor-not-allowed"
                                            }`}
                                          >
                                            <div className="flex text-lg font-bold text-slate-50">
                                              {category?.name}
                                              {/* <HideableInput
                                                value={category?.name}
                                                onSave={(value) =>
                                                  console.log(
                                                    value,
                                                    "data vlaueeee"
                                                  )
                                                }
                                                hideInputClasses={
                                                  "whitespace-nowrap truncate !text-lg"
                                                }
                                                inputClasses={"h-6 rounded-sm"}
                                                inputBodyclass={
                                                  "h-10 flex items-center"
                                                }
                                              /> */}

                                              <div className="text-lg font-bold text-slate-50 mt-2">
                                                {`(${category?.question?.length})`}
                                              </div>
                                            </div>
                                            {!category?.isActive && (
                                              <div
                                                className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer bg-amber-200 text-amber-700`}
                                                onClick={() =>
                                                  handleAssignTier(
                                                    category?._id,
                                                    category?.name
                                                  )
                                                }
                                              >
                                                Assign Tier
                                              </div>
                                            )}
                                          </div>
                                          {category?.question?.length > 0 ? (
                                            <div className="list-disc pl-6 p-4 space-y-1 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-280px)]">
                                              {category?.question?.map(
                                                (q, index) => (
                                                  <div
                                                    className={`flex text-gray-700 p-2 rounded-md border border-slate-300 shadow-sm font-semibold justify-between ${
                                                      !q?.isDefault &&
                                                      selectedQuestions?.length >=
                                                        8
                                                        ? "bg-slate-200"
                                                        : "bg-transparent"
                                                    }`}
                                                  >
                                                    <div className="flex justify-start px-2">
                                                      <div
                                                        className="flex items-center justify-between py-2"
                                                        key={index}
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          onChange={() =>
                                                            handleCheckboxChange(
                                                              q
                                                            )
                                                          }
                                                          checked={
                                                            category?.isActive
                                                              ? q.isChecked
                                                              : false
                                                          }
                                                          disabled={
                                                            category?.isActive
                                                              ? q.isDefault &&
                                                                q.isChecked
                                                                ? false // Not disabled
                                                                : selectedQuestions?.length <
                                                                  8
                                                                ? false
                                                                : !q.isDefault &&
                                                                  !q.isChecked
                                                                ? true // Disabled
                                                                : false // Enabled
                                                              : true
                                                          }
                                                          className="border cursor-pointer w-5 h-5 border-slate-400 
                 focus:ring-transparent rounded-full
                 checked:rounded-full 
                 checked:text-dark-teal-600 
                 checked:bg-dark-teal-600 
                 checked:hover:bg-dark-teal-600 
                 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300"
                                                        />
                                                      </div>
                                                    </div>

                                                    {q.name}

                                                    <div
                                                      className="flex-shrink-0"
                                                      data-tooltip-id={
                                                        "info-tooltip-" + q?._id
                                                      }
                                                      data-tooltip-place="left"
                                                    >
                                                      {q?.description && (
                                                        <>
                                                          <Tooltip
                                                            id={
                                                              "info-tooltip-" +
                                                              q?._id
                                                            }
                                                            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                                                            render={() => (
                                                              <div className="w-60 p-1 rounded-md">
                                                                <div className="text-[14px] font-normal">
                                                                  <div className="font-normal text-white">
                                                                    {
                                                                      q?.description
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          />
                                                          <i
                                                            className="fa-light fa-circle-info"
                                                            style={{
                                                              color: "#14b8a6",
                                                            }}
                                                          ></i>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ) : (
                                            <div className="list-disc pl-6 p-4 space-y-1">
                                              <div className="flex justify-center items-center">
                                                <i className="fas fa-exclamation-circle mr-2 text-slate-500 text-[25px]"></i>
                                              </div>
                                              <div className="flex justify-center items-center text-xl font-normal text-slate-700">
                                                No questions are available in
                                                this group at the moment.
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </>
                              )}

                              {tabType === "predictors" && (
                                <>
                                  {warningDataPredictor &&
                                    Array.isArray(warningDataPredictor) &&
                                    warningDataPredictor.length > 0 && (
                                      <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 rounded-md">
                                        <p className="font-bold mb-2">
                                          The administrator has rearranged or
                                          removed "tires." Please rearrange the
                                          questions under "tires" below on your
                                          end.
                                        </p>
                                        <div className="relative">
                                          {warningDataPredictor?.map(
                                            (warData, idx) => (
                                              <div
                                                key={idx}
                                                className="flex items-start space-x-2 mb-2 last:mb-0"
                                              >
                                                <i className="fa-regular fa-star text-orange-500 text-[16px]"></i>
                                                <div className="text-sky-700 font-semibold">
                                                  {warData}
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}

                                  <div className="grid grid-cols-3 gap-4 p-4 overflow-auto scroll-smooth scrollbar">
                                    {questionDataPredictor &&
                                      Array?.isArray(questionDataPredictor) &&
                                      questionDataPredictor?.length > 0 &&
                                      questionDataPredictor?.map(
                                        (category, index) => (
                                          <div
                                            className={`border rounded-lg shadow-md  ${
                                              category?.isActive
                                                ? ""
                                                : "cursor-not-allowed"
                                            }`}
                                            key={index}
                                          >
                                            <div
                                              className={`p-4 flex justify-between items-center border-t  rounded-t-lg ${
                                                category?.isActive
                                                  ? "bg-teal-500 cursor-pointer"
                                                  : "bg-slate-400 cursor-not-allowed"
                                              }`}
                                            >
                                              <div className="flex gap-2 text-lg font-bold text-slate-50">
                                                {category.name}
                                                <div className="text-lg font-bold text-slate-50">
                                                  {`(${category?.question?.length})`}
                                                </div>
                                              </div>
                                              {!category?.isActive && (
                                                <div
                                                  className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer bg-amber-200 text-amber-700`}
                                                  onClick={() =>
                                                    handleAssignTier(
                                                      category?._id,
                                                      category?.name
                                                    )
                                                  }
                                                >
                                                  Assign Tier
                                                </div>
                                              )}
                                            </div>
                                            {category?.question?.length > 0 ? (
                                              <div className="list-disc pl-6 p-4 space-y-1 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-280px)]">
                                                {category?.question?.map(
                                                  (q, index) => (
                                                    <div
                                                      className={`flex text-gray-700 p-2 rounded-md border border-slate-300 shadow-sm font-semibold justify-between ${
                                                        !q?.isDefault &&
                                                        selectedPredictorQuestions?.length >=
                                                          8
                                                          ? "bg-slate-200"
                                                          : "bg-transparent"
                                                      }`}
                                                    >
                                                      <div className="flex justify-start px-2">
                                                        <div
                                                          className="flex items-center justify-between py-2"
                                                          key={index}
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            onChange={() =>
                                                              handleCheckboxChangePredictor(
                                                                q
                                                              )
                                                            }
                                                            checked={
                                                              category?.isActive
                                                                ? q.isChecked
                                                                : false
                                                            }
                                                            disabled={
                                                              category?.isActive
                                                                ? q.isDefault &&
                                                                  q.isChecked
                                                                  ? false // Not disabled
                                                                  : selectedPredictorQuestions?.length <
                                                                    8
                                                                  ? false
                                                                  : !q.isDefault &&
                                                                    !q.isChecked
                                                                  ? true // Disabled
                                                                  : false // Enabled
                                                                : true
                                                            }
                                                            className="border cursor-pointer w-5 h-5 border-slate-400 
                  focus:ring-transparent rounded-full
                  checked:rounded-full 
                  checked:text-dark-teal-600 
                  checked:bg-dark-teal-600 
                  checked:hover:bg-dark-teal-600 
                  disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300"
                                                          />
                                                        </div>
                                                      </div>

                                                      {q.name}
                                                      <div
                                                        className="flex-shrink-0"
                                                        data-tooltip-id={
                                                          "info-tooltip-" +
                                                          q?._id
                                                        }
                                                        data-tooltip-place="left"
                                                      >
                                                        {q?.description && (
                                                          <>
                                                            <Tooltip
                                                              id={
                                                                "info-tooltip-" +
                                                                q?._id
                                                              }
                                                              className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                                                              render={() => (
                                                                <div className="w-60 p-1 rounded-md">
                                                                  <div className="text-[14px] font-normal">
                                                                    <div className="font-normal text-white">
                                                                      {
                                                                        q?.description
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            />
                                                            <i
                                                              className="fa-light fa-circle-info"
                                                              style={{
                                                                color:
                                                                  "#14b8a6",
                                                              }}
                                                            ></i>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="list-disc pl-6 p-4 space-y-1">
                                                <div className="flex justify-center items-center">
                                                  <i className="fas fa-exclamation-circle mr-2 text-slate-500 text-[25px]"></i>
                                                </div>
                                                <div className="flex justify-center items-center text-xl font-normal text-slate-700">
                                                  No questions are available in
                                                  this group at the moment.
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="grid grid-cols-2 gap-8">
                            <div className="relative">
                              <div className="flex items-center justify-start">
                                <div className="text-sm font-bold text-slate-800">
                                  {t("conditions_of_concern_library")}
                                </div>
                              </div>
                              {processing && (
                                <ManageDefaultConcernAndPredictorsSkleton
                                  rowValue={
                                    "!grid-cols-1 !max-h-[calc(100vh-280px)]"
                                  }
                                />
                              )}
                              {!processing && (
                                <div className="grid grid-cols-1 gap-4 p-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-280px)]">
                                  {concerns.length > 0 ? (
                                    concerns.map((it, idxs) => {
                                      return (
                                        <>
                                          <ConcernPredictorsItem
                                            item={it}
                                            index={idxs}
                                            isSelectTiers={false}
                                            tierDetails={tierDetails}
                                            getCheckboxValue={(val, event) =>
                                              updateHandler(
                                                val,
                                                event.target.checked,
                                                "concern",
                                                ""
                                              )
                                            }
                                          />
                                        </>
                                      );
                                    })
                                  ) : processing ? (
                                    <ManageDefaultConcernAndPredictorsSkleton />
                                  ) : (
                                    <NoDataFound label={"No Data Found"} />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="relative">
                              <div className="flex items-center justify-start">
                                <div className="text-sm font-bold text-slate-800">
                                  {t("potential_predictor_library")}
                                </div>
                              </div>
                              {processing && (
                                <ManageDefaultConcernAndPredictorsSkleton
                                  rowValue={
                                    "!grid-cols-1 !max-h-[calc(100vh-280px)]"
                                  }
                                />
                              )}
                              {!processing && (
                                <div className="grid grid-cols-1 gap-4 p-4 overflow-auto scroll-smooth scrollbar max-h-[calc(100vh-280px)]">
                                  {predictors.length > 0 ? (
                                    predictors.map((itm, inxs) => {
                                      return (
                                        <>
                                          <ConcernPredictorsItem
                                            item={itm}
                                            index={inxs}
                                            isSelectTiers={false}
                                            tierDetails={tierDetails}
                                            getCheckboxValue={(val, event) =>
                                              updateHandler(
                                                val,
                                                event.target.checked,
                                                "predictors",
                                                ""
                                              )
                                            }
                                          />
                                        </>
                                      );
                                    })
                                  ) : processing ? (
                                    <ManageDefaultConcernAndPredictorsSkleton />
                                  ) : (
                                    <NoDataFound label={"No Data Found"} />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-1 gap-4">
                    {step && step === 1 ? (
                      <Button
                        buttonClasses="!bg-teal-500"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          isContinue
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-check"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("continue")}
                        functions={() => {
                          let hasEmptyString = companyTires.some(
                            (item) => !item
                          );
                          if (hasEmptyString && isEnabledTires === "1") {
                            toastr.error("There have empty input");
                          } else {
                            onManageTiers();
                          }
                        }}
                        buttonHasLink={false}
                        buttonDisabled={isContinue}
                      />
                    ) : (
                      <Button
                        buttonClasses="!bg-teal-500"
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={
                          loading
                            ? "fa-light fa-spinner fa-spin"
                            : "fa-light fa-check"
                        }
                        buttonIconPosition={"left"}
                        buttonLabel={t("confirm")}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                      />
                    )}
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("cancel")}
                      buttonClasses={
                        "bg-white border border-dark-teal-500 !text-dark-teal-500 ml-2"
                      }
                      buttonLabelClasses="text-dark-teal-500"
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {enabledConfirmationPopup && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup}
          title={t("confirmation")}
          message={message}
          confirmButtonLabel={t("confirm")}
          cancelButtonLabel={t("cancel")}
          setIsOpen={(val) => setEnabledConfirmationPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        />
      )}
    </>
  );
};

export default ManageTiersPopup;
