import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../../logo-succession-now.png";
import Shape from "../../../shape.png";

import RobotoLight from "../../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const SecHelp = ({ companyLogo }) => {
  const styles = StyleSheet.create({
    image: {
      width: 200,
      height: 80,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0  auto",
    },
    sectionTitle: {
      fontSize: 14,
      fontFamily: "RobotoBold",
      textDecoration: "underline",
      marginBottom: 10,
    },
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
      backgroundColor: "#f1f5f9",
    },
    subDes: {
      fontSize: 12,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      marginBottom: 10,
    },
    flexPart: {
      display: "flex",
      flexDirection: "column",
    },
    section: {
      fontSize: 13,
    },
    listSection: {
      paddingLeft: 20,
      display: "flex",
      flexDirection: "column",
      fontFamily: "RobotoItalic",
    },
    listPart: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  });

  return (
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View
        style={Object.assign({
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        })}
        fixed
      >
        <View
          style={Object.assign({
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          })}
        >
          <Image style={Object.assign({ objectFit: "cover" })} src={Shape} />
        </View>
        <View
          style={Object.assign({
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          })}
        >
          <Image style={Object.assign({ objectFit: "contain" })} src={logo} />
        </View>
      </View>
      <View
        style={Object.assign({
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontSize: 16,
          alignItems: "center",
          position: "relative",
          width: "100%",
        })}
      >
        <Image style={styles.image} src={companyLogo} />
        <View style={Object.assign({ position: "absolute", right: 0, top: 0 })}>
          <Image style={Object.assign({ width: 120, height: 24, objectFit: "contain" })} src={logo} />
        </View>
      </View>

      <View
        style={Object.assign({
          textAlign: "center",
          margin: 30,
          display: "flex",
          flexDirection: "column",
          fontFamily: "RobotoMedium",
          justifyContent: "center",
          fontSize: 24,
        })}
      >
        <Text
          style={Object.assign({
            fontFamily: "RobotoBold",
            fontSize: 12,
            textTransform: "uppercase",
            color: "#14b8a6",
            letterSpacing: 3,
            textAlign: "center",
          })}
        >
          Tutorial/Instruction Manual
        </Text>
        <Text
          style={Object.assign({
            fontFamily: "RobotoBold",
            fontSize: 12,
            textTransform: "uppercase",
            textAlign: "center",
          })}
        >
          SuccessionNow!
        </Text>
      </View>
      <View style={styles.flexPart}>
        <Text style={styles.sectionTitle}>General Overview</Text>
        <Text style={styles.subDes}>
          SuccessionNow! is designed to first and foremost create an effective and efficient pathway for succession planning within companies. Its aim is to 1) Minimize service disruptions during
          times of management transition; 2) Ensure organizational viability over the long term; 3) Clarify employee development paths; 4) Recognize (identify), evaluate, and develop current talent;
          5) Create opportunities for employees, and relieve the stress created by turnover in key positions. Successions Plans are being recognized as vital in many industries. As an example, the
          company industries’ regulatory arm (The NCUA) has proposed regulations requiring Companies to have functioning succession plans. Required or not, succession plans importance is critical for
          the on-going management of successful organizations.
        </Text>
        <Text style={styles.subDes}>SuccessionNow is designed to do this with a straight forward methodology that is time-sensitive, user friendly, and comprehensive.</Text>
        <Text style={styles.subDes}>The following sections outline each of the components of SuccessionNow!</Text>
      </View>
      <View style={styles.flexPart}>
        <Text style={styles.sectionTitle}>The Profile Section:</Text>
        <Text style={styles.subDes}>
          This Section allows the user to sign-in and set up their own profile; their name, current position (the position being evaluated for future replacement) and the evaluator (generally the same
          person person).
        </Text>
      </View>
      <View style={styles.flexPart}>
        <Text style={styles.sectionTitle}>Positions & Competencies</Text>
        <Text style={styles.subDes}>
          This section lists many Sr. Management positions found within companies. The titles are generic, (your exact titles may differ), but the competencies & descriptions will be easy to identify.
          By selecting a position from the drop-down (from the <strong>Profile Section</strong>), the competency for that position will automatically appear providing key information regarding the
          position. If desired, the Administration can edit the information provided and add additionally competencies. On the <strong>Replacement Readiness</strong> section, the evaluator will be
          asked to determine if the competency is a good fit for the Potential Successor.
        </Text>
      </View>
      <View style={styles.flexPart}>
        <Text style={styles.sectionTitle}>Successor Section</Text>
        <Text style={styles.subDes}>
          This section allows the evaluator to identify a roster of potential successors. Typically, this includes the evaluator’s direct reports, but outliers (not a direct report) can also be
          included. List their name and their current title within the organization (maximum of four per position). The screen will then direct you to create their individual profile. The following
          succession factors will appear
        </Text>
      </View>
      <View style={styles.flexPart}>
        <Text style={styles.sectionTitle}>Individual Profile</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            1. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Years of Executive Experience:</Text> Self-explanatory with a drop-down scale to select from. Years of
            executive experience can come from both inside the current company or outsidefrom other organizations and is defined as vice-president or higher.
          </Text>
          <Text style={styles.subDes}>
            2. Current Potential Concerns<Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Years with Company:</Text> Self-explanatory with a drop-down scale to select from.The results of this item
            arenot calculated in the numerical ratings or on the scorecard; it is provided for informational purposes only and can be used as a “tie-breaker” if needed.
          </Text>
          <Text style={styles.subDes}>
            3. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Highest Level Education:</Text> Self-explanatory with a drop-down scale to select from. The highest rating is
            an MBA or equivalent. of higher. Any Master’s Degree qualifies as an MBA equivalent. PHD’s or law degrees are considered at the same level as MBA’s or equivalent even though they are
            higher. Designations such as CPA, AEP, etc. are not included – only the highest level of graduation from an accredited university or college.
          </Text>
          <Text style={styles.subDes}>
            4. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Recent Performance Review:</Text> Self-explanatory with a drop-down to select from. Your company’s Performance
            level designations should be included on this drop-down. If a candidate has not yet had a formal performance review, an informal or subjective rating from what the evaluator has witnessed
            to date, can be included in this section.
          </Text>
          <Text style={styles.subDes}>
            5. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Loyalty towards the Company:</Text> Self-explanatory with a drop-down scale to select from. This is a
            Pass/Fail rating. Any Potential Successor graded “questionable” will receive a zero score and be eliminated from succession consideration. Reasoning: hopefully obvious!
          </Text>
          <Text style={styles.subDes}>
            6. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Desire to Advance:</Text> Self-explanatory with a drop-down scale to select from. A key consideration is the
            Candidate’s actual desire for upward mobility. Upward mobility may not be important to all employees, but, important to know! This is a Pass/Fail rating. The choices are, No Desire to
            advance, Desires Advancement and Unquestionable Desire to advance. Any Candidate graded “no desire”, fails this question and will be eliminated from final succession consideration. To be
            clear, a rating of “no desire” is not an indicator of a bad employee,simply, the employee may be satisfied and secure in their current position, with no aspiration to advance.
          </Text>
          <Text style={styles.subDes}>
            7. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Retirement Window:</Text> Self-explanatory with a drop-down to select from. Understanding when a Potential
            Successor’s plan on retiring is key to succession planning. Knowing when Potential Successors plan on retiring, clearly directs succession thinking and planning.If a Candidate’s retirement
            window is short, it may not warrant the time, effort and energy for evaluation. A candidate with a Retirement Window of 0-2 years will be eliminated from succession consideration. If the
            evaluator would still like to consider a Candidate with a short retirement window, this section can easily be over-ridden.
          </Text>
          <Text style={styles.subDes}>
            8. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Additional Comments:</Text> Area to free-style, provide justification comments or informational notes
          </Text>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart)}>
        <Text style={styles.sectionTitle}>Conditions of Concerns</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>1. Each Potential Successor will automatically appear on the columns (from the listing on the prior section)– maximum of four candidates per position.</Text>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart)}> 2. Current Potential Concerns: </Text>
            Eight potential concerns are listed. “Concerns” are issues that could be detrimental to a candidate’s advancement, if not identified and corrected. “Concerns” couldslow-down or even thwart
            aPotential Successor’s advancement if not addressed. The “Concerns” are conditions of criticality; necessary for succession readiness…they are weighted equally, and scored on a scale from
            1-5:
          </Text>
          <View style={{ paddingLeft: 20, display: "flex", flexDirection: "column" }}>
            <Text style={styles.subDes}>
              <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>No Concern (5.0):</Text> Concernsare not an issue at all;
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Easily Correctable Concern(4.0): </Text> Infrequent lapses requiring some attention;
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Minor Concern (3.0): </Text> Attention is required;
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Significant Concern (2.0): </Text> Potential deal-breaker if not addressed & corrected;
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Major Concern (1.0).</Text> Immediate attention & remedial action required.
            </Text>
          </View>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { paddingLeft: 20 })}>
        <View style={styles.listSection}>
          <Text style={{ marginTop: 20, fontFamily: "RobotoItalic", fontSize: 12 }}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold", fontSize:12 })}>Following are the list of Concerns:</Text> Evaluate the Candidate’s current levels of concerns
          </Text>
        </View>
        <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
          <View style={styles.listSection}>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of Leadership Ability </Text> (Ability to Inspire)
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of Management Breadth </Text> (Understanding of all required areas of the replacement position)
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of Operational Expertise </Text> (Depth of understanding & ability to deliver)
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of ExecutiveDemenor </Text> (Outward or professional conduct or behavior – the candidate “looks the part”)
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Inadequate Public/Business Image </Text> (Inadequate Public/Business Image )
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of Organizational Maturity </Text> (Understands tact, diplomacy, effective reasoning – also infuses an
              element of humility)
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of Organizational Energy</Text> (Easily winded, unable to perform required assignments)
            </Text>
            <Text style={styles.subDes}>
              <Text style={Object.assign({ fontFamily: "RobotoBold", marginRight: 10 })}>Lack of Strategic Vision</Text> (Focus is on long-term plans, ideas, improvements & programs – not just
              tactical)
            </Text>
          </View>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart)}>
        <View style={styles.listSection}>
          <View style={{ display: "flex", flexDirection: "column" }}>
            <Text style={styles.subDes}>
              3. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Overall Score (1-5; 5 = Best):</Text> The final score for the Conditions of Concern will be shown for each
              Potential Successor. It will be a weighted average of all of the eight “Concerns” scored.
            </Text>
            <Text style={styles.subDes}>
              4. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Additional Comments: </Text> Area to free-style, provide justification comments or informational notes. This
              can also be an area to provide counsel for a Candidate in areas of concern.
            </Text>
          </View>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart)}>
        <Text style={styles.sectionTitle}>Potential Predictors</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            1. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Potential Predictors</Text> are behaviors, competencies or characteristics that can signal readiness for
            advancement. They form the make-up for Executive & Senior Management level positions. Successful Successors will score relatively high in each of the listed predictors. Unlike performance
            review factors, that score current or past performance, these predictors are used to help determine a candidate’s preparation or propensity for future needs. It provides a vital evaluation
            for succession planning and is the key-stone for mapping out future development needs and concerns for future executives. Each Potential Successor will automatically appear on the columns
            – maximum of four per position.
          </Text>
          <Text style={styles.subDes}>
            2. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Weightings: </Text> The Potential Predictors are weighted according to their importance. SuccessionNow! has
            pre-set the Predictor weightings at levels deemed appropriate and consistent with their overall value. However, each client can re-set the weights according to their desires and/or needs.
            The weightings must total 100%.
          </Text>
          <Text style={styles.subDes}>
            3. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Potential Predictors and Descriptions:</Text> Each Potential Predictor is identified by title and description.
            The descriptors provide depth and completeness to the title. Read them carefully. The Predictors are;{" "}
            <strong>Propensity to Lead, Capacity to Advance, Strategic Thinking, Results Oriented, Teamwork & Cooperation, Agent for Change, Decision-Making and Professionalism.</strong> Each
            Predictor will be rated from a 5-pointdrop-down scale. The overall weighted score will appear on the bottom of the section per candidate. When completed, the evaluator will be able to
            “sore-thumb” or do a side-by-side peer comparison. If any adjustments or edits are desired, the evaluator simply needs to hit the drop-down and make the appropriate change. The rating
            scale is:
          </Text>
          <View style={{ paddingLeft: 20, display: "flex", flexDirection: "column" }}>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>5.0:</Text> Clear Strength: Requires little to no improvement</Text>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>4.5:</Text> Strength: Strong performer; minor refinement helpful</Text>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>4.0:</Text> Skilled & Capable: Additional seasoning would be beneficial</Text>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>3.5:</Text> Progressing very well: On-going refinement & seasoning needed</Text>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>3.0:</Text> Progressing positively: Continued development & seasoning necessary</Text>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>2.5:</Text> Needs Improvement: Clearly needs Improvement; not yet there</Text>
            <Text style={styles.subDes}><Text style={Object.assign({ fontFamily: "RobotoBold",fontSize:12 })}>2.0:</Text> Needs Significant Improvement: A long road ahead with a doubtful futuret</Text>
          </View>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
        <Text style={styles.sectionTitle}>Succession Plan Scorecard</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            1. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>The Scorecard:</Text> The Scorecard provides the final or summary scoring on all input and evaluated sections
            in a 1-5 rating scale (automatically); it is weighted according to the weights set on the left-hand side of the section. In essence, provides the final Succession Planning Ranking.
            Corrections or edits can easily be made by going back to any section in question and making the adjustment/edit. The Scorecard screen will automatically and immediately re-calculate.
            Weights are pre-set, but may be adjusted according to the desires of the company. Totally 100%.
          </Text>
          <Text style={styles.subDes}>
            2. <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Additional Comments: </Text>
            Area to free-style, provide justification comments or informational notes. This can also be an area to provide counsel for a candidate in areas of concern.
          </Text>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
        <Text style={styles.sectionTitle}>Succession Planning Summary </Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}> This section simply displays the results from the Scorecard in a Bar Graph for easy review and peer comparison</Text>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
        <Text style={styles.sectionTitle}>Development Plan</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Current Needs: Needed Skills & Attributes:</Text> This section provides the opportunity to provide a development
            plan for each Potential Successor based on needed skills & attributes. This could include development of problem-solving skills, decision-making, presenting to large or small groups,
            learning to play nice with superiors, peers or subordinates, expressing oneselfconvincingly, assertiveness, tact & diplomacy, etc. A timeline to accompany the plan is also recommended.
          </Text>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Current Needs:Experience: </Text> A listing of needed seasoning, exposure and experience in critical areas of the
            Potential Successor’s framework. As an example, this could include more depth and understanding in Lending, Operations, IT, Supervision, HR, etc. A time-line puts teeth into the plan.
          </Text>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Current Needs: Education:</Text> This can include any additional formal (college or university) education and
            also specific education by sponsoring entities like CUNA, SHRM or other training providers, including in-house course study. The same recommendation on time-lines.
          </Text>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
        <Text style={styles.sectionTitle}>Advancement Readiness</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Readiness Timeline: </Text> The section provides the evaluator with timeline choices for candidate advancement,
            namely; “Immediate, 1-3 years, 4-5 years, more than 5, or never”. Even though SuccessionNow! uniquely provides for quantitative evaluation, the final determination can be both quantitative
            and qualitative. As an example, a high numeric score of 4.25 or higher may indicate a Candidate’s “immediate” readiness for advancement, however, there could still be a factor(s) that
            might suggest further seasoning, education or behavioral changes yet need to occur. This section, provides the evaluator the flexibility and latitude to make that final call, considering
            all aspects of advancement readiness, both objective and subjective.
          </Text>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Competency Fit: </Text> As described earlier, this section provides the evaluator the opportunity to compare the
            Candidate’s overall qualifications and advancement readiness with the competencies required for the future position. When making a determination, consider the level of Competency Fit based
            on the Candidates Readiness TimeLine, i.e., does the Candidate meet the necessary competencies when ready for advancement. It matches the position competencies detailed in the section
            “Positions & Competencies” with the “Readiness Timeline”. The choices are: Excellent Fit, Good Fit, Marginal Fit, Unlikely Fit & Poor Fit. A selection of “Excellent Fit” or “Good Fit”
            would confirm the Candidate’s capability for advancement (at the assigned readiness date). A selection of anything less, might put in serious questionthe candidate’squalifications for
            advancement.
          </Text>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
        <Text style={styles.sectionTitle}>Replacement Conclusion:</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            This Section invites the evaluator to make a “First, Second, Third, and Fourth” choice for advancement. It asks the question, is there a Candidate ready for immediate advancement (defined
            as 0-1 year), or will it take longer (1-3 years or 4-5 years)? If no one is immediately ready, the choice would be an external hire.
          </Text>
          <Text style={styles.subDes}>
            A company may need to also designate an employee for succession in times of “Emergency” or as an “Interim” while an executive search is being conducted. This “Emergency” or “Interim
            Replacement” may not be the first, second, or even third choice for ultimate succession (perhaps because the candidate has a short retirement window- 0-2 years),but fits the need on a
            short-term basis. The final column in this section provides for identification and selection of this individual.
          </Text>
          <Text style={styles.subDes}>
            <Text style={Object.assign(styles.listPart, { fontFamily: "RobotoBold" })}>Approvals: </Text>
            Each Succession Plan needs to be approved by the appropriate Executive. Generally, this will fall to the President/CEO, but it may be the responsibility of another designated executive.
            The Approval section should be completed and dated, with the approvers name & title.
          </Text>
        </View>
      </View>
      <View style={Object.assign(styles.flexPart, { marginTop: 20 })}>
        <Text style={styles.sectionTitle}>Annual Updates:</Text>
        <View style={styles.listSection}>
          <Text style={styles.subDes}>
            SuccessionNow! is designed for annual review to accommodate the on-going changes in organizations, candidates, candidates’ qualifications and the needs of the organization. For
            effectiveness, compliance and peace of mind (Boards and Executives), this process should be updated on an annual basis.SuccessionNow! will automatically renew each year unless notified by
            the client to discontinue.
          </Text>
        </View>
      </View>
    </Page>
  );
};

export default SecHelp;
