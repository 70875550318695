import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toastr from "toastr";
import { useTranslation } from "react-i18next";
import { classNames } from "../../helper/classNames";
import Button from "../form/Button";
import { postData } from "../../services/api";

const ResendSurveyPopup = ({
  isOpen,
  setIsOpen = () => {},
  data = "all",
  loadList = () => {},
  setSelectedMember = () => {},
  selectedMember,
}) => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  const closeModal = () => {
    setIsOpen({ isOpen: false, data: null });
    setSelectedMember([]);
  };

  useEffect(() => {
    async function employeeData() {
      try {
        const result = await postData("assign/list", {
          sortQuery: { created_at: -1 },
          status: 1,
          is_invited: 1,
        });
        if (result?.data) {
          setEmployees(
            result?.data?.length > 0 &&
              result?.data?.map((items) => {
                let firstName = "";
                let lastName = "";
                if (!items?.first_name) {
                  let nameParts = items?.name?.split(/\s+/);
                  firstName = nameParts?.[0] || "";
                  lastName = nameParts?.slice(1).join(" ") || "";
                } else {
                  firstName = items?.first_name;
                  lastName = items?.last_name;
                }
                return {
                  _id: items?._id,
                  label: firstName + " " + lastName + " - " + items?.email,
                  value: items?.email,
                  position: items?.designation,
                  first_name: firstName,
                  last_name: lastName,
                  isEnabled: true,
                };
              })
          );
        } else {
          console.log("Employee list message", result.message);
        }
      } catch (error) {
        console.log("Error employee list catch", error.message);
      }
    }
    employeeData();
  }, []);

  const onSubmit = async () => {
    let payloadData = {};
    if (data?._id) {
      payloadData =
        data &&
        employees &&
        employees?.length > 0 &&
        employees?.filter((item) => item?._id === data?.parent_id);
    } else {
      payloadData = employees.filter((employee) =>
        selectedMember.some((member) => member?.parent_id === employee._id)
      );
    }
    try {
      setLoading(true);
      const result = await postData("executive/resend-survey", {
        employees: payloadData,
        sendFor: "resend",
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsOpen({ isOpen: false, data: null });
        setLoading(false);
        loadList();
        setSelectedMember([]);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50  w-full" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto flex items-center justify-center">
            <div className="flex items-center justify-center text-center m-auto w-full md:p-0 p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full bg-white rounded-3xl h-auto text-left shadow-2xl transform transition-all sm:my-8 p-0 sm:max-w-sm">
                  <div className={classNames("md:py-6 py-4 md:px-6 px-4")}>
                    <div className="flex flex-col justify-center items-center gap-2">
                      <div className="flex justify-start w-full items-center">
                        <i className="fa-solid fa-paper-plane text-dark-teal-600 rounded-full mr-2 text-2xl"></i>
                        <div className="text-lg font-bold pl-1">
                          {t("resend_survey")}
                        </div>
                      </div>
                      <hr className="w-full border-1 border-slate-400"></hr>
                      <div className="flex pt-6 text-sm text-gray-700 font-semibold text-center">
                        {t("survey_alert_content")}
                      </div>
                      <div className="flex items-center gap-4 mt-3">
                        <Button
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          buttonClasses={
                            "!bg-rose-600 !text-base !rounded-lg !py-2 !px-6 !text-white  hover:!bg-rose-600/80"
                          }
                          functions={closeModal}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses={
                            "!bg-dark-teal-500 !border-dark-teal-500 hover:!bg-dark-teal-500/80 !rounded-lg"
                          }
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={
                            loading
                              ? "fa-regular fa-spinner fa-spin"
                              : "fa-solid fa-check"
                          }
                          buttonIconPosition={"left"}
                          buttonLabel={t("accept")}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default ResendSurveyPopup;
